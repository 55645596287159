import { Folder, AudioLines, Settings, LayoutGrid } from "lucide-react";

interface SidebarElement {
  title: string;
  icon: React.ReactNode;
  linkTo: string;
}

export const sidebarElements: SidebarElement[] = [
  {
    title: "Spaces",
    icon: <LayoutGrid size={18} strokeWidth={2.5} />,
    linkTo: "/spaces",
  },
  {
    title: "Media Library",
    icon: <Folder size={18} strokeWidth={2.5} />,
    linkTo: "/media",
  },
  {
    title: "Brand Voices",
    icon: <AudioLines size={18} strokeWidth={2.5} />,
    linkTo: "/brand-voice",
  },
  {
    title: "Settings",
    icon: <Settings size={18} strokeWidth={2.5} />,
    linkTo: "/account",
  },
];
