import useSwr from "swr";
import { fetcher } from "@/api/index";

export const useTaskPolling = (
  taskId: string | null,
  enabled: boolean = true,
  pollInterval: number = 5000,
) => {
  const { data, isLoading, error } = useSwr<Task>(
    enabled ? `/tasks/${taskId}` : null,
    fetcher,
    {
      refreshInterval: pollInterval,
    },
  );

  return {
    task: data,
    taskLoading: isLoading,
    taskError: error,
  };
};
