import { cn } from "@/lib/utils";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

type TabButtonProps = {
  text: React.ReactNode;
  to: string;
};

const TabButton = ({ text, to }: TabButtonProps) => {
  const location = useLocation();
  const isActive = location.pathname.includes(to);

  return (
    <NavLink
      to={to}
      className={cn(
        "relative pb-2 transition-all shrink-0",
        isActive ? "text-primary" : "text-muted-foreground hover:text-primary",
      )}
    >
      {text}
      {isActive && (
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
          layoutId="activeTab"
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        />
      )}
    </NavLink>
  );
};

export default TabButton;
