import { useParams } from "react-router";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";

import EditSpaceForm from "@/views/Spaces/components/Form/EditSpaceForm";
import DeleteSpaceForm from "@/views/Spaces/components/Form/DeleteSpaceForm";

import { useSpace } from "@/api/hooks/useSpaces";

const SpaceSettings = () => {
  const { spaceSlug } = useParams();
  const { space, spaceLoading, spaceError } = useSpace(spaceSlug!);

  return (
    <div>
      <div className="space-y-0.5">
        <h1 className="font-bricolage-semibold text-2xl md:text-3xl">Settings</h1>
        <p className="text-sm text-muted-foreground">
          These settings apply only for current space.
        </p>
      </div>
      <div className="space-y-16 py-4">
        <section>
          <h2 className="font-bricolage-medium text-lg">Space Settings</h2>
          <p className="mb-3 text-sm text-muted-foreground">
            Update your space details
          </p>
          <Separator className="mb-6" />
          {spaceLoading || spaceError ? (
            <div className="w-full lg:w-2/3 space-y-4">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="space-y-2">
                  <Skeleton className="h-2.5 w-32" />
                  <Skeleton className="h-10 w-full" />
                </div>
              ))}
              <div className="space-y-2">
                <Skeleton className="h-2.5 w-32" />
                <Skeleton className="h-28 w-full" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-2.5 w-32" />
                <Skeleton className="h-10 w-full" />
              </div>
            </div>
          ) : (
            <div className="w-full lg:w-2/3">
              <EditSpaceForm space={space!} />
            </div>
          )}
        </section>
        <section>
          <h2 className="font-bricolage-medium text-lg">Danger Zone</h2>
          <p className="mb-3 text-sm text-muted-foreground">
            Delete your space permanently
          </p>
          <Separator className="mb-6" />
          {spaceLoading || spaceError ? (
            <Skeleton className="h-20 w-full" />
          ) : (
            <DeleteSpaceForm space={space!} />
          )}
        </section>
      </div>
    </div>
  );
};

export default SpaceSettings;
