import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus, AudioLines } from "lucide-react";

import EditVoiceForm from "@/views/BrandVoices/components/Form/EditVoiceForm";
import AnalyzeForVoiceForm from "@/views/BrandVoices/components/Form/AnalyzeForVoiceForm";
import BrandVoicesSection from "@/views/BrandVoices/components/Section/BrandVoices";

import { AnalyzedBrandVoiceResponse } from "@/api/services/brandVoice";

const BrandVoices = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [step, setStep] = useState<"analyze" | "edit">("analyze");
  const [brandVoice, setBrandVoice] =
    useState<AnalyzedBrandVoiceResponse | null>(null);

  useEffect(() => {
    if (!dialogOpen) {
      setStep("analyze");
      setBrandVoice(null);
    }
  }, [dialogOpen]);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <div className="space-y-6 md:space-y-8">
        <div className="flex flex-row items-center justify-between">
          <h1 className="font-bricolage-semibold text-2xl md:text-3xl">
            Brand Voices
          </h1>
          <DialogTrigger asChild>
            <Button size="sm">
              <Plus size={18} strokeWidth={2.5} className="mr-1" />
              <span className="hidden sm:block">New Brand Voice</span>
              <span className="block sm:hidden">New Voice</span>
            </Button>
          </DialogTrigger>
        </div>
        <div className="flex w-full flex-row items-center gap-3 rounded-sm border border-primary bg-primary/10 p-4">
          <AudioLines
            strokeWidth={2.5}
            size={44}
            className="hidden text-primary md:block"
          />

          <div className="space-y-1 md:space-y-0">
            <h2 className="font-bricolage-semibold text-lg uppercase">
              Help Reinspire AI to sound like you!
            </h2>
            <p className="font-rubik-regular text-sm md:text-base">
              Voices will guide Reinspire AI to create content that looks and
              sounds like your brand.
            </p>
          </div>
        </div>
        <BrandVoicesSection />
      </div>
      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle>
            {step === "analyze" ? "Analyze Brand Voice" : "Edit Brand Voice"}
          </DialogTitle>
          <DialogDescription>
            {step === "analyze"
              ? "Analyze the details of your brand voice from a website or text."
              : "Edit the details of your brand voice."}
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {step === "analyze" ? (
            <AnalyzeForVoiceForm
              setBrandVoice={setBrandVoice}
              setStep={setStep}
              setDialogOpen={setDialogOpen}
            />
          ) : (
            <EditVoiceForm
              setStep={setStep}
              setDialogOpen={setDialogOpen}
              brandVoice={brandVoice}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BrandVoices;
