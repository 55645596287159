import axiosRetry from "axios-retry";
import { apiInstance } from "@/api/index";
import type { AxiosError } from "axios";

const BadRequest: Record<string, string> = {
  LOGIN_BAD_CREDENTIALS: "User account is permanently disabled",
  OAUTH_USER_ALREADY_EXISTS: "User with this email already exists",
};

export const handleGoogleAuth = async () => {
  axiosRetry(apiInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
  });

  try {
    const response = await apiInstance.get("/auth/google/authorize");
    const authorization_url =
      response.data.authorization_url + "&prompt=consent";
    return authorization_url;
  } catch (err) {
    const error = err as AxiosError;
    if (error.request) {
      throw new Error("Network error");
    }

    throw new Error("Something went wrong. Try again.");
  }
};

type OauthParams = {
  code: string;
  state: string;
  error: string | null;
};

export const getGoogleOauthCallback = async (
  params: OauthParams,
  controller: AbortController,
) => {
  axiosRetry(apiInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
  });

  await apiInstance
    .get("/auth/google/callback", { params: params, signal: controller.signal })
    .catch((error) => {
      if (!error.response && error.request) {
        throw new Error("Network error");
      }

      if (error.response) {
        if (error.response.status === 400) {
          const response = error.response;

          if (response.data.detail in BadRequest) {
            throw new Error(BadRequest[response.data.detail]);
          } else {
            throw new Error("Google login failed");
          }
        } else if (error.response.status === 429) {
          throw new Error("Too many requests. Try again later.");
        }
      }

      if (error.message === "canceled") {
        throw new Error("canceled");
      }

      throw new Error("Something went wrong. Try again.");
    });
};
