import useSWR from "swr";
import { fetcher } from "@/api/index";
import { AxiosError } from "axios";

export const userSubscriptionUrl = "/users/me/subscription";

export const useSubscription = () => {
  const { data, error, isLoading } = useSWR<Subscription, Error>(
    userSubscriptionUrl,
    fetcher,
    {
      onErrorRetry: (err) => {
        const error = err as AxiosError;
        // Never retry on 404.
        if (error.status === 404) return;
      },
    },
  );

  return {
    subscription: data,
    subscriptionError: error,
    subscriptionLoading: isLoading,
  };
};
