import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth, AuthStatus } from "@/views/Auth/AuthProvider";
import { Toaster } from "@/components/ui/toaster";
import HexagonalUrl from "@/assets/images/hexagonal.svg";

import TestimonialSlider from "@/views/Auth/components/Slider/TestimonialsSlider";

const AuthLayout = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.status === AuthStatus.IDLE) return;

    if (authState.status === AuthStatus.AUTHENTICATED) {
      navigate("/spaces", { replace: true });
    }
  }, [authState, navigate]);

  if (authState.status === AuthStatus.AUTHENTICATED) return null;

  return (
    <div className="relative flex h-screen max-h-screen flex-row">
      <main className="flex w-full flex-col items-center justify-center bg-card lg:w-[calc(50%-50px)]">
        <div className="w-full px-8 sm:w-2/3 sm:px-0">
          <Outlet />
        </div>
      </main>
      <aside className="relative hidden w-[calc(50%+50px)] overflow-hidden bg-primary lg:block lg:p-24 xl:p-32">
        <div className="relative flex h-full flex-col justify-between">
          <h1 className="h-fit font-bricolage-semibold text-6xl font-bold tracking-wide text-white xl:text-7xl">
            10x Your Content Production
          </h1>
          <TestimonialSlider />
        </div>

        {/* Decoration Hexagonals */}
        <img
          className="pointer-events-none absolute -bottom-[116rem] -left-[24rem] size-[4000px] rotate-45"
          src={HexagonalUrl}
          alt="decoration hexagon"
        />
        <img
          className="-rotate-70 pointer-events-none absolute -left-[35rem] -top-[145rem] size-[4000px]"
          src={HexagonalUrl}
          alt="decoration hexagon"
        />
        <img
          className="pointer-events-none absolute -right-[22rem] -top-[48rem] size-[1800px]"
          src={HexagonalUrl}
          alt="decoration hexagon"
        />
      </aside>
      <Toaster />
    </div>
  );
};

export default AuthLayout;
