export const STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export const HTTP_ERROR_CODES = [400, 401, 402, 403, 404, 409];

export const containsUndefinedParams = (
  params: Record<string, number | string | boolean | undefined>,
) => Object.values(params).some((value) => value === undefined);

export const pathBuilder = (
  basePath: string,
  params: Record<string, number | string | boolean | undefined>,
) => {
  let path = basePath;

  const queryParams = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`,
    );

  if (queryParams.length > 0) {
    path += "?" + queryParams.join("&");
  }

  return path.toString();
};
