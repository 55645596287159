import { useState } from "react";
import { Link } from "react-router-dom";

import RegisterForm from "@/views/Auth/components/Form/RegisterForm";
import VerificationCard from "@/views/Auth/components/Card/VerificationCard";
import { STATUS } from "@/api/utils";

import LogoUrl from "@/assets/images/logo-square.png";

const Register = () => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [email, setEmail] = useState("");

  if (status === STATUS.SUCCESS) return <VerificationCard email={email} />;

  return (
    <div className="relative space-y-4 text-start">
      <div className="space-y-2">
        <img src={LogoUrl} alt="Reinspire logo" className="size-14" />
        <h1 className="font-bricolage-semibold text-2xl">
          Welcome to Reinspire
        </h1>
        <p className="text-muted-foreground">
          Ready to supercharge your content production? Sign up now!
        </p>
      </div>
      <RegisterForm status={status} setStatus={setStatus} setEmail={setEmail} />
      <p className="text-sm text-muted-foreground">
        Already have an account?{" "}
        <Link to="/login" className="text-primary hover:underline">
          Log in
        </Link>
      </p>
      <p className="absolute -bottom-16 left-0 right-0 !mt-0 pb-3 text-center text-xs text-muted-foreground sm:pb-0">
        By signing up, you agree to our{" "}
        <a
          href="https://reinspire.io/terms"
          target="_blank"
          className="text-black underline"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://reinspire.io/privacy"
          target="_blank"
          className="text-black underline"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  );
};

export default Register;
