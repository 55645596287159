import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Ellipsis, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { deleteRecording } from "@/api/services/recording";

type RecordingDropdownProps = {
  recording: RecordingPreview;
};

const RecordingDropdown = ({ recording }: RecordingDropdownProps) => {
  const { spaceSlug } = useParams();
  const [status, setStatus] = useState(STATUS.IDLE);
  const [error, setError] = useState<string | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { toast } = useToast();

  useEffect(() => {
    // Reset the values when the dialog is closed
    setStatus(STATUS.IDLE);
    setDeleteConfirmation(null);
    setError(null);
  }, [isDeleteDialogOpen]);

  const handleDeleteRecording = async () => {
    setStatus(STATUS.LOADING);

    if (deleteConfirmation?.toLocaleLowerCase() !== "confirm") {
      setError("Please type 'confirm' to delete the recording");
      setStatus(STATUS.ERROR);
      return;
    }

    try {
      await deleteRecording(spaceSlug!, recording.id);
      setIsDeleteDialogOpen(false);
      setStatus(STATUS.SUCCESS);
      toast({
        title: "Recording deleted successfully",
        variant: "success",
      });
    } catch (error) {
      setError((error as Error).message);
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <div
      onClick={(e) => {
        // Prevent navigation to recording page
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              className="size-8 flex-shrink-0 rounded-sm p-0.5 text-muted-foreground"
            >
              <Ellipsis size={18} strokeWidth={2.5} />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent side="bottom" align="end">
            <DropdownMenuItem
              className="cursor-pointer !text-destructive hover:!bg-destructive/10"
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              <Trash2 size={16} strokeWidth={2.5} className="mr-2" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>

          {/* Delete Alert Dialog */}
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Recording</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription>
              {status === STATUS.ERROR && (
                <Alert type="error" message={error!} className="mb-4" />
              )}
              <span>
                Are you sure you want to delete this recording? All content
                generations and transcript data will be lost and cannot be
                recovered once deleted.
              </span>
            </AlertDialogDescription>
            <div className="flex flex-col gap-1">
              <small
                className={cn(status === STATUS.ERROR && "text-destructive")}
              >
                Type "<b className="font-rubik-medium">confirm</b>" below to
                confirm
              </small>
              <Input
                id="delete-confirmation"
                placeholder="Type 'confirm' to delete"
                value={deleteConfirmation || ""}
                onChange={(e) => setDeleteConfirmation(e.target.value.toLowerCase())}
              />
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel className="min-w-20">Cancel</AlertDialogCancel>
              <Button
                variant="destructive"
                disabled={deleteConfirmation?.toLocaleLowerCase() !== "confirm"}
                isLoading={status === STATUS.LOADING}
                onClick={handleDeleteRecording}
                className="min-w-20"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </DropdownMenu>
      </AlertDialog>
    </div>
  );
};

export default RecordingDropdown;
