import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { DialogClose, DialogFooter } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Loader2, Quote } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { useSpeakers } from "@/api/hooks/useSpeakers";
import { createSpaceSpeaker, assignSpaceSpeaker } from "@/api/services/space";

const assignFormSchema = z.object({
  recording_id: z.string(),
  transcript_chunk_id: z.string(),
  assign_to_all: z.boolean(),
});

const createFormSchema = z.object({
  assigned_label: z
    .string()
    .min(1, { message: "Speaker name is required" })
    .max(32, { message: "Speaker name must be less than 32 characters" }),
  recording_id: z.string(),
  transcript_chunk_id: z.string(),
  assign_to_all: z.boolean(),
});

type assignFormSchema = z.infer<typeof assignFormSchema>;
type createFormSchema = z.infer<typeof createFormSchema>;

type AssignSpeakerFormProps = {
  recording: Recording;
  transcriptChunk: TranscriptChunk;
  transcriptChunkMeta: RecordingTranscriptChunk;
  setDialogOpen: (open: boolean) => void;
};

const AssignSpeakerForm = (props: AssignSpeakerFormProps) => {
  const { recording, transcriptChunk, transcriptChunkMeta, setDialogOpen } =
    props;
  const { spaceSlug } = useParams();
  if (!spaceSlug) throw new Error("Space slug is required");

  const [activeTab, setActiveTab] = useState<"assign" | "create" | null>(null);

  const { speakers, speakersLoading, speakersError } = useSpeakers(spaceSlug);

  useEffect(() => {
    if (!speakers) return;
    if (speakers.length === 0) {
      setActiveTab("create");
    } else {
      setActiveTab("assign");
    }
  }, [speakers]);

  if (speakersLoading || speakersError) {
    return (
      <div className="flex h-44 items-center justify-center">
        <Loader2
          size={30}
          strokeWidth={2.5}
          className="animate-spin text-primary"
        />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative flex border-b border-border text-sm">
        <button
          className={cn(
            "flex flex-1 flex-row items-center justify-center gap-2 p-2",
            activeTab === "assign" ? "text-primary" : "text-muted-foreground",
          )}
          onClick={() => setActiveTab("assign")}
        >
          Assign Speaker
        </button>
        <button
          className={cn(
            "flex flex-1 flex-row items-center justify-center gap-2 p-2",
            activeTab === "create" ? "text-primary" : "text-muted-foreground",
          )}
          onClick={() => setActiveTab("create")}
        >
          New Speaker
        </button>
        <motion.div
          className="absolute bottom-0 h-0.5 bg-primary"
          initial={false}
          animate={{
            left: activeTab === "assign" ? "0%" : "50%",
            width: "50%",
          }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        />
      </div>
      <div className="relative rounded-sm border border-border bg-gray-100 p-3 text-sm text-muted-foreground">
        <Quote
          size={16}
          strokeWidth={2.5}
          className="absolute left-3 top-3 text-black"
        />
        <div className="overflow-hidden pl-6">
          <div className="line-clamp-3 text-pretty font-rubik-regular text-gray-600">
            {transcriptChunk.text}
          </div>
        </div>
      </div>
      {activeTab === "assign" ? (
        <ExistingSpeakerForm
          recording={recording}
          transcriptChunk={transcriptChunk}
          transcriptChunkMeta={transcriptChunkMeta}
          speakers={speakers!}
          spaceSlug={spaceSlug}
          setDialogOpen={setDialogOpen}
        />
      ) : activeTab === "create" ? (
        <NewSpeakerForm
          recording={recording}
          transcriptChunk={transcriptChunk}
          transcriptChunkMeta={transcriptChunkMeta}
          spaceSlug={spaceSlug}
          setDialogOpen={setDialogOpen}
        />
      ) : null}
    </div>
  );
};

type ExistingSpeakerFormProps = {
  recording: Recording;
  transcriptChunk: TranscriptChunk;
  transcriptChunkMeta: RecordingTranscriptChunk;
  speakers: SpaceSpeaker[];
  spaceSlug: string;
  setDialogOpen: (open: boolean) => void;
};

const ExistingSpeakerForm = (props: ExistingSpeakerFormProps) => {
  const {
    recording,
    transcriptChunk,
    transcriptChunkMeta,
    speakers,
    spaceSlug,
    setDialogOpen,
  } = props;

  const [status, setStatus] = useState(STATUS.IDLE);
  const [selectedSpeakerId, setSelectedSpeakerId] = useState<number | null>(
    transcriptChunkMeta.space_speaker?.id || null,
  );
  const { toast } = useToast();

  const form = useForm<assignFormSchema>({
    resolver: zodResolver(assignFormSchema),
    defaultValues: {
      recording_id: recording.id,
      transcript_chunk_id: transcriptChunk.id,
      assign_to_all: selectedSpeakerId ? false : true,
    },
  });

  const onSubmit: SubmitHandler<assignFormSchema> = async (data) => {
    if (!selectedSpeakerId) {
      form.setError("root", { message: "Please select a speaker" });
      return;
    }

    setStatus(STATUS.LOADING);
    try {
      await assignSpaceSpeaker(spaceSlug, selectedSpeakerId, data);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
      toast({
        title: "Speaker assigned successfully",
        variant: "success",
      });
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {status === STATUS.ERROR && (
          <Alert type="error" message={form.formState.errors.root!.message!} />
        )}

        {speakers && speakers.length > 0 ? (
          <RadioGroup
            value={selectedSpeakerId ? selectedSpeakerId.toString() : undefined}
            onValueChange={(value) => setSelectedSpeakerId(parseInt(value))}
            className="grid max-h-60 gap-2 overflow-y-auto md:max-h-44 md:grid-cols-2"
          >
            {speakers.map((speaker, index) => (
              <div
                key={index}
                className="flex h-12 w-full flex-row items-center gap-2 rounded-sm border border-border p-4 text-sm transition-colors hover:border-primary hover:bg-primary/10"
              >
                <RadioGroupItem
                  id={`speaker${index}`}
                  value={speaker.id.toString()}
                />
                <Label
                  htmlFor={`speaker${index}`}
                  className="w-full cursor-pointer py-4 text-start"
                >
                  {speaker.assigned_label}
                </Label>
              </div>
            ))}
          </RadioGroup>
        ) : (
          <div className="rounded-sm border border-border py-8 text-center text-muted-foreground">
            No existing speakers found.
          </div>
        )}

        <FormField
          control={form.control}
          name="assign_to_all"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  className="rounded-[4px]"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>Assign to all</FormLabel>
                <FormDescription>
                  Check this to assign to all "
                  {transcriptChunkMeta.space_speaker?.assigned_label ||
                    transcriptChunk.speaker.replace(/_/g, " ")}
                  " segments.
                </FormDescription>
              </div>
            </FormItem>
          )}
        />

        <DialogFooter className="gap-2">
          <DialogClose asChild>
            <Button variant="outline" className="min-w-20">
              Cancel
            </Button>
          </DialogClose>
          <Button
            type="submit"
            className="min-w-20"
            disabled={status === STATUS.LOADING}
            isLoading={status === STATUS.LOADING}
          >
            Assign
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

type NewSpeakerFormProps = {
  spaceSlug: string;
  recording: Recording;
  transcriptChunk: TranscriptChunk;
  transcriptChunkMeta: RecordingTranscriptChunk;
  setDialogOpen: (open: boolean) => void;
};

const NewSpeakerForm = (props: NewSpeakerFormProps) => {
  const {
    recording,
    transcriptChunk,
    transcriptChunkMeta,
    spaceSlug,
    setDialogOpen,
  } = props;
  const [status, setStatus] = useState(STATUS.IDLE);
  const { toast } = useToast();

  const form = useForm<createFormSchema>({
    resolver: zodResolver(createFormSchema),
    defaultValues: {
      assigned_label: "",
      recording_id: recording.id,
      transcript_chunk_id: transcriptChunk.id,
      assign_to_all: transcriptChunkMeta.space_speaker ? false : true,
    },
  });

  const onSubmit: SubmitHandler<createFormSchema> = async (data) => {
    setStatus(STATUS.LOADING);
    try {
      await createSpaceSpeaker(spaceSlug, data);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
      toast({
        title: "Speaker created and assigned successfully",
        variant: "success",
      });
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {status === STATUS.ERROR && (
          <Alert type="error" message={form.formState.errors.root!.message!} />
        )}

        <FormField
          control={form.control}
          name="assigned_label"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New Speaker Name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter new speaker name" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="assign_to_all"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  className="rounded-[4px]"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>Assign to all</FormLabel>
                <FormDescription>
                  Check this to assign to all "
                  {transcriptChunkMeta.space_speaker?.assigned_label ||
                    transcriptChunk.speaker.replace(/_/g, " ")}
                  " segments.
                </FormDescription>
              </div>
            </FormItem>
          )}
        />

        <DialogFooter className="gap-2">
          <DialogClose asChild>
            <Button variant="outline" className="min-w-20">
              Cancel
            </Button>
          </DialogClose>
          <Button
            type="submit"
            className="min-w-20"
            disabled={status === STATUS.LOADING}
            isLoading={status === STATUS.LOADING}
          >
            Create
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default AssignSpeakerForm;
