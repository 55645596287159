import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Mail } from "lucide-react";

import { handleVerifyRequest } from "@/api/services/verify";

type VerificationCardProps = {
  email: string;
};

const VerificationCard = ({ email }: VerificationCardProps) => {
  const [isResent, setIsResent] = useState(false);
  const { toast } = useToast();

  const resendEmail = async (email: string) => {
    try {
      await handleVerifyRequest(email);
      setIsResent(true);
      toast({
        title: "Email resent successfully",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Failed to resend email.",
        variant: "error",
      });
    }
  };

  return (
    <Card>
      <CardContent className="flex flex-col items-center justify-center space-y-3 p-6">
        <Mail size={96} />
        <p className="font-bricolage-medium text-2xl">
          We have sent you an email
        </p>
        <p className="text-center text-sm">
          We have just sent you an email to{" "}
          <span className="font-rubik-medium">{email}</span>. Please check your
          inbox and follow the instructions to verify your account.{" "}
          <span className="font-rubik-medium">
            Make sure to check your spam folder as well.
          </span>
        </p>
        <p className="text-xs">
          Didn't receive an email?
          <button
            disabled={isResent}
            className={cn(
              "ml-1 cursor-pointer text-xs text-primary hover:underline",
              isResent && "cursor-not-allowed text-muted-foreground",
            )}
            onClick={() => resendEmail(email)}
          >
            {isResent ? "Email resent" : "Resend email"}
          </button>
        </p>
        <div className="space-x-4">
          <Button size="sm" variant="outline" asChild>
            <Link to="https://mail.google.com/mail/u/0/#inbox" target="_blank">
              Open Gmail
            </Link>
          </Button>
          <Button size="sm" variant="outline" asChild>
            <Link to="https://outlook.office.com/mail/" target="_blank">
              Open Outlook
            </Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default VerificationCard;
