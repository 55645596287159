import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Copy, Check, ChevronDown, SquareArrowOutUpRight } from "lucide-react";

import { timestampToSeconds } from "@/lib/time";
import { postProcessOutput } from "@/lib/ai/utils";
import { Timestamp } from "@/components/utils/timestamp";

type AIContentCardProps = {
  isExpanded: boolean;
  aiContentBlock: RecordingContentBlock;
  seekToTimestamp: (startTime: number) => void;
};

const AIContentCard = (props: AIContentCardProps) => {
  const { isExpanded, aiContentBlock, seekToTimestamp } = props;
  const { spaceSlug } = useParams();
  const [isContentVisible, setIsContentVisible] = useState<boolean>(isExpanded);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    setIsContentVisible(isExpanded);
  }, [isExpanded]);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const timestampClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    seekToTimestamp(timestampToSeconds(e.currentTarget.innerText));
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(aiContentBlock.generations[0].output);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div
      id={`prompt-${aiContentBlock.prompt.id}`}
      className="scroll-mt-16 rounded-sm"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <Button
            size="icon"
            variant="secondary"
            onClick={toggleContent}
            className="size-8 bg-transparent"
          >
            <motion.div
              animate={{ rotate: isContentVisible ? 0 : -90 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronDown size={20} />
            </motion.div>
          </Button>
          <Link
            to={`/spaces/${spaceSlug}/prompts#${aiContentBlock.prompt.id}`}
            className="group flex flex-row items-center gap-1.5 transition-colors hover:text-primary"
          >
            <h3 className="line-clamp-1 font-bricolage-medium text-lg">
              {aiContentBlock.prompt.name}
            </h3>
            <SquareArrowOutUpRight
              size={16}
              strokeWidth={2.5}
              className="hidden group-hover:block"
            />
          </Link>
        </div>
        {aiContentBlock.status === "completed" && (
          <div className="flex flex-row items-center gap-2">
            <Button
              size="sm"
              variant="ghost"
              className="flex h-8 flex-row items-center gap-1 bg-card"
              onClick={handleCopy}
            >
              {isCopied ? (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Check
                    size={14}
                    strokeWidth={2.5}
                    className="text-green-600"
                  />
                </motion.div>
              ) : (
                <Copy size={14} strokeWidth={2.5} />
              )}
              <span className="hidden sm:block">
                {isCopied ? "Copied!" : "Copy"}
              </span>
            </Button>
          </div>
        )}
      </div>
      <motion.div
        initial={false}
        animate={{
          height: isContentVisible ? "auto" : 0,
          opacity: isContentVisible ? 1 : 0,
        }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <div className="prose mt-4 max-w-none whitespace-pre-line text-pretty font-rubik-regular text-secondary-foreground sm:pl-10">
          <Markdown
            options={{
              wrapper: React.Fragment,
              overrides: {
                h1: {
                  component: "h1",
                  props: { className: "text-[26px]" },
                },
                Timestamp: {
                  component: Timestamp,
                  props: {
                    onClick: timestampClickHandler,
                  },
                },
              },
            }}
          >
            {aiContentBlock.status === "completed"
              ? postProcessOutput(aiContentBlock.generations[0].output)
              : "Generation failed. Our team is aware of this issue and is working on a fix."}
          </Markdown>
        </div>
      </motion.div>
    </div>
  );
};

export default AIContentCard;
