import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Alert from "@/components/Alert/Alert";
import { useToast } from "@/components/ui/use-toast";
import { AtSign, User } from "lucide-react";

import { STATUS } from "@/api/utils";
import { updateUser } from "@/api/services/user";

const formSchema = z.object({
  full_name: z.string().min(3, "Full name must be at least 3 characters long"),
});

type formSchema = z.infer<typeof formSchema>;

type AccountFormProps = {
  user: User;
};

const AccountForm = ({user}: AccountFormProps) => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const { toast } = useToast();

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      full_name: user.full_name,
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await updateUser(data);
      setStatus(STATUS.SUCCESS);
      toast({
        title: "Account updated successfully",
        variant: "success",
      });
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  useEffect(() => {
    if (!user) return;
    form.setValue("full_name", user.full_name);
  }, [user, form]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-full flex-col gap-4 md:w-3/4 lg:w-1/2"
      >
        {status === STATUS.ERROR && form.formState.errors.root && (
          <Alert type="error" message={form.formState.errors.root.message!} />
        )}
        <FormField
          control={form.control}
          name="full_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Full name</FormLabel>
              <FormControl>
                <Input
                  startIcon={User}
                  placeholder="Enter your full name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
              <FormDescription>
                Used to personalize your experience on the platform and for
                communication purposes.
              </FormDescription>
            </FormItem>
          )}
        />
        <div className="space-y-1">
          <span className="text-sm">Email</span>
          <Input disabled startIcon={AtSign} value={user.email} />
          <small className="text-xs text-muted-foreground">
            You can't change your email at this time
          </small>
        </div>
        <Button
          size="sm"
          type="submit"
          isLoading={status === STATUS.LOADING}
          className="w-fit"
        >
          Save changes
        </Button>
      </form>
    </Form>
  );
};

export default AccountForm;
