import { useOutletContext } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";

import { RecordingContextType } from "@/views/Recordings/RecordingLayout";
import TranscriptEditor from "@/views/Recordings/components/Editor/TranscriptEditor";

import { useTranscript } from "@/api/hooks/useTranscript";

const Transcript = () => {
  const { recording, player } = useOutletContext<RecordingContextType>();
  if (!recording) throw new Error("Recording not found");

  const { transcript, transcriptLoading, transcriptError } = useTranscript(
    recording.transcript_file.src_url,
  );

  if (transcriptLoading || transcriptError) {
    return (
      <div className="space-y-8 mt-6">
        {[...Array(4)].map((_, index) => (
          <div key={index} className="space-y-2">
            <Skeleton className="h-4 w-40 rounded-[5px]" />
            <Skeleton className="h-32 w-full rounded-sm" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mt-6">
      <TranscriptEditor
        recording={recording}
        transcript={transcript!}
        player={player}
      />
    </div>
  );
};

export default Transcript;
