import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import data, { Skin } from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandItem,
} from "@/components/ui/command";
import { ChevronDown, Check, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { supportedLanguages } from "@/lib/constants";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { editSpace } from "@/api/services/space";
import { useBrandVoices } from "@/api/hooks/useBrandVoices";

const formSchema = z.object({
  emoji: z.string().min(1),
  name: z
    .string()
    .min(3, {
      message: "Space name must be at least 3 characters.",
    })
    .max(32, {
      message: "Space name must be at most 32 characters.",
    }),
  language: z.string().min(1, {
    message: "Please select a language.",
  }),
  context_prompt: z.string().max(1500, {
    message: "Context prompt must be at most 1500 characters.",
  }),
  brand_voice_id: z.number().nullable(),
  preset_id: z.number().nullable(),
});

type FormSchema = z.infer<typeof formSchema>;

type EditSpaceFormProps = {
  space: Space;
};

const EditSpaceForm = ({ space }: EditSpaceFormProps) => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [comboboxOpen, setComboboxOpen] = useState<boolean>(false);

  const { toast } = useToast();

  const { brandVoices, brandVoicesLoading, brandVoicesError } =
    useBrandVoices();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emoji: space.emoji,
      name: space.name,
      language: space.language,
      context_prompt: space.context_prompt,
      brand_voice_id: space.brand_voice_id,
      preset_id: space.preset?.id || null,
    },
  });

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await editSpace(space.slug, data);
      setStatus(STATUS.SUCCESS);
      toast({
        title: "Saved successfully",
        variant: "success",
      });
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  const handleEmojiSelect = (emoji: Skin) => {
    form.setValue("emoji", emoji.native);
    setShowPicker(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {status === STATUS.ERROR && (
          <Alert type="error" message={form.formState.errors.root!.message!} />
        )}

        <div className="space-y-1">
          <FormLabel className="text-sm">Emoji & Name</FormLabel>
          <div className="flex flex-row items-start gap-2">
            <FormField
              control={form.control}
              name="emoji"
              render={({ field }) => (
                <FormItem className="relative flex w-10 flex-shrink-0 items-center gap-4">
                  <FormControl defaultValue={field.value}>
                    <>
                      <Button
                        variant="outline"
                        type="button"
                        className="size-10 text-xl"
                        onClick={() => setShowPicker(!showPicker)}
                      >
                        {field.value}
                      </Button>
                      {showPicker && (
                        <div
                          className="absolute left-0 top-full z-10"
                          onWheel={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Picker
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                            theme="light"
                            icons="outline"
                            previewPosition="none"
                            emojiVersion="15.0"
                            skinTonePosition="none"
                            maxFrequentRows={2}
                            emojiButtonColors={["#F3F4F6"]}
                            dynamicWidth={true}
                          />
                        </div>
                      )}
                    </>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex-grow">
                  <FormControl>
                    <Input {...field} placeholder="Enter space name..." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <FormField
          control={form.control}
          name="language"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel className="mb-1 w-fit">Language</FormLabel>
              <Popover open={comboboxOpen} onOpenChange={setComboboxOpen}>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "justify-between capitalize active:scale-[1]",
                        !field.value && "text-muted-foreground",
                      )}
                    >
                      {field.value || "Select language"}
                      <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-[--radix-popover-trigger-width] p-0 shadow-sm">
                  <Command>
                    <CommandInput
                      placeholder="Search language..."
                      className="h-9"
                    />
                    <CommandEmpty>No language found.</CommandEmpty>
                    <CommandGroup>
                      <CommandList>
                        {supportedLanguages.map((language) => (
                          <CommandItem
                            key={language}
                            value={language}
                            onSelect={() => {
                              form.setValue("language", language);
                              setComboboxOpen(false);
                            }}
                            className="cursor-pointer capitalize"
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                language === field.value
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                            {language}
                          </CommandItem>
                        ))}
                      </CommandList>
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormDescription>
                The language for generated content in this space.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="brand_voice_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Brand Voice (optional)</FormLabel>
              <Select
                value={field.value === null ? "null" : field.value?.toString()}
                onValueChange={(value) =>
                  form.setValue(
                    "brand_voice_id",
                    value === "null" ? null : parseInt(value),
                  )
                }
                disabled={brandVoicesLoading || brandVoicesError ? true : false}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        <span className="flex flex-row items-center gap-2">
                          {(brandVoicesLoading || brandVoicesError) && (
                            <Loader2
                              size={16}
                              strokeWidth={2.5}
                              className="animate-spin"
                            />
                          )}
                          Select brand voice
                        </span>
                      }
                    >
                      {field.value ? (
                        <>
                          {
                            brandVoices?.find((bv) => bv.id === field.value)
                              ?.emoji
                          }{" "}
                          {
                            brandVoices?.find((bv) => bv.id === field.value)
                              ?.name
                          }
                        </>
                      ) : (
                        "No brand voice"
                      )}
                    </SelectValue>
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="null">No brand voice</SelectItem>
                  {brandVoices?.map((brandVoice) => (
                    <SelectItem
                      key={brandVoice.id}
                      value={brandVoice.id.toString()}
                    >
                      {brandVoice.emoji} {brandVoice.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
              <FormDescription>
                Brand voice for generated content in this space.
              </FormDescription>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="context_prompt"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Context Prompt (optional)</FormLabel>
              <div className="flex flex-grow flex-col gap-1">
                <FormControl>
                  <Textarea
                    {...field}
                    placeholder="Enter any additional context here..."
                    className="max-h-56 min-h-20"
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Additional prompt that will be injected into main Reinspire AI
                  prompt. Used to provide context and specific instructions for
                  the AI in this space.
                </FormDescription>
              </div>
            </FormItem>
          )}
        />

        <div className="!mt-8 flex justify-start gap-4">
          <Button
            type="submit"
            size="sm"
            className="min-w-20"
            isLoading={status === STATUS.LOADING}
          >
            Save Changes
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default EditSpaceForm;
