import { useState } from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import data, { Skin } from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandItem,
} from "@/components/ui/command";
import { Icon } from "@/components/ui/icon";
import { ChevronDown, Check, Settings2, Loader2 } from "lucide-react";
import { supportedLanguages } from "@/lib/constants";
import { getRandomEmoji } from "@/lib/emojis";
import { cn } from "@/lib/utils";

import Alert from "@/components/Alert/Alert";
import FetchError from "@/components/Error/FetchError";

import { STATUS } from "@/api/utils";
import { createSpace } from "@/api/services/space";
import { usePresets } from "@/api/hooks/usePresets";
import { useBrandVoices } from "@/api/hooks/useBrandVoices";

const formSchema = z.object({
  emoji: z.string().min(1),
  name: z
    .string()
    .min(3, {
      message: "Space name must be at least 3 characters.",
    })
    .max(32, {
      message: "Space name must be at most 32 characters.",
    }),
  language: z.string().min(1, {
    message: "Please select a language.",
  }),
  context_prompt: z.string().max(1500, {
    message: "Context prompt must be at most 1500 characters.",
  }),
  brand_voice_id: z.number().nullable(),
  preset_id: z.number().nullable(),
});

type FormSchema = z.infer<typeof formSchema>;

type NewSpaceFormProps = {
  setDialogOpen: (value: boolean) => void;
  onNextStep: () => void;
  onPrevStep: () => void;
  currentStep: 1 | 2;
};

const NewSpaceForm = (props: NewSpaceFormProps) => {
  const { setDialogOpen, onNextStep, onPrevStep, currentStep } = props;
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.IDLE);
  const [selectedPresetId, setSelectedPresetId] = useState<number | null>(null);
  const [showAllPresetPrompts, setShowAllPresetPrompts] =
    useState<boolean>(false);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [comboboxOpen, setComboboxOpen] = useState<boolean>(false);

  const { brandVoices, brandVoicesLoading, brandVoicesError } =
    useBrandVoices();
  const { presets, presetsLoading, presetsError } = usePresets();

  const randomEmoji = getRandomEmoji();

  const selectedPreset = presets?.find(
    (preset) => preset.id === selectedPresetId,
  );
  const prompts = selectedPreset?.prompts || [];
  const displayPrompts = showAllPresetPrompts ? prompts : prompts.slice(0, 4);

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emoji: randomEmoji,
      name: "",
      language: "auto",
      context_prompt: "",
      brand_voice_id: null,
      preset_id: undefined,
    },
  });

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      const space = await createSpace(data);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
      navigate(`/spaces/${space.slug}`);
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  const handleEmojiSelect = (emoji: Skin) => {
    form.setValue("emoji", emoji.native);
    setShowPicker(false);
  };

  const handleNextStep = () => {
    form
      .trigger([
        "emoji",
        "name",
        "language",
        "context_prompt",
        "brand_voice_id",
      ])
      .then((isValid) => {
        if (isValid) onNextStep();
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {status === STATUS.ERROR && (
          <Alert type="error" message={form.formState.errors.root!.message!} />
        )}
        {currentStep === 1 ? (
          <>
            <div className="space-y-1">
              <FormLabel className="text-sm">Emoji & Name</FormLabel>
              <div className="flex flex-row items-start gap-2">
                <FormField
                  control={form.control}
                  name="emoji"
                  render={({ field }) => (
                    <FormItem className="relative flex w-10 flex-shrink-0 items-center gap-4">
                      <FormControl defaultValue={randomEmoji}>
                        <>
                          <Button
                            variant="outline"
                            type="button"
                            className="size-10 text-xl"
                            onClick={() => setShowPicker(!showPicker)}
                          >
                            {field.value || randomEmoji}
                          </Button>
                          {showPicker && (
                            <div
                              className="absolute left-0 top-full z-10"
                              onWheel={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Picker
                                data={data}
                                onEmojiSelect={handleEmojiSelect}
                                theme="light"
                                icons="outline"
                                previewPosition="none"
                                emojiVersion="15.0"
                                skinTonePosition="none"
                                maxFrequentRows={2}
                                emojiButtonColors={["#F3F4F6"]}
                                dynamicWidth={true}
                              />
                            </div>
                          )}
                        </>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="flex-grow">
                      <FormControl>
                        <Input {...field} placeholder="Enter space name..." />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel className="mb-1 w-fit">Language</FormLabel>
                  <Popover open={comboboxOpen} onOpenChange={setComboboxOpen}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between capitalize active:scale-[1]",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value || "Select language"}
                          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[--radix-popover-trigger-width] p-0 shadow-sm">
                      <Command>
                        <CommandInput
                          placeholder="Search language..."
                          className="h-9"
                        />
                        <CommandEmpty>No language found.</CommandEmpty>
                        <CommandGroup>
                          <CommandList>
                            {supportedLanguages.map((language) => (
                              <CommandItem
                                key={language}
                                value={language}
                                onSelect={() => {
                                  form.setValue("language", language);
                                  setComboboxOpen(false);
                                }}
                                className="cursor-pointer capitalize"
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    language === field.value
                                      ? "opacity-100"
                                      : "opacity-0",
                                  )}
                                />
                                {language}
                              </CommandItem>
                            ))}
                          </CommandList>
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    The language for generated content in this space. Default is
                    auto-detect.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="brand_voice_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Brand Voice (optional)</FormLabel>
                  <Select
                    value={
                      field.value === null ? "null" : field.value?.toString()
                    }
                    onValueChange={(value) =>
                      form.setValue(
                        "brand_voice_id",
                        value === "null" ? null : parseInt(value),
                      )
                    }
                    disabled={
                      brandVoicesLoading || brandVoicesError ? true : false
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={
                            <span className="flex flex-row items-center gap-2">
                              {(brandVoicesLoading || brandVoicesError) && (
                                <Loader2
                                  size={16}
                                  strokeWidth={2.5}
                                  className="animate-spin"
                                />
                              )}
                              Select brand voice
                            </span>
                          }
                        >
                          {field.value ? (
                            <>
                              {
                                brandVoices?.find((bv) => bv.id === field.value)
                                  ?.emoji
                              }{" "}
                              {
                                brandVoices?.find((bv) => bv.id === field.value)
                                  ?.name
                              }
                            </>
                          ) : (
                            "No brand voice"
                          )}
                        </SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="null">No brand voice</SelectItem>
                      {brandVoices?.map((brandVoice) => (
                        <SelectItem
                          key={brandVoice.id}
                          value={brandVoice.id.toString()}
                        >
                          {brandVoice.emoji} {brandVoice.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                  <FormDescription>
                    Brand voice for generated content in this space.
                  </FormDescription>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="context_prompt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Context Prompt (optional)</FormLabel>
                  <div className="flex flex-grow flex-col gap-1">
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Enter any additional context here..."
                        className="max-h-56 min-h-20"
                      />
                    </FormControl>
                    <FormMessage />
                    <FormDescription>
                      Additional prompt that will be injected into main
                      Reinspire AI prompt. Used to provide context and specific
                      instructions for the AI in this space.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />

            <div className="!mt-8 flex justify-end gap-4">
              <Button
                variant="outline"
                className="min-w-20"
                type="button"
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="min-w-20"
                onClick={handleNextStep}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <>
            {presetsLoading ? (
              <Loader2
                size={32}
                strokeWidth={2.5}
                className="mx-auto my-16 animate-spin text-primary"
              />
            ) : presetsError ? (
              <FetchError />
            ) : (
              <div className="space-y-8">
                <FormField
                  control={form.control}
                  name="preset_id"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex flex-col gap-2">
                        <div className="grid-rows-auto grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
                          {presets?.map((preset, i) => (
                            <button
                              key={i}
                              type="button"
                              onClick={() => {
                                form.setValue("preset_id", preset.id);
                                setSelectedPresetId(preset.id);
                              }}
                              className={cn(
                                "flex flex-row items-center justify-start gap-3 rounded-sm border border-border px-3 py-2.5 transition-transform active:scale-95",
                                field.value === preset.id &&
                                  "scale-[1.01] border-primary",
                              )}
                            >
                              <div
                                className="flex size-11 flex-shrink-0 items-center justify-center rounded-full border"
                                style={{
                                  borderColor: preset.color,
                                  backgroundColor: `${preset.color}33`,
                                }}
                              >
                                <Icon
                                  name={
                                    preset.icon as keyof typeof dynamicIconImports
                                  }
                                  size={20}
                                  strokeWidth={2.5}
                                  style={{ color: preset.color }}
                                />
                              </div>
                              <div className="text-start">
                                <div className="font-bricolage-medium text-sm font-semibold">
                                  {preset.name}
                                </div>
                                <div className="line-clamp-2 text-xs text-muted-foreground">
                                  {preset.description}
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                        <div className="relative my-4 hidden h-px w-full shrink-0 bg-border bg-opacity-45 md:block">
                          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2.5 text-center text-sm tracking-[-0.3px] text-muted-foreground">
                            or
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            form.setValue("preset_id", null);
                            setSelectedPresetId(null);
                          }}
                          className={cn(
                            "flex flex-row items-center justify-start gap-3 rounded-sm border border-border px-3 py-2.5 transition-transform active:scale-95",
                            field.value === null &&
                              "scale-[1.01] border-primary",
                          )}
                        >
                          <div className="flex size-11 items-center justify-center rounded-full border border-border border-gray-600 bg-gray-600/20 text-gray-600">
                            <Settings2 strokeWidth={2.5} size={22} />
                          </div>
                          <div className="flex flex-col items-start">
                            <div className="text-sm font-semibold">
                              Blank Space (No Preset)
                            </div>
                            <div className="text-xs text-muted-foreground">
                              Start from scratch with a blank space.
                            </div>
                          </div>
                        </button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {selectedPresetId && (
                  <div className="space-y-4">
                    <span className="text-sm font-semibold">
                      Preset Prompts:
                    </span>
                    <div className="grid-rows-auto grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
                      {displayPrompts.map((prompt, i) => (
                        <div
                          key={i}
                          className="flex flex-col gap-1 rounded-sm border border-border p-3"
                        >
                          <span className="font-bricolage-medium">
                            {prompt.name}
                          </span>
                          <div className="line-clamp-2 text-sm text-muted-foreground">
                            {prompt.prompt}
                          </div>
                        </div>
                      ))}
                    </div>
                    {prompts.length > 4 && (
                      <div className="mt-4 flex justify-center">
                        <Button
                          size="sm"
                          type="button"
                          variant="outline"
                          onClick={() =>
                            setShowAllPresetPrompts(!showAllPresetPrompts)
                          }
                        >
                          {showAllPresetPrompts ? "See Less" : "See More"}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="!mt-8 flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                className="min-w-20"
                onClick={onPrevStep}
                disabled={status === STATUS.LOADING}
              >
                Back
              </Button>
              <Button
                type="submit"
                className="min-w-20"
                isLoading={status === STATUS.LOADING}
              >
                Create Space
              </Button>
            </div>
          </>
        )}
      </form>
    </Form>
  );
};

export default NewSpaceForm;
