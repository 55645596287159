import useSWRImmutable from "swr/immutable";
import { fetcher } from "@/api/index";

export const pricesUrl = "/prices";

export const usePrices = () => {
  const { data, error, isLoading } = useSWRImmutable<PriceItem[], Error>(
    pricesUrl,
    fetcher,
  );

  return {
    prices: data,
    pricesError: error,
    pricesLoading: isLoading,
  };
};
