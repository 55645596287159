import useSWR from "swr";
import { fetcher } from "@/api/index";
import { AxiosError } from "axios";

export const usersMeUrl = "/users/me";

export const useUser = () => {
  const { data, error, isLoading } = useSWR<User, Error>(usersMeUrl, fetcher, {
    onErrorRetry: (err) => {
      const error = err as AxiosError;
      // Never retry on 401.
      if (error.status === 401) return;
    },
  });

  return {
    user: data,
    userError: error,
    userLoading: isLoading,
  };
};
