import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import LogoUrl from "@/assets/images/logo-square.png";

import FetchError from "@/components/Error/FetchError";
import BillingPlanCard from "@/views/Account/components/Card/BillingPlanCard";
import { planFeatures } from "@/views/Account/components/Utils/PlanFeatures";
import { useAppContext } from "@/app/AppContextProvider";

import { usePrices } from "@/api/hooks/usePrices";

type PaywallProps = {
  showPaywall: boolean;
  setShowPaywall: (show: boolean) => void;
};

const Paywall = ({ showPaywall, setShowPaywall }: PaywallProps) => {
  const { paddle } = useAppContext();

  const [activeTab, setActiveTab] = useState<"month" | "year">("year");
  const { prices, pricesError, pricesLoading } = usePrices();

  return (
    <Dialog open={showPaywall} onOpenChange={setShowPaywall}>
      <DialogContent className="flex w-full max-w-[1100px] flex-col items-center gap-3 bg-card py-10">
        <img src={LogoUrl} alt="Reinspire Logo" className="size-16" />
        <h1 className="text-center font-bricolage-semibold text-3xl">
          Supercharge Your Content Production
        </h1>
        <p className="w-2/3 text-center text-muted-foreground">
          Choose the plan that works best for you — cancel anytime.
        </p>

        <Tabs
          value={activeTab}
          onValueChange={(value) => setActiveTab(value as "month" | "year")}
          className="mt-4 space-y-6"
        >
          <div className="flex flex-row items-center justify-center">
            <TabsList className="relative bg-secondary">
              <TabsTrigger value="month" className="font-bricolage-regular">
                Monthly
              </TabsTrigger>
              <TabsTrigger value="year" className="font-bricolage-regular">
                Yearly
              </TabsTrigger>
            </TabsList>
          </div>
          <div className="flex items-center justify-center">
            {pricesLoading ? (
              <div className="flex w-full flex-row flex-wrap items-center justify-center gap-8">
                <Skeleton className="h-96 w-[300px] rounded-sm border border-border" />
                <Skeleton className="h-96 w-[300px] rounded-sm border border-border" />
                <Skeleton className="h-96 w-[300px] rounded-sm border border-border" />
              </div>
            ) : pricesError ? (
              <FetchError />
            ) : (
              <>
                <TabsContent
                  value="month"
                  className="mt-0 flex flex-row flex-wrap justify-center gap-8"
                >
                  {prices!
                    .filter(
                      (price_item) =>
                        price_item.price.billing_cycle.interval === "month",
                    )
                    .map((price_item, index) => (
                      <BillingPlanCard
                        key={price_item.price.id}
                        priceId={price_item.price.id}
                        title={price_item.price.name}
                        price={
                          parseFloat(price_item.price.unit_price.amount) / 100
                        }
                        period="monthly"
                        activeSubscription={null}
                        isUserSubscribed={false}
                        features={planFeatures[index]}
                        paddle={paddle}
                        onBeforeCheckout={() => setShowPaywall(false)}
                      />
                    ))}
                </TabsContent>
                <TabsContent
                  value="year"
                  className="mt-0 flex flex-row flex-wrap justify-center gap-8"
                >
                  {prices!
                    .filter(
                      (price_item) =>
                        price_item.price.billing_cycle.interval === "year",
                    )
                    .map((price_item, index) => (
                      <BillingPlanCard
                        key={price_item.price.id}
                        priceId={price_item.price.id}
                        title={price_item.price.name}
                        price={
                          parseFloat(price_item.price.unit_price.amount) / 100
                        }
                        period="yearly"
                        discount={25}
                        activeSubscription={null}
                        isUserSubscribed={false}
                        features={planFeatures[index]}
                        paddle={paddle}
                        onBeforeCheckout={() => setShowPaywall(false)}
                      />
                    ))}
                </TabsContent>
              </>
            )}
          </div>
        </Tabs>

        <p className="py-6 text-center text-muted-foreground">
          Want more minutes or need a custom plan?{" "}
          <a
            href="mailto:support@reinspire.io"
            className="text-primary underline"
          >
            Contact us
          </a>
        </p>

        <h2 className="font-bricolage-semibold text-2xl">Pricing FAQ</h2>
        <Accordion
          type="single"
          collapsible
          className="w-full px-4 pb-6 lg:w-2/3"
        >
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-start">
              How do processing minutes work?
            </AccordionTrigger>
            <AccordionContent>
              Every plan comes with a certain number of processing minutes.
              Every time you create a recording from a media file, it
              transcribes it into text. The length of the media file determines
              the number of processing minutes used. (e.g. a 30 minute youtube
              video will use 30 processing minutes)
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Can I cancel my subscription?</AccordionTrigger>
            <AccordionContent>
              Yes, you can cancel your subscription at any time. Your account
              will remain active until the end of your current billing cycle.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>
              What happens if I use all my minutes?
            </AccordionTrigger>
            <AccordionContent>
              If you use all your minutes, you can always upgrade your plan to
              get more minutes.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>Do you offer refunds?</AccordionTrigger>
            <AccordionContent>
              We do not offer refunds. However, you can cancel your subscription
              at any time. More about our refund policy can be found in our{" "}
              <a
                href="https://staging.reinspire.io/terms#payments_and_refunds"
                target="_blank"
                className="text-primary underline"
              >
                Terms of Service
              </a>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <p className="text-center text-xs text-muted-foreground">
          <a
            href="https://reinspire.io/terms"
            target="_blank"
            className="text-black underline"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://reinspire.io/privacy"
            target="_blank"
            className="text-black underline"
          >
            Privacy Policy
          </a>
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default Paywall;
