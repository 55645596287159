import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { updateUser } from "@/api/services/user";

const formSchema = z.object({
  current_password: z.string(),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .regex(/[0-9]/, "Password must contain at least 1 number"),
  confirm_password: z.string(),
});

type formSchema = z.infer<typeof formSchema>;

const PasswordForm = () => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const { toast } = useToast();

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      current_password: "",
      confirm_password: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    if (data.password !== data.confirm_password) {
      form.setError("confirm_password", {
        message: "Passwords do not match",
      });
      setStatus(STATUS.ERROR);
      return;
    }

    if (data.password === data.current_password) {
      form.setError("password", {
        message: "New password must be different from the current password",
      });
      setStatus(STATUS.ERROR);
      return;
    }

    try {
      await updateUser(data);
      setStatus(STATUS.SUCCESS);
      toast({
        title: "Password updated successfully",
        variant: "success",
      });
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  useEffect(() => {
    form.reset();
  }, [form, form.formState.isSubmitSuccessful]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-full flex-col gap-4 md:w-3/4 lg:w-1/2"
      >
        {status === STATUS.ERROR && form.formState.errors.root && (
          <Alert type="error" message={form.formState.errors.root.message!} />
        )}

        <FormField
          control={form.control}
          name="current_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Current Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your current password"
                  {...field}
                />
              </FormControl>
              <FormMessage {...field} />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your new password"
                  showEye={true}
                  {...field}
                />
              </FormControl>
              <FormMessage {...field} />
              <FormDescription>
                Password must be at least 8 characters long and contain at least
                1 uppercase letter and 1 number
              </FormDescription>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirm_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Repeat your new password"
                  {...field}
                />
              </FormControl>
              <FormMessage {...field} />
            </FormItem>
          )}
        />
        <Button
          size="sm"
          type="submit"
          isLoading={status === STATUS.LOADING}
          className="w-fit"
        >
          Update password
        </Button>
      </form>
    </Form>
  );
};

export default PasswordForm;
