import { Link } from "react-router-dom";
import LogoUrl from "@/assets/images/logo-square.png";

import MobileSidebar from "@/components/Sidebar/MobileSidebar";

type MobileHeaderProps = {
  setShowPaywall: (show: boolean) => void;
};

const MobileHeader = ({ setShowPaywall }: MobileHeaderProps) => {
  return (
    <header className="fixed left-0 right-0 top-0 z-20 flex h-14 items-center border-b border-border bg-white lg:hidden">
      <div className="container mx-auto flex items-center justify-between px-4 py-2">
        <Link to="/" className="flex flex-row items-center gap-2 text-black">
          <img src={LogoUrl} alt="Reinspire" className="size-9" />
          <h1 className="font-bricolage-semibold text-2xl">Reinspire</h1>
        </Link>
        <MobileSidebar setShowPaywall={setShowPaywall} />
      </div>
    </header>
  );
};

export default MobileHeader;
