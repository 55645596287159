import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { deleteMedia } from "@/api/services/media";

type DeleteMediaDialogProps = {
  media: Media;
};

const DeleteMediaDialog = ({ media }: DeleteMediaDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [error, setError] = useState<string>("");

  const handleDelete = async () => {
    setStatus(STATUS.LOADING);
    try {
      await deleteMedia(media.id);
      setDialogOpen(false);
      setStatus(STATUS.SUCCESS);
    } catch (err) {
      const error = err as Error;
      setError(error.message);
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          disabled={media.status !== "completed" && media.status !== "failed"}
          className="size-8 rounded-sm transition-colors hover:border-destructive hover:bg-destructive/10"
        >
          <Trash2 size={16} strokeWidth={2.5} className="text-destructive" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          {status === STATUS.ERROR && <Alert type="error" message={error!} />}
          <AlertDialogDescription>
            Are you sure you want to delete{" "}
            <span className="break-all">"{media.title}"</span>? This action
            cannot be undone. This will permanently delete the following media
            from all spaces.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="min-w-20">Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={handleDelete}
            isLoading={status === STATUS.LOADING}
            className="min-w-20"
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteMediaDialog;
