import { useState } from "react";
import {
  flexRender,
  FilterFn,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Search, ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";

import FetchError from "@/components/Error/FetchError";
import { columns } from "@/views/Media/components/Table/MediaTableColumns";

import { useMedia } from "@/api/hooks/useMedia";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<Media> = (row, _, value, addMeta) => {
  const searchableString =
    row.getValue("title") + (" " + row.original.src_original_url || "");
  const itemRank = rankItem(searchableString, value);
  addMeta({ itemRank });
  return itemRank.passed;
};

const EmptyRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <tr className="w-full">
      <td className="h-40 w-full py-8">{children}</td>
    </tr>
  );
};

const MediaDataTable = () => {
  const { media, mediaError, mediaLoading } = useMedia();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data: media || [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    globalFilterFn: fuzzyFilter,
  });

  const noMediaFound =
    !mediaLoading && !mediaError && table.getRowModel().rows.length === 0;

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <Input
          placeholder="Search Title / URL..."
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="h-9 max-w-sm"
          startIcon={Search}
        />
      </div>
      <div className="overflow-x-auto rounded-sm border border-border">
        <Table className="table-fixed w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className="flex flex-row items-center min-w-max"
              >
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className={cn(
                      " h-10 px-4 py-2.5",
                      header.column.columnDef.id === "title" && "flex-grow",
                    )}
                    style={{
                      width: header.column.columnDef.size,
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {mediaLoading ? (
              <EmptyRow>
                <div className="flex w-full items-center justify-center py-4">
                  <Loader2
                    size={28}
                    strokeWidth={2.5}
                    className="animate-spin text-primary"
                  />
                </div>
              </EmptyRow>
            ) : mediaError ? (
              <EmptyRow>
                <FetchError />
              </EmptyRow>
            ) : noMediaFound ? (
              <EmptyRow>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-sm text-muted-foreground">
                    No media found.
                  </span>
                </div>
              </EmptyRow>
            ) : (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="flex flex-row items-center min-w-max"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "block px-4 py-2.5",
                        cell.column.columnDef.id === "title" && "flex-grow",
                      )}
                      style={{
                        width: cell.column.columnDef.size,
                        minWidth: cell.column.columnDef.minSize,
                        maxWidth: cell.column.columnDef.maxSize,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-4 flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-3">
          <Button
            variant="outline"
            size="icon"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="size-9"
          >
            <ChevronLeft size={16} strokeWidth={2.5} />
          </Button>
          <span className="text-sm">
            {table.getState().pagination.pageIndex + 1} /{" "}
            {table.getPageCount() === 0 ? 1 : table.getPageCount()}
          </span>
          <Button
            variant="outline"
            size="icon"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="size-9"
          >
            <ChevronRight size={16} strokeWidth={2.5} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MediaDataTable;
