import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Star } from "lucide-react";
import { cn } from "@/lib/utils";

import AlexNImg from "@/assets/images/alex-n.webp";
import SubhenduPImg from "@/assets/images/subhendu-p.webp";
import JacksonGImg from "@/assets/images/jackson-g.webp";

interface Testimonial {
  content: string;
  author: string;
  role: string;
  image: string;
}

const testimonials: Testimonial[] = [
  {
    content:
      "Reinspire has been a game-changer for my social media strategy! I used to spend hours trying to create content, but now I can quickly repurpose existing material into fresh, engaging posts.",
    author: "Alex N.",
    role: "Social Media Manager",
    image: AlexNImg,
  },
  {
    content:
      "Reinspire is an innovative tool. Creating content is not for everyone, this really helps a lot!",
    author: "Subhendu P.",
    role: "Founder @ Shootmail",
    image: SubhenduPImg,
  },
  {
    content:
      "Reinspire is a great tool for creating content. It's easy to use and saves me a lot of time.",
    author: "Jackson G.",
    role: "Content Creator",
    image: JacksonGImg,
  },
];

const TestimonialSlider: React.FC = () => {
  const [[current, direction], setCurrent] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent(([current]) => [(current + 1) % testimonials.length, 1]);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative z-10">
      <div className="relative">
        <AnimatePresence initial={false} custom={direction} mode="popLayout">
          <motion.div
            key={current}
            custom={direction}
            variants={{
              enter: (direction: number) => ({
                x: direction > 0 ? 1000 : -1000,
                opacity: 0,
                position: "absolute",
              }),
              center: {
                x: 0,
                opacity: 1,
                position: "relative",
              },
              exit: (direction: number) => ({
                x: direction < 0 ? 1000 : -1000,
                opacity: 0,
                position: "absolute",
              }),
            }}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 40 },
              opacity: { duration: 0.2 },
            }}
            className="w-full"
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                {[...Array(5)].map((_, index) => (
                  <Star
                    key={index}
                    size={20}
                    fill="#FFD700"
                    color="#FFD700"
                    strokeWidth={2}
                  />
                ))}
              </div>
              <p className="text-md font-semibold text-white">
                {testimonials[current].content}
              </p>
              <div className="flex flex-row items-center gap-4">
                <img
                  src={testimonials[current].image}
                  alt={`${testimonials[current].author} testimonial`}
                  className="z-10 h-9 w-9 rounded-full"
                />
                <div className="flex flex-col">
                  <h4 className="text-base font-semibold text-white">
                    {testimonials[current].author}
                  </h4>
                  <h5 className="pr-5 text-xs font-semibold text-white">
                    {testimonials[current].role}
                  </h5>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      <div className="mt-8 flex justify-center gap-2">
        {testimonials.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrent([index, current > index ? -1 : 1])}
            className={cn(
              "h-2 rounded-full transition-all",
              current === index ? "w-4 bg-white" : "w-2 bg-white/50",
            )}
            aria-label={`Go to testimonial ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;
