import { useState } from "react";
import { useParams } from "react-router";
import * as z from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Plus } from "lucide-react";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { createSpacePrompt } from "@/api/services/space";

const formSchema = z.object({
  name: z
    .string()
    .min(1, "Name is required")
    .max(32, "Name should not exceed 32 characters"),
  prompt: z
    .string()
    .min(1, "Prompt is required")
    .max(1000, "Prompt should not exceed 1000 characters"),
});

type formSchema = z.infer<typeof formSchema>;

const NewPromptDialog = () => {
  const { spaceSlug } = useParams();
  const { toast } = useToast();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(STATUS.IDLE);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      prompt: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await createSpacePrompt(spaceSlug!, data);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
      toast({
        title: "Prompt created successfully",
        variant: "success",
      });
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          New Prompt
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle>Create Prompt</DialogTitle>
          <DialogDescription>
            Add a custom prompt to this space
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {status === STATUS.ERROR && (
            <Alert
              type="error"
              message={form.formState.errors.root!.message!}
            />
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter prompt name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="prompt"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Prompt</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Enter prompt"
                        className="max-h-96 min-h-56"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter className="gap-2">
                <DialogClose asChild>
                  <Button variant="outline" className="min-w-20">
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  isLoading={status === STATUS.LOADING}
                  className="min-w-20"
                >
                  Create Prompt
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewPromptDialog;
