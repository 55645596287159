import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Edit2 } from "lucide-react";

import AssignSpeakerForm from "@/views/Recordings/components/Form/AssignSpeakerForm";

type AssignSpeakerDialogProps = {
  recording: Recording;
  transcriptChunk: TranscriptChunk;
  transcriptChunkMeta: RecordingTranscriptChunk;
};

const AssignSpeakerDialog = ({
  recording,
  transcriptChunk,
  transcriptChunkMeta,
}: AssignSpeakerDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <button className="group flex flex-row items-center gap-2 font-bricolage-medium text-sm text-muted-foreground underline-offset-2 hover:underline">
          {transcriptChunkMeta.space_speaker?.assigned_label ||
            transcriptChunk.speaker.replace(/_/g, " ")}
          <Edit2
            size={14}
            strokeWidth={2.5}
            className="hidden text-primary group-hover:block"
          />
        </button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle>Assign Speaker</DialogTitle>
          <DialogDescription>
            Assign the speaker to a paragraph in the transcript
          </DialogDescription>
        </DialogHeader>

        <AssignSpeakerForm
          recording={recording}
          transcriptChunk={transcriptChunk}
          transcriptChunkMeta={transcriptChunkMeta}
          setDialogOpen={setDialogOpen}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AssignSpeakerDialog;
