import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { MoveLeft, Settings, Text, PlayCircle } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { motion } from "framer-motion";
import MenuButton from "@/components/Button/MenuButton";

type SpaceDetailsProps = {
  space: Space | undefined;
  spaceLoading: boolean;
  spaceError: AxiosError | undefined;
};

export const SpaceDetails = ({
  space,
  spaceLoading,
  spaceError,
}: SpaceDetailsProps) => {
  if (spaceLoading || spaceError) {
    return (
      <>
        <Skeleton className="size-9 rounded-full" />
        <div className="space-y-1.5">
          <Skeleton className="h-2.5 w-32" />
          <Skeleton className="h-2 w-20" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="relative size-9 flex-shrink-0 overflow-hidden rounded-full">
        <div className="absolute left-0 right-0 m-auto flex size-9 items-center justify-center text-4xl blur-[12px]">
          {space!.emoji}
        </div>
        <div className="relative flex size-9 items-center justify-center rounded-full border border-gray-300">
          {space!.emoji}
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className="line-clamp-1">{space!.name}</h2>
        {space!.preset && (
          <small className="text-xs">{space!.preset.name}</small>
        )}
      </div>
    </>
  );
};

export const SpaceNav = () => {
  return (
    <>
      <div className="space-y-2 py-4">
        <div className="space-y-2 pb-3">
          <span className="font-bricolage-medium text-xs text-muted-foreground">
            SPACE
          </span>
          <div className="space-y-1">
            <MenuButton
              to="recordings"
              text="Recordings"
              startContent={<PlayCircle size={16} strokeWidth={2.5} />}
            />
          </div>
        </div>
        <Separator />
        <div className="space-y-2 py-3">
          <span className="font-bricolage-medium text-xs text-muted-foreground">
            SETTINGS
          </span>
          <div className="space-y-2">
            <MenuButton
              to="settings"
              text="Settings"
              startContent={<Settings size={16} strokeWidth={2.5} />}
            />
            <MenuButton
              to="prompts"
              text="Prompts"
              startContent={<Text size={16} strokeWidth={2.5} />}
            />
          </div>
        </div>
      </div>
      <Separator />
      <div className="flex h-16 flex-shrink-0 flex-row items-center">
        <Link
          to="/spaces"
          className="flex items-center gap-2 font-bricolage-regular text-muted-foreground"
        >
          <MoveLeft size={16} strokeWidth={2.5} />
          <span className="text-[15px]">Spaces</span>
        </Link>
      </div>
    </>
  );
};

type SidebarProps = {
  space: Space | undefined;
  spaceLoading: boolean;
  spaceError: AxiosError | undefined;
};

const Sidebar = (props: SidebarProps) => {
  const { space, spaceLoading, spaceError } = props;

  return (
    <motion.div
      className="fixed left-16 z-0 hidden h-screen w-56 flex-col border-r border-border bg-card lg:flex"
      initial={{ x: "-80%" }}
      animate={{ x: 0 }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      <div className="flex h-full flex-col px-4">
        <div className="flex h-16 flex-row items-center gap-2">
          <SpaceDetails
            space={space}
            spaceLoading={spaceLoading}
            spaceError={spaceError}
          />
        </div>
        <SpaceNav />
      </div>
    </motion.div>
  );
};

export default Sidebar;
