import { useState } from "react";
import * as z from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Edit2 } from "lucide-react";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { editMedia } from "@/api/services/media";

const formSchema = z.object({
  title: z
    .string()
    .min(1, "Title is required")
    .max(120, "Title should not exceed 120 characters"),
});

type formSchema = z.infer<typeof formSchema>;

type EditMediaDialogProps = {
  media: Media;
};

const EditMediaDialog = ({ media }: EditMediaDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(STATUS.IDLE);

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: media.title,
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await editMedia(media.id, data);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button
          size="icon"
          variant="ghost"
          disabled={media.status !== "completed"}
          className="size-8"
        >
          <Edit2 size={16} strokeWidth={2.5} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Rename Media</DialogTitle>
          <DialogDescription>
            Rename the media title to better describe the content.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {status === STATUS.ERROR && (
            <Alert
              type="error"
              message={form.formState.errors.root!.message!}
            />
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter media title" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="gap-2">
                <DialogClose asChild>
                  <Button variant="outline" className="min-w-20">
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  isLoading={status === STATUS.LOADING}
                  className="min-w-20"
                >
                  Save
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditMediaDialog;
