import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import axios, { AxiosError, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { mutate } from "swr";
import { mediaUrl } from "@/api/hooks/useMedia";

// Media
type EditMediaData = {
  title: string;
};

export const editMedia = async (id: string, data: EditMediaData) => {
  try {
    const response = await apiInstance.patch(`/media/${id}`, data);
    const updatedMedia = response.data;
    mutate(
      mediaUrl,
      (data) =>
        data.map((media: Media) =>
          media.id === id ? { ...media, ...updatedMedia } : media,
        ),
      { revalidate: false },
    );
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const deleteMedia = async (id: string) => {
  try {
    await apiInstance.delete(`/media/${id}`);
    mutate(mediaUrl, (data) => data.filter((media: Media) => media.id !== id), {
      revalidate: false,
    });
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

// Upload
type InitializeUploadResponse = {
  upload_id: string;
  key: string;
  chunk_size: number;
  urls: string[];
  fields: Record<string, string>;
};

export const initUpload = async (
  file: File,
): Promise<InitializeUploadResponse> => {
  try {
    const response = await apiInstance.post("/media/initialize-upload", {
      file_name: file.name,
      file_size: file.size,
      content_type: file.type,
    });
    return response.data as InitializeUploadResponse;
  } catch (err) {
    const error = err as AxiosError;
    console.error(`Failed to initialize upload for ${file.name}:`, error);
    throw new Error("Failed to initialize upload");
  }
};

export const uploadChunk = async (
  url: string,
  chunk: Blob,
  contentType: string,
  signal: AbortSignal,
): Promise<string> => {
  axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

  try {
    const response = await axios.put(url, chunk, {
      headers: {
        "Content-Type": contentType,
      },
      signal: signal,
    });
    return response.headers.etag;
  } catch (err) {
    const error = err as AxiosError;
    console.error(`Failed to upload chunk to ${url}:`, error);
    throw new Error("Failed to upload chunk");
  }
};

export const completeUpload = async (
  key: string,
  uploadId: string,
  etags: string[],
): Promise<Media> => {
  try {
    const response = await apiInstance.post("/media/complete-upload", {
      key,
      upload_id: uploadId,
      etags,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      console.error(`Failed to complete upload for ${key}:`, error);
      throw new Error("Something went wrong. Try again.");
    }
  }
};

type urlUploadResponse = {
  task_id: string;
  media: Media;
};

export const urlUpload = async (url: string): Promise<urlUploadResponse> => {
  try {
    const response = await apiInstance.post("/media/url-upload", {
      url,
    });
    mutate(mediaUrl, (data) => [response.data.media, ...data], {
      revalidate: false,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error.");
    }

    if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    }

    throw new Error("Something went wrong. Try again.");
  }
};
