import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  ChevronUp,
  ChevronDown,
  ChevronsUpDown,
  CircleDashed,
  RefreshCw,
  AlertCircle,
} from "lucide-react";
import { formatDate } from "@/lib/date";

import MediaIcon from "@/components/Icons/MediaIcon";
import AssignRecordingDialog from "@/views/Media/components/Dialog/AssignRecordingDialog";
import EditMediaDialog from "@/views/Media/components/Dialog/EditMediaDialog";
import DeleteMediaDialog from "@/views/Media/components/Dialog/DeleteMediaDialog";

export const columns: ColumnDef<Media, string[]>[] = [
  {
    id: "type",
    accessorKey: "type",
    header: () => {
      return <div className="text-center">#</div>;
    },
    cell: ({ row }) => {
      const status = row.original.status;
      const type = row.original.type;
      const source = row.original.source;
      return (
        <div className="flex size-9 items-center justify-center rounded-sm border border-border">
          {status === "pending" ? (
            <CircleDashed
              size={15}
              strokeWidth={2.5}
              className="animate-pulse text-primary"
            />
          ) : status === "processing" ? (
            <RefreshCw
              size={15}
              strokeWidth={2.5}
              className="animate-spin text-muted-foreground"
            />
          ) : status === "failed" ? (
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <AlertCircle
                    size={15}
                    strokeWidth={2.5}
                    className="text-destructive"
                  />
                </TooltipTrigger>
                <TooltipContent className="max-w-60 text-sm" side="bottom">
                  Unexpected error during media processing. Our team is informed
                  about this issue.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <MediaIcon source={type || source} />
          )}
        </div>
      );
    },
    size: 70,
    minSize: 70,
    maxSize: 70,
  },
  {
    id: "title",
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <button
          className="flex flex-row items-center gap-1"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Title / URL
          {column.getIsSorted() === "asc" ? (
            <ChevronUp size={15} strokeWidth={2} />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown size={15} strokeWidth={2} />
          ) : (
            <ChevronsUpDown size={15} strokeWidth={2} />
          )}
        </button>
      );
    },
    cell: ({ row }) => {
      const sourceLink =
        row.original.src_original_url || row.original.file?.src_url;
      return (
        <a
          href={sourceLink!}
          target="_blank"
          className="line-clamp-1 underline-offset-2 hover:text-primary hover:underline"
        >
          {row.original.title}
        </a>
      );
    },
    minSize: 260,
  },
  {
    id: "created_at",
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <button
          className="flex  flex-row items-center gap-1"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Imported at
          {column.getIsSorted() === "asc" ? (
            <ChevronUp size={15} strokeWidth={2} />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown size={15} strokeWidth={2} />
          ) : (
            <ChevronsUpDown size={15} strokeWidth={2} />
          )}
        </button>
      );
    },
    cell: ({ row }) => {
      return (
        <span className="">
          {formatDate(row.getValue("created_at"), "MMM D, YYYY")}
        </span>
      );
    },
    size: 144,
    minSize: 144,
    maxSize: 144,
  },
  {
    id: "spaces",
    header: () => {
      return <span>Spaces</span>;
    },
    cell: ({ row }) => {
      const mediaSpaces = row.original.recordings.map(
        (recording) => recording.space,
      );
      return (
        <div className="flex flex-row space-x-2">
          {mediaSpaces.map((space, index) => (
            <TooltipProvider key={index} skipDelayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    to={`/spaces/${space.slug}/recordings`}
                    className="flex size-8 items-center justify-center rounded-sm border border-border text-base hover:border-primary hover:bg-primary/10 hover:text-primary"
                  >
                    {space.emoji}
                  </Link>
                </TooltipTrigger>
                <TooltipContent className="text-sm shadow-none" side="bottom">
                  {space.name}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}

          <AssignRecordingDialog
            row={row}
            mediaRecordings={row.original.recordings}
          />
        </div>
      );
    },
    size: 256,
    minSize: 256,
    maxSize: 256,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const media = row.original;
      return (
        <div className="flex flex-row space-x-2">
          <EditMediaDialog media={media} />
          <DeleteMediaDialog media={media} />
        </div>
      );
    },
    size: 128,
    minSize: 128,
    maxSize: 128,
  },
];
