import { ColumnDef } from "@tanstack/react-table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  ChevronUp,
  ChevronDown,
  ChevronsUpDown,
  CircleDashed,
  RefreshCw,
  AlertCircle,
  CheckCircle2,
} from "lucide-react";
import { formatDate } from "@/lib/date";

import MediaIcon from "@/components/Icons/MediaIcon";
import RecordingDropdown from "@/views/Recordings/components/Dropdown/RecordingDropdown";
import TranscribeButton from "@/views/Recordings/components/Button/TranscribeButton";

export const columns: ColumnDef<RecordingPreview>[] = [
  {
    id: "title",
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <button
          className="flex flex-row items-center gap-1"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Title
          {column.getIsSorted() === "asc" ? (
            <ChevronUp size={15} strokeWidth={2} />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown size={15} strokeWidth={2} />
          ) : (
            <ChevronsUpDown size={15} strokeWidth={2} />
          )}
        </button>
      );
    },
    cell: ({ row }) => {
      const source = row.original.media.source;
      const type = row.original.media.type;
      return (
        <div className="flex flex-row items-center gap-2">
          <MediaIcon source={type || source} />
          <span className="line-clamp-1">{row.original.media.title}</span>
        </div>
      );
    },
    minSize: 260,
  },
  {
    id: "status",
    accessorKey: "status",
    header: () => {
      return <span>Status</span>;
    },
    cell: ({ row }) => {
      const status = row.original.transcript_status;
      return (
        <div className="flex items-center rounded-sm">
          {status === "not_started" ? (
            <span>—</span>
          ) : status === "pending" ? (
            <div className="flex flex-row items-center gap-1.5">
              <CircleDashed
                size={15}
                strokeWidth={2.5}
                className="animate-pulse text-primary"
              />
              <span>Pending</span>
            </div>
          ) : status === "processing" ? (
            <div className="flex flex-row items-center gap-1.5">
              <RefreshCw
                size={15}
                strokeWidth={2.5}
                className="animate-spin text-muted-foreground"
              />
              <span>Processing</span>
            </div>
          ) : status === "completed" ? (
            <div className="flex flex-row items-center gap-1.5">
              <CheckCircle2
                size={15}
                strokeWidth={2.5}
                className="text-green-600"
              />
              <span>Completed</span>
            </div>
          ) : (
            status === "failed" && (
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex flex-row items-center gap-1.5">
                      <AlertCircle
                        size={15}
                        strokeWidth={2.5}
                        className="text-destructive"
                      />
                      <span>Failed</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-60 text-sm" side="bottom">
                    Unexpected error during media processing. Our team is
                    informed about this issue.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )
          )}
        </div>
      );
    },
    size: 176,
    minSize: 176,
  },
  {
    id: "created_at",
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <button
          className="flex flex-row items-center gap-1"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created at
          {column.getIsSorted() === "asc" ? (
            <ChevronUp size={15} strokeWidth={2} />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown size={15} strokeWidth={2} />
          ) : (
            <ChevronsUpDown size={15} strokeWidth={2} />
          )}
        </button>
      );
    },
    cell: ({ row }) => {
      return (
        <span>{formatDate(row.getValue("created_at"), "MMM D, YYYY")}</span>
      );
    },
    size: 160,
    minSize: 160,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <div className="flex flex-row items-center justify-end space-x-2">
          {row.original.transcript_status === "not_started" && (
            <TranscribeButton
              recordingId={row.original.id}
              size="sm"
              variant="ghost"
              className="h-8"
            />
          )}

          <RecordingDropdown recording={row.original} />
        </div>
      );
    },
  },
];
