import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import { AxiosError, AxiosResponse } from "axios";
import { mutate } from "swr";
import { usersMeUrl } from "@/api/hooks/useUser";

type OnboardingData = {
  full_name?: string;
  lead_source: string;
  usage_description: string;
};

export const onboardUser = async (data: OnboardingData) => {
  try {
    const response = await apiInstance.post("/users/me/onboarding", data);
    mutate(usersMeUrl, response.data, { revalidate: false });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    }

    throw new Error("Something went wrong. Try again.");
  }
};
