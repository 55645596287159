import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus } from "lucide-react";

import MediaUploadForm from "@/views/Media/components/Form/MediaUploadForm";

const MediaUploadDialog = () => {
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (location.state?.newSpace) {
      setDialogOpen(true);
    }
  }, [location, setDialogOpen]);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus size={18} strokeWidth={2.5} className="mr-1" />
          New Import
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle>New Import</DialogTitle>
          <DialogDescription>
            Import any audio or media to use in your Spaces.
          </DialogDescription>
        </DialogHeader>
        <MediaUploadForm setDialogOpen={setDialogOpen} />
      </DialogContent>
    </Dialog>
  );
};

export default MediaUploadDialog;
