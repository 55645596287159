import { useState } from "react";
import { cn } from "@/lib/utils";
import { CircleCheck, CircleX, Info, TriangleAlert, X } from "lucide-react";

const alertTypes = {
  success: "bg-green-600/20 text-green-600 border-green-600",
  error: "bg-destructive/20 text-destructive border-destructive",
  warning: "bg-yellow-600/20 text-yellow-600 border-yellow-600",
  info: "bg-sky-500/20 text-sky-600 border-sky-600",
};

const alertIcons = {
  success: (
    <CircleCheck size={18} strokeWidth={2.5} className="flex-shrink-0" />
  ),
  error: <CircleX size={18} strokeWidth={2.5} className="flex-shrink-0" />,
  warning: (
    <TriangleAlert size={18} strokeWidth={2.5} className="flex-shrink-0" />
  ),
  info: <Info size={18} strokeWidth={2.5} className="flex-shrink-0" />,
};

type AlertProps = {
  type: "success" | "error" | "warning" | "info";
  message: string;
  className?: string;
  isDismissible?: boolean;
};

const Alert = ({ type, message, className, isDismissible }: AlertProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) return null;

  return (
    <div
      className={cn(
        `flex items-center gap-2 rounded-sm border px-3 py-2`,
        alertTypes[type],
        className,
      )}
    >
      {alertIcons[type]}
      <span className="flex-grow text-sm">{message}</span>
      {isDismissible && (
        <button onClick={() => setIsDismissed(true)} className="ml-auto">
          <X size={18} strokeWidth={2.5} className="hover:text-black" />
        </button>
      )}
    </div>
  );
};

export default Alert;
