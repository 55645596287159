import { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { useMediaRemote } from "@vidstack/react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Info } from "lucide-react";
import { WandSparkles, RefreshCw, CircleDashed } from "lucide-react";
import { motion } from "framer-motion";

import { RecordingContextType } from "@/views/Recordings/RecordingLayout";
import AIContentTable from "@/views/Recordings/components/Table/AIContentTable";

import { STATUS } from "@/api/utils";
import { useTaskPolling } from "@/api/hooks/useTaskPolling";
import { generateAIContent } from "@/api/services/content";
import { mutate } from "swr";

interface ContentTaskInfo {
  status: string;
  progress: number;
}

const AIContent = () => {
  const { spaceSlug } = useParams();
  if (!spaceSlug) throw new Error("Space slug is required");
  const { recording, player } = useOutletContext<RecordingContextType>();
  if (!recording) throw new Error("Recording is required");

  const [status, setStatus] = useState(STATUS.IDLE);
  const [genProgress, setGenProgress] = useState<number>(-1);
  const { toast } = useToast();

  // Check if all speakers are assigned
  const allSpeakersAssigned = recording.transcript_chunks.every(
    (chunk) => chunk.space_speaker,
  );

  // Timestamp click handler
  const remote = useMediaRemote(player);
  const seekToTimestamp = (startTime: number) => {
    remote.seeking(startTime);
    remote.seek(startTime);
  };

  // Poll task status
  const { task } = useTaskPolling(
    recording.content_task_id,
    ["pending", "processing"].includes(recording.content_status),
  );

  // Update progress
  useEffect(() => {
    if (!task) return;

    // If task is completed, refetch the recording
    if (task.status === "SUCCESS") {
      setGenProgress(100);
      mutate(`/spaces/${spaceSlug}/recordings/${recording.id}`);
    } else if (["PENDING", "PROCESSING"].includes(task.status)) {
      const taskInfo = task.info as ContentTaskInfo | undefined;
      if (!taskInfo) return;

      // Update progress
      setGenProgress(taskInfo.progress * 100);
    } else if (task.status === "FAILURE") {
      // If task failed, mutate recording to render error state
      mutate(`/spaces/${spaceSlug}/recordings/${recording.id}`);
    }
  }, [task, recording, spaceSlug]);

  const onGenerateAIContent = async () => {
    setStatus(STATUS.LOADING);

    try {
      await generateAIContent(spaceSlug, recording.id);
      setStatus(STATUS.SUCCESS);
    } catch (error) {
      toast({
        title: "Error generating AI content",
        description: (error as Error).message,
        variant: "error",
      });
      setStatus(STATUS.ERROR);
    }
  };

  // Progress circle properties
  const size = 48;
  const strokeWidth = 5;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className="relative space-y-6">
      {!allSpeakersAssigned && (
        <div className="mt-6 flex flex-row items-center gap-3 rounded-sm border border-primary bg-primary/10 p-3">
          <Info
            className="hidden text-primary md:block"
            size={32}
            strokeWidth={2.5}
          />
          <div className="flex flex-col gap-1">
            <h3 className="flex flex-row items-center gap-1 font-bricolage-medium text-base">
              Label speakers for better AI content
            </h3>
            <p className="text-sm">
              Pro tip: Improve quality of your content by assigning the speakers
              to transcript sections in the{" "}
              <Link
                to={`/spaces/${spaceSlug}/recordings/${recording.id}/transcript`}
                className="underline"
              >
                Transcript Tab
              </Link>
            </p>
          </div>
        </div>
      )}

      {recording.content_status === "not_started" ? (
        <div className="mt-6 flex flex-col justify-between gap-4 rounded-sm border border-border bg-card p-3 md:flex-row md:items-center">
          <div className="space-y-1 md:space-y-0">
            <h3 className="font-bricolage-medium text-base">
              No AI content generated.
            </h3>
            <p className="text-sm text-muted-foreground">
              Generate AI content for this recording to get started.
            </p>
          </div>
          <Button
            size="sm"
            className="flex flex-row items-center gap-2"
            onClick={onGenerateAIContent}
            isLoading={status === STATUS.LOADING}
          >
            {status !== STATUS.LOADING && (
              <WandSparkles size={16} strokeWidth={2} />
            )}
            <span>Generate</span>
          </Button>
        </div>
      ) : ["pending", "processing"].includes(recording.content_status) ? (
        <div className="mt-6 flex flex-col justify-between gap-4 rounded-sm border border-border bg-card p-3 md:flex-row md:items-center">
          <div className="space-y-1 md:space-y-0">
            <h3 className="flex flex-row items-center gap-1 font-bricolage-medium text-base">
              {task?.status === "PENDING" ? (
                <CircleDashed
                  size={16}
                  strokeWidth={2.5}
                  className="animate-pulse text-primary"
                />
              ) : (
                <RefreshCw
                  size={16}
                  strokeWidth={2.5}
                  className="animate-spin text-primary"
                />
              )}
              {task?.status === "PENDING"
                ? "Content generation is queued"
                : "Generating AI content"}
            </h3>
            <p className="text-sm text-muted-foreground">
              {task?.status === "PENDING"
                ? "AI content generation is queued and will start shortly."
                : "Generation is in progress. This usually takes a few minutes."}
            </p>
          </div>
          {genProgress > 0 ? (
            <div className="relative h-11 w-11">
              <svg
                className="h-full w-full -rotate-90"
                viewBox={`0 0 ${size} ${size}`}
              >
                <circle
                  className="text-gray-200"
                  strokeWidth={strokeWidth}
                  stroke="currentColor"
                  fill="transparent"
                  r={radius}
                  cx={size / 2}
                  cy={size / 2}
                />
                <motion.circle
                  className="text-primary"
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r={radius}
                  cx={size / 2}
                  cy={size / 2}
                  initial={{
                    strokeDasharray: circumference,
                    strokeDashoffset: circumference,
                  }}
                  animate={{
                    strokeDashoffset:
                      circumference - (genProgress / 100) * circumference,
                  }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </svg>
              <motion.span
                className="absolute inset-0 flex items-center justify-center font-bricolage-medium text-xs text-muted-foreground"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {Math.round(genProgress)}%
              </motion.span>
            </div>
          ) : null}
        </div>
      ) : recording.content_status === "failed" ? (
        <div className="mt-6 flex flex-col justify-between gap-4 rounded-sm border border-border bg-card p-3 md:flex-row md:items-center">
          <div className="space-y-1 md:space-y-0">
            <h3 className="font-bricolage-medium text-base">
              Error generating AI content.
            </h3>
            <p className="text-sm text-muted-foreground">
              There was an error generating AI content for this recording.
              Please try again or contact support.
            </p>
          </div>
          <div className="w-full pr-0 md:w-fit md:pr-2">
            <Button
              size="sm"
              className="flex w-full flex-row items-center gap-2"
              onClick={onGenerateAIContent}
              isLoading={status === STATUS.LOADING}
            >
              <RefreshCw size={16} strokeWidth={2} />
              <span>Try again</span>
            </Button>
          </div>
        </div>
      ) : (
        <AIContentTable
          spaceSlug={spaceSlug}
          recording={recording}
          onGenerateAIContent={onGenerateAIContent}
          status={status}
          seekToTimestamp={seekToTimestamp}
        />
      )}
    </div>
  );
};

export default AIContent;
