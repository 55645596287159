import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, AuthStatus } from "@/views/Auth/AuthProvider";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.status === AuthStatus.IDLE) return;

    if (authState.status === AuthStatus.UNAUTHENTICATED) {
      const nextPath = location.pathname;
      navigate(`/login?next=${nextPath}`, { replace: true });
    }

    if (
      authState.status === AuthStatus.AUTHENTICATED &&
      !authState.currentUser?.is_onboarded
    ) {
      navigate("/onboarding", { replace: true });
    }
  }, [authState, location.pathname]);

  if (
    authState.status === AuthStatus.AUTHENTICATED &&
    authState.currentUser?.is_onboarded
  ) {
    return <>{children}</>;
  }

  return null;
};

export default ProtectedRoute;
