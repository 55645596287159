import useSwr from "swr";
import { fetcher } from "@/api/index";

import { spacesUrl } from "@/api/hooks/useSpaces";

export const useRecordings = (spaceSlug: string) => {
  const { data, error, isLoading } = useSwr<RecordingPreview[], Error>(
    `${spacesUrl}/${spaceSlug}/recordings`,
    fetcher,
  );

  return {
    recordings: data,
    recordingsError: error,
    recordingsLoading: isLoading,
  };
};

export const useRecording = (spaceSlug: string, recordingId: string) => {
  const { data, error, isLoading } = useSwr<Recording, Error>(
    `${spacesUrl}/${spaceSlug}/recordings/${recordingId}`,
    fetcher,
  );

  return {
    recording: data,
    recordingError: error,
    recordingLoading: isLoading,
  };
};
