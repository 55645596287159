import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import PaymentDetailsCard from "@/views/Account/components/Card/PaymentDetailsCard";

type UpdatePaymentDialogProps = {
  subscription: Subscription;
};

const UpdatePaymentDialog = ({ subscription }: UpdatePaymentDialogProps) => {
  const [updatePaymentDialogOpen, setUpdatePaymentDialogOpen] = useState(false);

  return (
    <Dialog
      open={updatePaymentDialogOpen}
      onOpenChange={setUpdatePaymentDialogOpen}
    >
      <DialogTrigger asChild>
        <button className="w-fit text-sm underline transition-colors hover:text-primary">
          Manage subscription
        </button>
      </DialogTrigger>
      <DialogContent className="!rounded-sm">
        <DialogHeader>
          <DialogTitle>Manage Subscription</DialogTitle>
          <DialogDescription>
            Manage your subscription plan and billing details.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col space-y-4">
          <PaymentDetailsCard
            setDialogOpen={setUpdatePaymentDialogOpen}
            payment_method_details={subscription.payment_method_details}
          />
          <Separator />
          <div className="text-sm">
            If you want to cancel your subscription, please contact{" "}
            <a
              href="mailto:support@reinspire.io?subject=[Subscription%20Cancellation]"
              className="text-primary underline"
            >
              support@reinspire.io
            </a>{" "}
            and we will assist you.
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePaymentDialog;
