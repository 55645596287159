import { useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { Progress } from "@/components/ui/progress";
import { AudioLines } from "lucide-react";
import { formatDate } from "@/lib/date";

import { useUsage } from "@/api/hooks/useUsage";

type UserUsageProps = {
  subscription: Subscription;
};

const UserUsage = ({ subscription }: UserUsageProps) => {
  const { usage, usageLoading, usageError } = useUsage();

  const [usagePercentage, setUsagePercentage] = useState<number>(0);

  useEffect(() => {
    if (!usage) return;
    const percentage = (usage.ai_minutes_usage / usage.ai_minutes_limit) * 100;

    setUsagePercentage(percentage);
  }, [usage]);

  return (
    <>
      <Separator className="col-span-2" />
      <div className="space-y-2 md:w-1/2">
        <div className="font-rubik-regular text-sm">
          <AudioLines
            size={18}
            strokeWidth={2.5}
            className="mr-2 inline-block text-primary"
          />
          Minutes Usage
        </div>
        <Progress value={usagePercentage} className="h-2" />
        <div className="flex flex-row justify-between font-bricolage-regular text-sm text-muted-foreground">
          {usageLoading || usageError ? (
            <>
              <Skeleton className="h-3 w-16 rounded-full" />
              <Skeleton className="h-3 w-16 rounded-full" />
            </>
          ) : (
            <>
              <span>
                {usage!.ai_minutes_usage} ({usagePercentage.toFixed(1)}%)
                minutes used
              </span>
              <span>{usage!.ai_minutes_limit}</span>
            </>
          )}
        </div>
        {usage && subscription!.next_billed_at ? (
          <div className="!mt-3">
            <small>
              Resets at {formatDate(usage!.reset_at, "HH:mm, MMM DD, YYYY")}
            </small>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UserUsage;
