import useSwr from "swr";
import { fetcher } from "@/api/index";

export const spacesUrl = "/spaces";

export const useSpacePreviews = () => {
  const { data, error, isLoading } = useSwr<SpacePreview[], Error>(
    spacesUrl,
    fetcher,
  );

  return {
    spaces: data,
    spacesError: error,
    spacesLoading: isLoading,
  };
};

export const useSpace = (spaceSlug: string) => {
  const { data, error, isLoading } = useSwr<Space, Error>(
    `${spacesUrl}/${spaceSlug}`,
    fetcher,
  );

  return {
    space: data,
    spaceError: error,
    spaceLoading: isLoading,
  };
};
