import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import { DialogFooter, DialogClose } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandList,
  CommandInput,
  CommandEmpty,
  CommandGroup,
  CommandSeparator,
  CommandItem,
} from "@/components/ui/command";
import { ChevronDown, Check, Loader2 } from "lucide-react";
import { supportedLanguages } from "@/lib/constants";
import { cn } from "@/lib/utils";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { useMedia } from "@/api/hooks/useMedia";
import { createRecording } from "@/api/services/recording";

const formSchema = z.object({
  media_id: z.string().min(1, { message: "Media is required" }),
  num_speakers: z.number().min(1).max(50).optional(),
  language: z.string(),
  vocabulary: z
    .string()
    .max(120, { message: "Vocabulary must be less than 120 characters" })
    .optional(),
});

type FormSchema = z.infer<typeof formSchema>;

type NewRecordingFormProps = {
  setDialogOpen: (open: boolean) => void;
};

const NewRecordingForm = ({ setDialogOpen }: NewRecordingFormProps) => {
  const { spaceSlug } = useParams();
  const [status, setStatus] = useState(STATUS.IDLE);
  const [languageComboboxOpen, setLanguageComboboxOpen] =
    useState<boolean>(false);
  const [mediaComboboxOpen, setMediaComboboxOpen] = useState<boolean>(false);
  const [filteredMedia, setFilteredMedia] = useState<Media[]>([]);

  const { toast } = useToast();
  const { media, mediaLoading, mediaError } = useMedia();

  useEffect(() => {
    if (!media) return;

    // Filter out not fully processed media and media that is already assigned to current space
    const filteredMedia = media.filter((media) => {
      const mediaInCurrentSpace = media.recordings.find(
        (recording) => recording.space.slug === spaceSlug,
      );

      if (mediaInCurrentSpace || media.status !== "completed") return;
      return media;
    });
    setFilteredMedia(filteredMedia);
  }, [media, spaceSlug]);

  const form = useForm<FormSchema>({
    shouldUnregister: true,
    resolver: zodResolver(formSchema),
    defaultValues: {
      media_id: "",
      num_speakers: undefined,
      language: "auto",
      vocabulary: undefined,
    },
  });

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      const mediaId = data.media_id;

      // Remove space_slug from data
      const modifiedData = {
        num_speakers: data.num_speakers,
        language: data.language,
        vocabulary: data.vocabulary,
      };

      // Create recording
      const { transcript_status } = await createRecording(
        spaceSlug!,
        mediaId,
        modifiedData,
      );

      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);

      if (transcript_status === "processing") {
        toast({
          title: "Recording created successfully",
          description:
            "Transcript is being processed. It usually takes a few minutes.",
          variant: "success",
        });
      } else {
        toast({
          title: "Transcription didn't start.",
          description: "Not enough minutes available to transcribe.",
          variant: "info",
        });
      }
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  return (
    <div className="space-y-4">
      {status === STATUS.ERROR && (
        <Alert type="error" message={form.formState.errors.root!.message!} />
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="media_id"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="mb-1 w-fit">Media</FormLabel>
                <Popover
                  open={mediaComboboxOpen}
                  onOpenChange={setMediaComboboxOpen}
                >
                  <PopoverTrigger asChild>
                    <FormControl className="overflow-hidden">
                      <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                          "line-clamp-1 flex flex-row justify-between whitespace-normal text-left capitalize active:scale-[1]",
                          !field.value && "text-muted-foreground",
                        )}
                        disabled={mediaLoading || mediaError ? true : false}
                      >
                        {(mediaLoading || mediaError) && (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        <span className="line-clamp-1 ">
                          {media?.find((media) => media.id === field.value)
                            ?.title || "Select media"}
                        </span>
                        <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-[--radix-popover-trigger-width] p-0 shadow-sm">
                    <Command>
                      <CommandInput
                        placeholder="Search media..."
                        className="h-9"
                      />

                      <CommandList>
                        <CommandEmpty>No media available.</CommandEmpty>
                        <CommandGroup>
                          {filteredMedia?.map((media) => (
                            <CommandItem
                              key={media.id}
                              value={media.id}
                              onSelect={() => {
                                form.setValue("media_id", media.id);
                                setMediaComboboxOpen(false);
                              }}
                              className="cursor-pointer"
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  media.id === field.value
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                              <span className="line-clamp-1">
                                {media.title}
                              </span>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="num_speakers"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Number of Speakers</FormLabel>
                <div className="flex items-center space-x-4">
                  <div>
                    <FormControl>
                      <Input
                        {...field}
                        type="text"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || value.toLowerCase() === "auto") {
                            field.onChange(undefined);
                          } else {
                            const numValue = parseInt(value, 10);
                            if (
                              !isNaN(numValue) &&
                              numValue >= 0 &&
                              numValue <= 50
                            ) {
                              field.onChange(numValue);
                            }
                          }
                        }}
                        value={field.value === undefined ? "Auto" : field.value}
                        min={1}
                        max={50}
                        onFocus={(e) => {
                          if (field.value === undefined) {
                            e.target.select();
                          }
                        }}
                        className="w-20"
                      />
                    </FormControl>
                  </div>
                  <Slider
                    min={0}
                    max={50}
                    step={1}
                    value={[field.value === undefined ? 0 : field.value]}
                    onValueChange={(value) =>
                      field.onChange(value[0] || undefined)
                    }
                    className="flex-grow"
                  />
                </div>
                {form.formState.errors.num_speakers ? (
                  <FormMessage />
                ) : (
                  <FormDescription>
                    The number of speakers in the recording. Default is auto
                    detect.{" "}
                    <span className="text-primary">
                      Recommended to set for higher quality results.
                    </span>
                  </FormDescription>
                )}
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="mb-1 w-fit">Language</FormLabel>
                <Popover
                  open={languageComboboxOpen}
                  onOpenChange={setLanguageComboboxOpen}
                >
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                          "justify-between capitalize active:scale-[1]",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value || "Select language"}
                        <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-[--radix-popover-trigger-width] p-0 shadow-sm">
                    <Command>
                      <CommandInput
                        placeholder="Search language..."
                        className="h-9"
                      />

                      <CommandList>
                        <CommandEmpty>No language found.</CommandEmpty>
                        <CommandGroup heading="Common">
                          {supportedLanguages.slice(0, 4).map((language) => (
                            <CommandItem
                              key={language}
                              value={language}
                              onSelect={() => {
                                form.setValue("language", language);
                                setLanguageComboboxOpen(false);
                              }}
                              className="cursor-pointer capitalize"
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  language === field.value
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                              {language}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                        <CommandSeparator />
                        <CommandGroup heading="Other">
                          {supportedLanguages.slice(4).map((language) => (
                            <CommandItem
                              key={language}
                              value={language}
                              onSelect={() => {
                                form.setValue("language", language);
                                setLanguageComboboxOpen(false);
                              }}
                              className="cursor-pointer capitalize"
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  language === field.value
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                              {language}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormDescription>
                  The language in the recording. Default is auto detect.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="vocabulary"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Vocabulary</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Vocabulary (optional)" />
                </FormControl>
                {form.formState.errors.num_speakers ? (
                  <FormMessage />
                ) : (
                  <FormDescription>
                    The vocabulary used in the recording. Use commas to separate
                    words. 120 characters max.
                  </FormDescription>
                )}
              </FormItem>
            )}
          />

          <DialogFooter className="gap-2">
            <DialogClose asChild>
              <Button variant="outline" className="min-w-20">
                Cancel
              </Button>
            </DialogClose>
            <Button
              type="submit"
              className="min-w-20"
              isLoading={status === STATUS.LOADING}
            >
              Save recording
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </div>
  );
};

export default NewRecordingForm;
