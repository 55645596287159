import { NavLink } from "react-router-dom";
import { cn } from "@/lib/utils";

type MenuTileButtonProps = {
  text: string;
  to: string;
  icon: React.ReactNode;
  onAfterClick?: () => void;
  className?: string;
};

const MenuTileButton = (props: MenuTileButtonProps) => {
  const { text, to, icon, onAfterClick, className } = props;

  return (
    <NavLink
      to={to}
      className={({ isActive }: { isActive: boolean }) =>
        cn(
          "flex h-[72px] flex-col items-center justify-center gap-2 rounded-sm border border-border px-1 hover:bg-secondary",
          isActive && "bg-secondary text-primary",
          className,
        )
      }
      onClick={onAfterClick}
    >
      {icon}
      <span className="overflow-hidden text-center text-xs text-muted-foreground">
        {text}
      </span>
    </NavLink>
  );
};

export default MenuTileButton;
