import { useEffect, useState } from "react";
import { extractYTVideoId } from "@/lib/utils";

const YouTubePreview = ({ url }: { url: string }) => {
  const [videoId, setVideoId] = useState<string | null>(null);

  useEffect(() => {
    const ytVideoId = extractYTVideoId(url);

    setVideoId(ytVideoId);
  }, [url]);

  if (!videoId) return null;

  return (
    <div className="mt-4">
      <iframe
        width="100%"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="aspect-video rounded-sm"
      ></iframe>
    </div>
  );
};

export default YouTubePreview;
