import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import YouTubePreview from "@/components/utils/ytvideo-embed";
import MediaDropzone from "@/views/Media/components/Dropzone/MediaDropzone";

import { STATUS } from "@/api/utils";
import { urlUpload } from "@/api/services/media";

const formSchema = z.object({
  url: z
    .string()
    .min(1, { message: "Please enter a valid YouTube URL." })
    .refine(
      (url) => {
        let parsedUrl: URL;
        const validDomains = ["youtube.com", "youtu.be"];

        try {
          parsedUrl = new URL(url);
        } catch (error) {
          return false;
        }

        // Check if the domain is valid
        const isDomainValid = validDomains.some((domain) =>
          parsedUrl.hostname.endsWith(domain),
        );

        // Check for video ID based on the domain
        const hasValidVideoId = parsedUrl.hostname.includes("youtube.com")
          ? /[?&]v=[a-zA-Z0-9_-]{11}/.test(url)
          : /youtu\.be\/[a-zA-Z0-9_-]{11}/.test(url);

        return isDomainValid && hasValidVideoId;
      },
      {
        message: "Please enter a valid YouTube URL.",
      },
    ),
});

type FormSchema = z.infer<typeof formSchema>;

type MediaUploadFormProps = {
  setDialogOpen: (open: boolean) => void;
};

const MediaUploadForm = ({ setDialogOpen }: MediaUploadFormProps) => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const { toast } = useToast();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: { url: "" },
  });

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await urlUpload(data.url);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
      toast({
        title: "Processing media",
        description: "Your media is being processed. This may take a while.",
        variant: "success",
      });
    } catch (error) {
      form.setError("url", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <div className="space-y-8">
      <MediaDropzone />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="url"
            render={({ field }) => (
              <FormItem>
                <FormLabel>YouTube video URL</FormLabel>
                <FormControl>
                  <div className="flex gap-4">
                    <Input placeholder="https://..." {...field} />
                    <Button type="submit" disabled={status === STATUS.LOADING}>
                      {status === STATUS.LOADING ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </FormControl>
                {form.formState.errors.url ? (
                  <FormMessage />
                ) : (
                  <FormDescription>
                    Enter a valid YouTube video URL.
                  </FormDescription>
                )}
              </FormItem>
            )}
          />
          {form.watch("url") && !form.formState.errors.url && (
            <YouTubePreview url={form.watch("url")} />
          )}
        </form>
      </Form>
    </div>
  );
};

export default MediaUploadForm;
