import useSwr from "swr";
import { fetcher } from "@/api/index";

import { spacesUrl } from "@/api/hooks/useSpaces";

export const useAIContent = (spaceSlug: string, recordingId: string) => {
  const { data, error, isLoading } = useSwr<RecordingContentBlock[], Error>(
    `${spacesUrl}/${spaceSlug}/recordings/${recordingId}/content`,
    fetcher,
  );

  return {
    content: data,
    contentError: error,
    contentLoading: isLoading,
  };
};
