type TimestampProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const timestampClassName =
  "text-sm bg-primary/20 rounded-[5px] py-[0.5px] px-1.5 text-primary cursor-pointer";

export const Timestamp = ({ children, onClick }: TimestampProps) => {
  if (onClick) {
    return (
      <button onClick={onClick} className={timestampClassName}>
        {children}
      </button>
    );
  } else {
    return <span className={timestampClassName}>{children}</span>;
  }
};
