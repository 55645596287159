import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/components/ui/use-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Ellipsis, Edit, Trash2 } from "lucide-react";

import Alert from "@/components/Alert/Alert";
import EditBrandVoiceForm from "@/views/BrandVoices/components/Form/EditVoiceForm";

import { STATUS } from "@/api/utils";
import { deleteBrandVoice } from "@/api/services/brandVoice";
import { formatDate } from "@/lib/date";

type BrandVoiceCardProps = {
  brandVoice: BrandVoice;
};

const BrandVoiceCard = ({ brandVoice }: BrandVoiceCardProps) => {
  const { toast } = useToast();
  const [status, setStatus] = useState(STATUS.IDLE);
  const [error, setError] = useState<string | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const onBrandVoiceDelete = async () => {
    setStatus(STATUS.LOADING);

    try {
      await deleteBrandVoice(brandVoice.id);
      setStatus(STATUS.SUCCESS);
      setIsDeleteDialogOpen(false);
      toast({
        title: "Brand Voice deleted successfully",
        variant: "success",
      });
    } catch (error) {
      setStatus(STATUS.ERROR);
      setError((error as Error).message);
    }
  };

  return (
    <div className="flex h-40 flex-col rounded-sm border border-border bg-card p-4">
      <div className="flex-grow space-y-2">
        <div className="flex flex-row justify-between gap-2">
          <h6 className="line-clamp-1 flex-grow font-bricolage-medium text-lg">
            {brandVoice.emoji} {brandVoice.name}
          </h6>
          <div>
            <AlertDialog
              open={isDeleteDialogOpen}
              onOpenChange={setIsDeleteDialogOpen}
            >
              <Dialog
                open={isEditDialogOpen}
                onOpenChange={setIsEditDialogOpen}
              >
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      size="icon"
                      variant="outline"
                      className="size-7 flex-shrink-0 rounded-sm border-none p-0.5"
                    >
                      <Ellipsis
                        size={18}
                        strokeWidth={2.5}
                        className="text-muted-foreground"
                      />
                    </Button>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent side="bottom" align="end">
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => setIsEditDialogOpen(true)}
                    >
                      <Edit size={16} strokeWidth={2.5} className="mr-2" />
                      Edit
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer !text-destructive hover:!bg-destructive/10"
                      onClick={() => setIsDeleteDialogOpen(true)}
                    >
                      <Trash2 size={16} strokeWidth={2.5} className="mr-2" />
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>

                  {/* Edit Dialog */}
                  <DialogContent className="max-w-screen-sm">
                    <DialogHeader>
                      <DialogTitle>Edit Brand Voice</DialogTitle>
                      <DialogDescription>
                        Edit the details of your brand voice.
                      </DialogDescription>
                    </DialogHeader>
                    <div>
                      <EditBrandVoiceForm
                        setDialogOpen={setIsEditDialogOpen}
                        brandVoice={brandVoice}
                      />
                    </div>
                  </DialogContent>

                  {/* Delete Alert Dialog */}
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Delete Brand Voice</AlertDialogTitle>
                    </AlertDialogHeader>
                    <AlertDialogDescription>
                      {status === STATUS.ERROR && (
                        <Alert type="error" message={error!} className="mb-4" />
                      )}
                      <span>
                        Are you sure you want to delete this brand voice?
                      </span>
                    </AlertDialogDescription>
                    <AlertDialogFooter>
                      <AlertDialogCancel className="min-w-20">
                        Cancel
                      </AlertDialogCancel>
                      <Button
                        variant="destructive"
                        onClick={onBrandVoiceDelete}
                        isLoading={status === STATUS.LOADING}
                        className="min-w-20"
                      >
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </DropdownMenu>
              </Dialog>
            </AlertDialog>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-1">
          {brandVoice.tone.slice(0, 4).map((tone) => (
            <Badge key={tone} variant="secondary">
              {tone}
            </Badge>
          ))}
        </div>
      </div>
      <div className="mt-3 flex-shrink-0 font-rubik-regular text-xs text-muted-foreground">
        {brandVoice.spaces_count && brandVoice.spaces_count > 0 ? (
          <span>Used in {brandVoice.spaces_count} spaces • </span>
        ) : null}
        <span>
          Created on {formatDate(brandVoice.created_at, "MMM DD, YYYY")}
        </span>
      </div>
    </div>
  );
};

export default BrandVoiceCard;
