import useSwr from "swr";
import { fetcher } from "@/api/index";

import { spacesUrl } from "@/api/hooks/useSpaces";

export const useSpeakers = (spaceSlug: string) => {
  const { data, error, isLoading } = useSwr<SpaceSpeaker[], Error>(
    `${spacesUrl}/${spaceSlug}/speakers`,
    fetcher,
  );

  return {
    speakers: data,
    speakersLoading: isLoading,
    speakersError: error,
  };
};
