import { useState } from "react";
import { useParams } from "react-router";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { Trash2 } from "lucide-react";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { deleteSpacePrompt } from "@/api/services/space";

type DeletePromptDialogProps = {
  prompt: SpacePrompt;
};

const DeletePromptDialog = ({ prompt }: DeletePromptDialogProps) => {
  const { spaceSlug } = useParams();
  const { toast } = useToast();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [error, setError] = useState<string>("");

  const handleDelete = async () => {
    setStatus(STATUS.LOADING);

    try {
      await deleteSpacePrompt(spaceSlug!, prompt.id);
      setDialogOpen(false);
      setStatus(STATUS.SUCCESS);
      toast({
        title: "Prompt deleted successfully",
        variant: "success",
      });
    } catch (err) {
      const error = err as Error;
      setError(error.message);
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          className="size-8 rounded-sm transition-colors hover:border-destructive hover:bg-destructive/10"
        >
          <Trash2 size={16} strokeWidth={2.5} className="text-destructive" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          {status === STATUS.ERROR && <Alert type="error" message={error!} />}
          <AlertDialogDescription>
            Are you sure you want to delete "{prompt.name}" prompt? This action
            cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="min-w-20">Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={handleDelete}
            isLoading={status === STATUS.LOADING}
            className="min-w-20"
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeletePromptDialog;
