import { Outlet } from "react-router";

const MainLayout = () => {
  return (
    <div className="lg:ml-64 w-[100vw] px-4 md:px-8 lg:px-16 py-8">
      <Outlet />
    </div>
  );
};

export default MainLayout;
