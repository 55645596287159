import { createBrowserRouter, Navigate } from "react-router-dom";

// Core
import CoreLayout from "@/views/Core/Layout";
import MainLayout from "@/views/Core/MainLayout";
import ProtectedRoute from "@/views/Core/ProtectedRoute";

// Error
import NotFound from "@/views/Error/NotFound";

// Auth
import AuthLayout from "@/views/Auth/Layout";
import Login from "@/views/Auth/Login";
import Register from "@/views/Auth/Register";
import Oauth from "@/views/Auth/Oauth";
import ForgotPassword from "@/views/Auth/ForgotPassword";
import ResetPassword from "@/views/Auth/ResetPassword";
import VerifyEmail from "@/views/Auth/VerifyEmail";

// Account
import AccountLayout from "@/views/Account/Layout";
import Settings from "@/views/Account/Settings";
import Integrations from "@/views/Account/Integrations";
import Billing from "@/views/Account/Billing";
import CheckoutSuccess from "@/views/Account/CheckoutSuccess";
import Onboarding from "@/views/Onboarding/Index";

// Spaces
import Spaces from "@/views/Spaces/Index";
import SpaceLayout from "@/views/Spaces/SpaceLayout";
import SpaceSettings from "@/views/Spaces/Settings";
import SpacePrompts from "@/views/Spaces/Prompts";

// Recordings
import SpaceRecordings from "@/views/Recordings/Recordings";

import RecordingLayout from "@/views/Recordings/RecordingLayout";
import Transcript from "@/views/Recordings/Transcript";
import AIContent from "@/views/Recordings/AIContent";

// Media Library
import Media from "@/views/Media/Index";

// Brand Voice
import BrandVoices from "@/views/BrandVoices/Index";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: "/register",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <Register /> }],
  },
  {
    path: "/oauth-callback",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <Oauth /> }],
  },
  {
    path: "/verify-email",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <VerifyEmail /> }],
  },
  {
    path: "/forgot-password",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <ForgotPassword /> }],
  },
  {
    path: "/reset-password",
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <ResetPassword /> }],
  },

  { path: "/onboarding", element: <Onboarding /> },

  {
    path: "/",
    element: (
      <ProtectedRoute>
        <CoreLayout>
          <MainLayout />
        </CoreLayout>
      </ProtectedRoute>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Navigate to="/spaces" />,
      },
      {
        path: "/spaces",
        element: <Spaces />,
      },
      { path: "/media", element: <Media /> },
      { path: "/brand-voice", element: <BrandVoices /> },
      {
        path: "/account",
        element: <AccountLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/account/settings" />,
          },
          {
            path: "/account/settings",
            element: <Settings />,
          },
          {
            path: "/account/integrations",
            element: <Integrations />,
          },
          {
            path: "/account/billing",
            element: <Billing />,
          },
        ],
      },
      {
        path: "/checkout",
        element: <CheckoutSuccess />,
      },
    ],
  },

  {
    path: "/spaces/:spaceSlug",
    element: (
      <ProtectedRoute>
        <CoreLayout>
          <SpaceLayout />
        </CoreLayout>
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <Navigate to="recordings" /> },
      { path: "/spaces/:spaceSlug/recordings", element: <SpaceRecordings /> },
      { path: "/spaces/:spaceSlug/settings", element: <SpaceSettings /> },
      { path: "/spaces/:spaceSlug/prompts", element: <SpacePrompts /> },
      {
        path: "/spaces/:spaceSlug/recordings/:recordingId",
        element: <RecordingLayout />,
        children: [
          { index: true, element: <Navigate to="transcript" /> },
          {
            path: "/spaces/:spaceSlug/recordings/:recordingId/transcript",
            element: <Transcript />,
          },
          {
            path: "/spaces/:spaceSlug/recordings/:recordingId/content",
            element: <AIContent />,
          },
        ],
      },
    ],
  },
]);

export default router;
