import { useState } from "react";
import { Row } from "@tanstack/react-table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { cn } from "@/lib/utils";

import AssignRecordingForm from "@/views/Media/components/Form/AssignRecordingForm";

type AssignRecordingDialogProps = {
  row: Row<Media>;
  mediaRecordings: MediaRecording[];
};

const AssignRecordingDialog = ({
  row,
  mediaRecordings,
}: AssignRecordingDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              disabled={row.original.status !== "completed"}
              onClick={() => setDialogOpen(true)}
              className={cn(
                "flex h-8 flex-row items-center gap-1 px-2",
                mediaRecordings.length !== 0 && "w-8 p-0",
              )}
            >
              <Plus size={15} strokeWidth={2.5} className="text-primary" />
              {mediaRecordings.length === 0 && "New recording"}
            </Button>
          </TooltipTrigger>
          <TooltipContent
            side="bottom"
            className={cn("text-sm", mediaRecordings.length === 0 && "hidden")}
          >
            New recording
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DialogContent>
        <DialogHeader>
          <DialogClose />
          <DialogTitle>New Recording</DialogTitle>
          <DialogDescription>
            Tell us about the new recording you want to add.
          </DialogDescription>
        </DialogHeader>
        <AssignRecordingForm
          row={row}
          setDialogOpen={setDialogOpen}
          mediaRecordings={mediaRecordings}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AssignRecordingDialog;
