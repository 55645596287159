import { useState } from "react";
import { useParams } from "react-router";
import * as z from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Edit2 } from "lucide-react";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { editSpacePrompt } from "@/api/services/space";

const formSchema = z.object({
  name: z
    .string()
    .min(1, "Name is required")
    .max(32, "Name should not exceed 32 characters"),
  prompt: z
    .string()
    .min(1, "Prompt is required")
    .max(1000, "Prompt should not exceed 1000 characters"),
});

type formSchema = z.infer<typeof formSchema>;

type EditPromptDialogProps = {
  prompt: SpacePrompt;
};

const EditPromptDialog = ({ prompt }: EditPromptDialogProps) => {
  const { spaceSlug } = useParams();
  const { toast } = useToast();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(STATUS.IDLE);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: prompt.name,
      prompt: prompt.prompt,
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await editSpacePrompt(spaceSlug!, prompt.id, data);
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);
      toast({
        title: "Prompt updated successfully",
        variant: "success",
      });
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          className="size-8 rounded-sm transition-colors hover:border-primary hover:bg-primary/10 hover:text-primary"
        >
          <Edit2 size={16} strokeWidth={2.5} />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle>Edit Prompt</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {status === STATUS.ERROR && (
            <Alert
              type="error"
              message={form.formState.errors.root!.message!}
            />
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="name"
                defaultValue={prompt.name}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter prompt name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="prompt"
                defaultValue={prompt.prompt}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Prompt</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Enter prompt"
                        className="max-h-96 min-h-56"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter className="gap-2">
                <DialogClose asChild>
                  <Button variant="outline" className="min-w-20">
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  isLoading={status === STATUS.LOADING}
                  className="min-w-20"
                >
                  Save
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditPromptDialog;
