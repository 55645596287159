import { router } from "@/app/router";

export function isValidRoute(path: string): boolean {
  const allRoutes = extractRoutes(router.routes);
  return allRoutes.some((route) => matchRoute(route, path));
}

function extractRoutes(routes: any[]): string[] {
  return routes.reduce((acc: string[], route: any) => {
    if (route.path) {
      acc.push(route.path);
    }
    if (route.children) {
      acc.push(...extractRoutes(route.children));
    }
    return acc;
  }, []);
}

function matchRoute(routePattern: string, path: string): boolean {
  const routeParts = routePattern.split("/");
  const pathParts = path.split("/");

  if (routeParts.length !== pathParts.length) {
    return false;
  }

  return routeParts.every((routePart, index) => {
    if (routePart.startsWith(":")) {
      // This is a dynamic segment, so it matches any value
      return true;
    }
    return routePart === pathParts[index];
  });
}
