import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import { AxiosError, AxiosResponse } from "axios";
import { mutate } from "swr";
import { userSubscriptionUrl } from "@/api/hooks/useSubscription";

type PaymentUpdateTransactionResponse = {
  transaction_id: string;
};

export const getPaymentUpdateTransaction =
  async (): Promise<PaymentUpdateTransactionResponse> => {
    try {
      const response: AxiosResponse<PaymentUpdateTransactionResponse> =
        await apiInstance.get("/users/me/subscription/update-payment-method");
      return response.data;
    } catch (err) {
      const error = err as AxiosError;
      const response = error.response as AxiosResponse;

      if (!response && error.request) {
        throw new Error("Network error");
      } else {
        throw new Error("Something went wrong. Try again.");
      }
    }
  };

export const changeSubscriptionPlan = async (
  priceId: string,
): Promise<AxiosResponse> => {
  const payload = [
    {
      paddle_price_id: priceId,
      quantity: 1,
    },
  ];
  try {
    const response = await apiInstance.patch("/users/me/subscription", payload);
    mutate(userSubscriptionUrl, response.data, { revalidate: false });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};
