import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import { AxiosError, AxiosResponse } from "axios";
import { mutate, MutatorCallback } from "swr";
import { brandVoicesUrl } from "@/api/hooks/useBrandVoices";

type BrandVoicePayload = {
  emoji: string;
  name: string;
  purpose: string;
  audience: string;
  tone: string[];
  emotion: string[];
  character: string[];
  language: string[];
};

export const createBrandVoice = async (
  payload: BrandVoicePayload,
): Promise<BrandVoice> => {
  try {
    const response = await apiInstance.post("/brand-voices", payload);
    const newBrandVoice = {
      ...response.data,
      spaces_count: 0,
    };
    mutate(brandVoicesUrl, (data) => [...data, newBrandVoice], {
      revalidate: false,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const editBrandVoice = async (
  id: number,
  payload: BrandVoicePayload,
): Promise<BrandVoice> => {
  try {
    const response = await apiInstance.put(`/brand-voices/${id}`, payload);
    const mutationRule: MutatorCallback = (data: BrandVoice[]): BrandVoice[] =>
      data.map((item: BrandVoice) =>
        item.id === id
          ? { ...response.data, spaces_count: item.spaces_count }
          : item,
      );
    mutate(brandVoicesUrl, mutationRule, {
      revalidate: false,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const deleteBrandVoice = async (id: number): Promise<void> => {
  try {
    await apiInstance.delete(`/brand-voices/${id}`);
    mutate(
      brandVoicesUrl,
      (data) => data.filter((item: BrandVoice) => item.id !== id),
      { revalidate: false },
    );
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

// Analyze brand voice
export type AnalyzedBrandVoiceResponse = {
  emoji: string;
  name: string;
  purpose: string;
  audience: string;
  tone: string[];
  emotion: string[];
  character: string[];
  language: string[];
};

type AnalyzeBrandVoicePayload = {
  website_url?: string;
  text_content?: string;
};

export const analyzeBrandVoice = async (
  payload: AnalyzeBrandVoicePayload,
  controller?: AbortController,
): Promise<AnalyzedBrandVoiceResponse> => {
  try {
    const response = await apiInstance.post("/brand-voices/analyze", payload, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};
