import { useState } from "react";
import { useDropzone, FileRejection, ErrorCode } from "react-dropzone";
import { Upload } from "lucide-react";

import Alert from "@/components/Alert/Alert";
import MediaDropzoneItem from "@/views/Media/components/Dropzone/MediaDropzoneItem";

import { uploadFilesConcurrently, CustomFile } from "@/lib/file";

const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB
const ACCEPTED_FORMATS = {
  audio: [".m4a", ".mp3", ".wav"],
  video: [".mp4", ".webm", ".mpeg", ".mpg", ".mov", ".acc"],
};

export const MediaDropzone = () => {
  const [files, setFiles] = useState<CustomFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onDrop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => {
    setErrorMessage(null);

    // If there are rejected files, handle them
    if (rejectedFiles.length > 0) {
      handleRejectedFiles(rejectedFiles);
      return;
    }

    // Check if the total number of files exceeds the limit
    if (files.length + acceptedFiles.length > 5) {
      setErrorMessage("You can only upload a maximum of 5 files.");
      return;
    }

    // Check for duplicate files
    const duplicateFiles = acceptedFiles.filter((file) =>
      files.some((f) => f.name === file.name && f.progress >= 0),
    );
    if (duplicateFiles.length > 0) {
      setErrorMessage("Some files are already uploaded.");
    }

    // Filter out duplicate files and add progress property.
    // If file already uploaded but failed, replace it with the new file.
    const newFiles = acceptedFiles
      .filter((file) => {
        const existingFile = files.find((f) => f.name === file.name);
        return !existingFile || existingFile.progress < 0;
      })
      .map((file) =>
        Object.assign(file, {
          progress: 0,
        }),
      );

    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter(
        (f) =>
          f.progress >= 0 &&
          !newFiles.some((newFile) => newFile.name === f.name),
      );
      return [...updatedFiles, ...newFiles];
    });

    await uploadFilesConcurrently(newFiles, updateFile);
  };

  const handleRejectedFiles = (rejectedFiles: FileRejection[]) => {
    const errors = rejectedFiles.map((rejectedFile: FileRejection) => {
      if (rejectedFile.errors[0].code === ErrorCode.FileInvalidType) {
        return `${rejectedFile.file.name} is not a valid file type.`;
      } else if (rejectedFile.errors[0].code === ErrorCode.FileTooLarge) {
        return `${rejectedFile.file.name} exceeds the maximum size limit of 10GB.`;
      } else {
        return "An error occurred while uploading the file(s).";
      }
    });
    setErrorMessage(errors[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "audio/*": ACCEPTED_FORMATS.audio,
      "video/*": ACCEPTED_FORMATS.video,
    },
    maxFiles: 5,
    maxSize: MAX_FILE_SIZE,
    onDrop,
  });

  const updateFile = (
    name: string,
    updates: { progress?: number; error?: string },
  ) => {
    setFiles((prevFiles) =>
      prevFiles.map((f) => {
        if (f.name === name) {
          f.progress = updates.progress || f.progress;
          f.error = updates.error || f.error;
        }
        return f;
      }),
    );
  };

  return (
    <div className="space-y-4">
      {errorMessage && <Alert type="error" message={errorMessage} />}

      <div className="space-y-2">
        <label className="text-sm font-medium">Upload Media Files</label>
        <div
          {...getRootProps()}
          className={`cursor-pointer rounded-lg border-2 border-dashed border-gray-300 p-2 text-center transition-colors sm:p-6
            ${
              isDragActive
                ? "border-primary bg-primary/10"
                : "hover:border-primary hover:bg-primary/5"
            }`}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center space-y-2">
            <Upload
              size={36}
              strokeWidth={2.5}
              className="mx-auto text-primary"
            />
            <p className="text-sm sm:text-base">
              Drag & drop up to 5 audio or video files here, or click to select
              files
            </p>
            <p className="w-3/4 text-xs text-muted-foreground sm:text-sm">
              File up to 10 GB, maximum duration of 10 hours (mp4, mp3, m4a,
              mpeg, webm, wav)
            </p>
          </div>
        </div>
        <p className="text-xs">
          Videos of more than 10 mins duration work the best.
        </p>
      </div>

      {files.length > 0 && (
        <div className="space-y-2">
          <label className="text-sm font-medium">Uploaded Files</label>
          <ul className="space-y-2">
            {files.map((file, index) => (
              <MediaDropzoneItem key={index} file={file} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MediaDropzone;
