import React from "react";
import { NavLink } from "react-router-dom";
import { cn } from "@/lib/utils";

type MenuButtonProps = {
  text: string;
  to: string;
  isCollapsed?: boolean;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onAfterClick?: () => void;
};

const MenuButton = (props: MenuButtonProps) => {
  const {
    text,
    to,
    isCollapsed,
    startContent,
    endContent,
    isDisabled,
    className,
    onAfterClick,
  } = props;

  const commonClassNames = cn(
    "flex h-9 w-full flex-row items-center justify-between rounded-sm px-3 transition-all hover:bg-secondary",
    isCollapsed && "size-9 px-[9px]",
    isDisabled && "cursor-not-allowed opacity-50 hover:bg-transparent",
    className,
  );

  const content = (
    <>
      <span className="flex flex-row items-center gap-2.5">
        {startContent}{" "}
        {!isCollapsed && (
          <span className="overflow-hidden text-nowrap">{text}</span>
        )}
      </span>
      {!isCollapsed && (
        <span className="flex items-center justify-center">{endContent}</span>
      )}
    </>
  );

  if (isDisabled) {
    return <div className={commonClassNames}>{content}</div>;
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }: { isActive: boolean }) =>
        cn(commonClassNames, isActive && "bg-secondary")
      }
      onClick={onAfterClick}
    >
      {content}
    </NavLink>
  );
};

export default MenuButton;
