import { useState } from "react";

import SpacesTable from "@/views/Spaces/components/Table/SpacesTable";
import NewSpaceDialog from "@/views/Spaces/components/Dialog/NewSpaceDialog";
// import { Link } from "react-router-dom";

const Spaces = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <div className="space-y-3">
      <div className="flex flex-row items-center justify-between">
        <h1 className="font-bricolage-semibold text-2xl md:text-3xl">Spaces</h1>
        <NewSpaceDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
      </div>
      {/* <div className="!mt-4 flex flex-row gap-8 rounded-sm border border-border bg-card p-4">
        <div className="h-32 w-64 flex-shrink-0 rounded-sm bg-secondary"></div>
        <div className="flex-grow">
          <h2 className="font-bricolage-semibold text-lg">
            Welcome to Reinspire 👋
          </h2>
          <p className="text-muted-foreground">
            Watch an overview video or follow steps below to get started.
          </p>
          <ul className="mt-2 list-inside list-decimal text-sm text-muted-foreground">
            <li>
              Upload your media in{" "}
              <Link
                to="/media"
                state={{ newSpace: true }}
                className="text-primary underline"
              >
                Media Library
              </Link>
            </li>
            <li>Create a new space using</li>
            <li>Click "New Recording" to transcribe media</li>
          </ul>
        </div>
      </div> */}
      <div className="py-4">
        <SpacesTable setDialogOpen={setDialogOpen} />
      </div>
    </div>
  );
};

export default Spaces;
