import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus } from "lucide-react";

import NewRecordingForm from "@/views/Spaces/components/Form/NewRecordingForm";

const NewRecordingDialog = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          New Recording
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Recording</DialogTitle>
          <DialogDescription>
            Select media file to create a new recording from.
          </DialogDescription>
        </DialogHeader>
        <NewRecordingForm setDialogOpen={setDialogOpen} />
      </DialogContent>
    </Dialog>
  );
};

export default NewRecordingDialog;
