import useSwr from "swr";
import { fetcher } from "@/api/index";

export const mediaUrl = "/media";

export const useMedia = () => {
  const { data, error, isLoading } = useSwr<Media[], Error>(mediaUrl, fetcher);

  return {
    media: data,
    mediaError: error,
    mediaLoading: isLoading,
  };
};
