import useSwr from "swr";
import { fetcher } from "@/api/index";

export const presetsUrl = "/presets";

export const usePresets = () => {
  const { data, error, isLoading } = useSwr<Preset[], Error>(
    presetsUrl,
    fetcher,
  );

  return {
    presets: data,
    presetsError: error,
    presetsLoading: isLoading,
  };
};
