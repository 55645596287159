import { CircleX } from "lucide-react";
import { cn } from "@/lib/utils";

type FetchErrorProps = {
  className?: string;
};

const FetchError = ({ className }: FetchErrorProps) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center py-8",
        className,
      )}
    >
      <CircleX size={32} strokeWidth={2.5} className="text-destructive" />
      <h6 className="text-center font-bricolage-semibold text-base">
        Something went wrong
      </h6>
      <p className="font-bricolage-regular text-sm">
        Failed to load data. Please try again.
      </p>
    </div>
  );
};

export default FetchError;
