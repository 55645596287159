import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { MoveRight } from "lucide-react";

import { leadSources, leadRoles } from "@/lib/constants";

import { STATUS } from "@/api/utils";
import { AuthStatus, useAuth } from "@/views/Auth/AuthProvider";
import { onboardUser } from "@/api/services/onboarding";

const formSchema = z.object({
  full_name: z
    .string()
    .min(3, {
      message: "Full name must be at least 3 characters",
    })
    .max(120, {
      message: "Full name must be at most 120 characters",
    })
    .optional(),
  lead_source: z
    .string()
    .max(50, { message: "This field must be at most 50 characters" }),
  lead_role: z
    .string()
    .max(50, { message: "This field must be at most 50 characters" }),
  usage_description: z.string().max(256, {
    message: "This field must be at most 256 characters",
  }),
});

type formSchema = z.infer<typeof formSchema>;

type OnboardingFormProps = {
  user: User;
};

const OnboardingForm = ({ user }: OnboardingFormProps) => {
  const navigate = useNavigate();
  const { setAuthState } = useAuth();
  const [status, setStatus] = useState(STATUS.IDLE);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    shouldUnregister: true,
    defaultValues: {
      full_name: user.full_name,
      lead_source: "",
      usage_description: "",
    },
  });

  const onSubmit = async (data: formSchema) => {
    setStatus(STATUS.LOADING);

    try {
      const user = await onboardUser(data);
      setAuthState({
        status: AuthStatus.AUTHENTICATED,
        currentUser: user,
      });
      setStatus(STATUS.SUCCESS);
      navigate("/spaces", { replace: true });
    } catch (error) {
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {!user.full_name && (
          <FormField
            control={form.control}
            name="full_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Full name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter your full name" {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Will be used to personalize your experience on the platform
                  and for communication purposes.
                </FormDescription>
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="lead_source"
          render={({ field }) => (
            <FormItem>
              <FormLabel>How did you hear about us?</FormLabel>
              <Select
                onValueChange={(value) => {
                  if (value === "other") {
                    setShowOtherInput(true);
                    field.onChange("");
                  } else {
                    setShowOtherInput(false);
                    field.onChange(value);
                  }
                }}
                value={showOtherInput ? "other" : field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.entries(leadSources).map(([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {showOtherInput && (
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Please specify"
                    className="mt-4"
                  />
                </FormControl>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lead_role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>What's your role?</FormLabel>
              <Select
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.entries(leadRoles).map(([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="usage_description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Tell us what you hope to achieve with Reinspire
              </FormLabel>
              <FormControl>
                <Textarea
                  placeholder="One sentence is fine"
                  className="max-h-32 min-h-16"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="!mt-6 w-full"
          isLoading={status === STATUS.LOADING}
        >
          Get Started <MoveRight size={16} strokeWidth={2.5} className="ml-2" />
        </Button>
      </form>
    </Form>
  );
};

export default OnboardingForm;
