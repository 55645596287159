import { useParams } from "react-router";
import { Skeleton } from "@/components/ui/skeleton";

import NewPromptDialog from "@/views/Spaces/components/Dialog/NewPromptDialog";
import DeletePromptDialog from "@/views/Spaces/components/Dialog/DeletePromptDialog";
import EditPromptDialog from "@/views/Spaces/components/Dialog/EditPromptDialog";

import { usePrompts } from "@/api/hooks/usePrompts";

const SpacePrompts = () => {
  const { spaceSlug } = useParams();

  const { prompts, promptsError, promptsLoading } = usePrompts(spaceSlug!);

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <div className="space-y-0.5">
          <h1 className="font-bricolage-semibold text-2xl md:text-3xl">
            Prompts
          </h1>
          <p className="hidden text-sm text-muted-foreground sm:block">
            Customize your space content prompts.
          </p>
        </div>
        <NewPromptDialog />
      </div>

      <div className="flex flex-col gap-4 py-4">
        {promptsLoading || promptsError ? (
          [...Array(5)].map((_, index) => (
            <Skeleton key={index} className="h-24 w-full rounded-sm" />
          ))
        ) : prompts!.length === 0 ? (
          <div className="flex w-full flex-col items-center justify-center gap-4 py-20">
            <h6 className="text-lg text-muted-foreground">No prompts found</h6>
            <NewPromptDialog />
          </div>
        ) : (
          prompts?.map((prompt) => (
            <div
              key={prompt.id}
              id={prompt.id.toString()}
              className="flex flex-col gap-2 rounded-sm border border-border bg-card p-4 md:flex-row md:gap-6"
            >
              <div className="flex-grow">
                <h2 className="font-bricolage-semibold text-lg">
                  {prompt.name}
                </h2>
                <p className="text-pretty text-sm text-muted-foreground">
                  {prompt.prompt}
                </p>
              </div>
              <div className="flex flex-shrink-0 flex-row space-x-2">
                <EditPromptDialog prompt={prompt} />
                <DeletePromptDialog prompt={prompt} />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SpacePrompts;
