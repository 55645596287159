import {
  AudioLines,
  MicVocal,
  ListStart,
  LayoutGrid,
  Text,
  CloudUpload,
} from "lucide-react";

export const planFeatures = [
  [
    {
      icon: <AudioLines size={18} strokeWidth={2.5} />,
      feature: "300 processing minutes",
    },
    {
      icon: <MicVocal size={18} strokeWidth={2.5} />,
      feature: "3 Brand Voices",
    },
    {
      icon: <LayoutGrid size={18} strokeWidth={2.25} />,
      feature: "Unlimited spaces",
    },
    { icon: <Text size={18} strokeWidth={2.25} />, feature: "Custom Prompts" },
    {
      icon: <CloudUpload size={18} strokeWidth={2.25} />,
      feature: "Unlimited uploads",
    },
  ],
  [
    {
      icon: <AudioLines size={18} strokeWidth={2.5} />,
      feature: "900 processing minutes",
    },
    {
      icon: <MicVocal size={18} strokeWidth={2.5} />,
      feature: "10 Brand Voices",
    },
    {
      icon: <ListStart size={18} strokeWidth={2.5} />,
      feature: "Priority AI Processing",
    },
    {
      icon: <LayoutGrid size={18} strokeWidth={2.25} />,
      feature: "Unlimited spaces",
    },
    { icon: <Text size={18} strokeWidth={2.25} />, feature: "Custom Prompts" },
    {
      icon: <CloudUpload size={18} strokeWidth={2.25} />,
      feature: "Unlimited uploads",
    },
  ],
  [
    {
      icon: <AudioLines size={18} strokeWidth={2.5} />,
      feature: "2500 processing minutes",
    },
    {
      icon: <MicVocal size={18} strokeWidth={2.5} />,
      feature: "Unlimited Brand Voices",
    },
    {
      icon: <ListStart size={18} strokeWidth={2.5} />,
      feature: "Priority AI Processing",
    },
    {
      icon: <LayoutGrid size={18} strokeWidth={2.25} />,
      feature: "Unlimited spaces",
    },
    {
      icon: <Text size={18} strokeWidth={2.25} />,
      feature: "Custom Prompts",
    },
    {
      icon: <CloudUpload size={18} strokeWidth={2.25} />,
      feature: "Unlimited uploads",
    },
  ],
];
