import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Ellipsis, Edit, Plus, Text } from "lucide-react";
import { formatDate } from "@/lib/date";

import FetchError from "@/components/Error/FetchError";

import { useSpacePreviews } from "@/api/hooks/useSpaces";

type SpacesTableProps = {
  setDialogOpen: (open: boolean) => void;
};

const SpacesTable = ({ setDialogOpen }: SpacesTableProps) => {
  const navigate = useNavigate();
  const { spaces, spacesLoading, spacesError } = useSpacePreviews();

  return (
    <div>
      <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
        {spacesLoading ? (
          [...Array(6)].map((_, index) => (
            <Skeleton key={index} className="h-28 w-full" />
          ))
        ) : spacesError ? (
          <FetchError className="col-span-3" />
        ) : spaces?.length === 0 ? (
          <button
            onClick={() => setDialogOpen(true)}
            className="flex h-28 flex-col items-center justify-center gap-2 rounded-sm border-2 border-dashed border-border bg-card p-4 text-muted-foreground transition-all hover:border-primary active:scale-95"
          >
            <Plus size={28} strokeWidth={2} />
            <p className="text-sm">New Space</p>
          </button>
        ) : (
          spaces?.map((space, index) => (
            <div
              key={index}
              onClick={() => navigate(`/spaces/${space.slug}/recordings`)}
              className="border-1 group cursor-pointer space-y-4 rounded-sm border bg-card p-4 transition-all hover:border-primary"
            >
              <div className="flex flex-row space-x-2">
                <div className="flex flex-grow items-center space-x-2">
                  <div className="relative size-11 flex-shrink-0 overflow-hidden rounded-full">
                    {/* Blurred Background Emoji */}
                    <div className="absolute left-0 right-0 m-auto flex size-11 items-center justify-center text-4xl blur-[12px]">
                      {space.emoji}
                    </div>
                    {/* Main Emoji */}
                    <div className="relative flex size-11 items-center justify-center rounded-full border border-gray-300 text-xl">
                      {space.emoji}
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <Link
                      to={`/spaces/${space.slug}/recordings`}
                      className="line-clamp-1 font-bricolage-medium"
                    >
                      {space.name}
                    </Link>
                    <div className="text-sm">
                      {space.preset ? space.preset.name : null}
                    </div>
                  </div>
                </div>
                <div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        size="icon"
                        variant="outline"
                        className="size-7 flex-shrink-0 rounded-sm border-none p-0.5"
                      >
                        <Ellipsis
                          size={18}
                          strokeWidth={2.5}
                          className="text-muted-foreground"
                        />
                      </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent
                      side="bottom"
                      align="end"
                      className="z-20"
                    >
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/spaces/${space.slug}/settings`);
                        }}
                      >
                        <Edit size={16} strokeWidth={2.5} className="mr-2" />
                        Edit
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/spaces/${space.slug}/prompts`);
                        }}
                      >
                        <Text size={16} strokeWidth={2.5} className="mr-2" />
                        Prompts
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              <div className="text-sm text-muted-foreground">
                Created on {formatDate(space.created_at, "MMM DD, yyyy")}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SpacesTable;
