import { mutate } from "swr";
import { initUpload, uploadChunk, completeUpload } from "@/api/services/media";
import { mediaUrl } from "@/api/hooks/useMedia";

const CHUNK_CONCURRENCY_LIMIT = 5;

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export interface CustomFile extends File {
  progress: number;
  error?: string;
}

export const uploadFilesConcurrently = async (
  files: CustomFile[],
  updateFile: (
    fileName: string,
    updates: { progress?: number; error?: string },
  ) => void,
) => {
  await Promise.all(files.map(uploadSingleFile(updateFile)));
};

const uploadSingleFile =
  (
    updateFile: (
      fileName: string,
      updates: { progress?: number; error?: string },
    ) => void,
  ) =>
  async (file: CustomFile) => {
    const abortController = new AbortController();

    try {
      // Initialize and prepare upload
      const { chunkUploads, key, upload_id } =
        await initializeAndPrepareUpload(file);

      updateFile(file.name, { progress: 1 });

      // Upload chunks concurrently
      const etags = await uploadChunksWithConcurrencyLimit(
        file,
        chunkUploads,
        abortController,
        updateFile,
      );

      // Complete upload
      // try {
      const mediaFile = await completeUpload(key, upload_id, etags);
      mutate(mediaUrl, (data) => [mediaFile, ...data], { revalidate: false });
      updateFile(file.name, { progress: 100 });

      // } catch (completeError) {
      //   const error = completeError as Error;
      //   updateFile(file.name, { progress: -1, error: error.message });
      //   throw completeError;
      // }

      updateFile(file.name, { progress: 100 });
    } catch (error) {
      updateFile(file.name, { progress: -1 });
      abortController.abort();
    }
  };

const initializeAndPrepareUpload = async (file: CustomFile) => {
  const initResponse = await initUpload(file);
  if (!initResponse) {
    throw new Error(`Failed to initialize upload for ${file.name}`);
  }

  const { chunk_size, urls, key, upload_id } = initResponse;
  const chunks = Math.ceil(file.size / chunk_size);

  if (!urls || urls.length !== chunks) {
    throw new Error(
      `Mismatch in number of URLs (${urls?.length}) and chunks (${chunks}) for ${file.name}`,
    );
  }

  const chunkUploads = Array.from({ length: chunks }, (_, j) => {
    const start = j * chunk_size;
    const end = Math.min(start + chunk_size, file.size);
    const chunk = file.slice(start, end);
    const url = urls[j];
    if (!url) {
      throw new Error(`Missing URL for chunk ${j} of ${file.name}`);
    }
    return { chunk, url, index: j };
  });

  return { chunkUploads, key, upload_id };
};

interface ChunkUpload {
  chunk: Blob;
  url: string;
  index: number;
}

interface QueueItem extends ChunkUpload {
  status: "pending" | "inProgress" | "completed";
}

const uploadChunksWithConcurrencyLimit = async (
  file: File,
  chunkUploads: ChunkUpload[],
  abortController: AbortController,
  updateFile: (
    fileName: string,
    updates: { progress?: number; error?: string },
  ) => void,
): Promise<string[]> => {
  const totalChunks = chunkUploads.length;
  const etags: (string | null)[] = new Array(totalChunks).fill(null);
  let completedChunks = 0;

  // Initialize queue with all chunks set to 'pending'
  const queue: QueueItem[] = chunkUploads.map((chunkUpload) => ({
    ...chunkUpload,
    status: "pending",
  }));

  const processQueue = async (): Promise<void> => {
    while (queue.some((item) => item.status !== "completed")) {
      const inProgressCount = queue.filter(
        (item) => item.status === "inProgress",
      ).length;
      const availableSlots = CHUNK_CONCURRENCY_LIMIT - inProgressCount;

      // Start uploading chunks that fit within the concurrency limit
      for (let i = 0; i < availableSlots; i++) {
        const nextChunk = queue.find((item) => item.status === "pending");
        if (nextChunk) {
          nextChunk.status = "inProgress";
          uploadSingleChunk(nextChunk);
        }
      }

      // Small delay to prevent tight loop
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const uploadSingleChunk = async (chunkInfo: QueueItem): Promise<void> => {
    try {
      // Upload the chunk and get the ETag
      const etag = await uploadChunk(
        chunkInfo.url,
        chunkInfo.chunk,
        file.type,
        abortController.signal,
      );

      completedChunks++;
      const progress = Math.round((completedChunks / totalChunks) * 100);
      updateFile(file.name, { progress: progress === 100 ? 99 : progress });

      chunkInfo.status = "completed";
      etags[chunkInfo.index] = etag;
    } catch (err) {
      const error = err as Error;
      chunkInfo.status = "completed";

      // Abort the upload and throw an error
      abortController.abort();

      throw new Error(
        `Failed to upload chunk ${chunkInfo.index + 1} for ${file.name}: ${
          error.message
        }`,
      );
    }
  };

  // Start processing the queue
  await processQueue();

  // Check if all chunks were uploaded successfully
  if (etags.some((etag) => etag === null)) {
    throw new Error("One or more chunks failed to upload");
  }

  return etags as string[];
};
