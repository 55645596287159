import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

import { STATUS } from "@/api/utils";
import { transcribeRecording } from "@/api/services/recording";

type TranscribeButtonProps = {
  recordingId: string;
  size?: "sm" | "default" | "lg";
  variant?: "default" | "secondary" | "ghost" | "outline";
  className?: string;
};

const TranscribeButton = (props: TranscribeButtonProps) => {
  const { recordingId, size, variant, className } = props;
  const { spaceSlug } = useParams();
  if (!spaceSlug) throw new Error("Space slug is required.");

  const navigate = useNavigate();
  const { toast } = useToast();
  const [status, setStatus] = useState(STATUS.IDLE);

  const onTranscribe = async () => {
    setStatus(STATUS.LOADING);

    try {
      await transcribeRecording(spaceSlug, recordingId);
      setStatus(STATUS.SUCCESS);
      toast({
        title: "Transcription started",
        description:
          "The transcription has started. Please check back in a few minutes.",
        variant: "success",
      });
      navigate(`/spaces/${spaceSlug}/recordings/${recordingId}`);
    } catch (error) {
      setStatus(STATUS.ERROR);
      toast({
        title: "Transcription error",
        description: (error as Error).message,
        variant: "error",
      });
    }
  };

  return (
    <Button
      size={size || "sm"}
      className={cn("", className)}
      variant={variant || "default"}
      onClick={onTranscribe}
      isLoading={status === STATUS.LOADING}
    >
      Transcribe
    </Button>
  );
};

export default TranscribeButton;
