import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { AuthProvider } from "./views/Auth/AuthProvider";
import { AppProvider } from "./app/AppContextProvider";

import router from "./app/router";

import "./globals.css";
import "./main.css";
import "./assets/fonts/bricolage/bricolage.css";
import "./assets/fonts/rubik/rubik.css";

Sentry.init({
  dsn: "https://11d803be94ef8b934fe935787ba0d262@o4505902441693184.ingest.us.sentry.io/4508037721948160",
  debug: import.meta.env.VITE_DEBUG_MODE === "true",
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false
    }),
  ],
  // Tracing
  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    /^https:\/\/api\.reinspire\.io/, // api.reinspire.io
    /^https:\/\/staging-api\.reinspire\.io/, // staging-api.reinspire.io
  ],
  // Profiling
  profilesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>,
);
