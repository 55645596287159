import { useState, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import { DialogTrigger } from "@/components/ui/dialog";
import { Plus, Loader2 } from "lucide-react";

import FetchError from "@/components/Error/FetchError";
import BrandVoiceCard from "@/views/BrandVoices/components/Card/BrandVoiceCard";

import { useAuth } from "@/views/Auth/AuthProvider";
import { useBrandVoices } from "@/api/hooks/useBrandVoices";

const BrandVoicesSection = () => {
  const [brandVoicesLeft, setBrandVoicesLeft] = useState<number>(0);

  const { authState } = useAuth();
  const { brandVoices, brandVoicesLoading, brandVoicesError } =
    useBrandVoices();

  useEffect(() => {
    if (authState.currentUser?.subscription && brandVoices) {
      setBrandVoicesLeft(
        authState.currentUser?.subscription!.brand_voices_limit -
          brandVoices!.length,
      );
    }
  }, [authState.currentUser, brandVoices]);

  return (
    <section>
      <h2 className="font-bricolage-medium text-lg">Brand Voices</h2>
      <p className="mb-3 text-sm text-muted-foreground">
        Voices help Reinspire AI to tailor its writing to match the tone and
        style of your brand.
      </p>
      <Separator className="mb-6" />
      {brandVoicesLoading ? (
        <div className="flex w-full items-center justify-center py-4">
          <Loader2
            size={28}
            strokeWidth={2.5}
            className="animate-spin text-primary"
          />
        </div>
      ) : brandVoicesError ? (
        <FetchError />
      ) : (
        <div className="grid md:grid-cols-2 gap-4 xl:grid-cols-3">
          {brandVoices?.map((brandVoice, index) => (
            <BrandVoiceCard key={index} brandVoice={brandVoice} />
          ))}
          {((!authState.currentUser?.subscription &&
            brandVoices!.length === 0) ||
            (authState.currentUser?.subscription && brandVoicesLeft !== 0)) && (
            <DialogTrigger asChild>
              <button className="h-40 rounded-sm border-2 border-dashed border-muted bg-card text-muted-foreground transition-all hover:border-primary hover:text-primary active:scale-95">
                <div className="flex h-full flex-col items-center justify-center">
                  <Plus size={24} strokeWidth={2.5} />
                  <p className="text-sm">New Brand Voice</p>
                  {authState.currentUser?.subscription && (
                    <small className="mt-2 text-xs">
                      {brandVoicesLeft}/
                      {authState.currentUser.subscription.brand_voices_limit}{" "}
                      left
                    </small>
                  )}
                </div>
              </button>
            </DialogTrigger>
          )}
        </div>
      )}
    </section>
  );
};

export default BrandVoicesSection;
