import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import data, { Skin } from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { DialogClose, DialogFooter } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import TagInput from "@/components/Input/TagInput";

import { STATUS } from "@/api/utils";
import {
  createBrandVoice,
  editBrandVoice,
  AnalyzedBrandVoiceResponse,
} from "@/api/services/brandVoice";
import Alert from "@/components/Alert/Alert";

const formSchema = z.object({
  emoji: z.string().min(1),
  name: z
    .string()
    .min(2, {
      message: "Voice name must be at least 2 characters.",
    })
    .max(32, {
      message: "Voice name must be at most 32 characters.",
    }),
  purpose: z
    .string()
    .min(20, "Purpose must be at least 20 characters long.")
    .max(300, {
      message: "Purpose must be at most 300 characters.",
    }),
  audience: z
    .string()
    .min(20, "Audience must be at least 20 characters long.")
    .max(300, {
      message: "Audience must be at most 300 characters.",
    }),
  tone: z.array(z.string()).min(1, "Must contain at least 1 item").max(5),
  emotion: z.array(z.string()).min(1, "Must contain at least 1 item").max(5),
  character: z.array(z.string()).min(1, "Must contain at least 1 item").max(5),
  language: z.array(z.string()).min(1, "Must contain at least 1 item").max(5),
});

type formSchema = z.infer<typeof formSchema>;

type EditVoiceFormProps = {
  setStep?: (step: "analyze" | "edit") => void;
  setDialogOpen: (open: boolean) => void;
  brandVoice: AnalyzedBrandVoiceResponse | BrandVoice | null;
};

const EditVoiceForm = (props: EditVoiceFormProps) => {
  const { setStep, setDialogOpen, brandVoice } = props;
  const { toast } = useToast();
  const [showPicker, setShowPicker] = useState(false);
  const [status, setStatus] = useState(STATUS.IDLE);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emoji: brandVoice?.emoji || "",
      name: brandVoice?.name || "",
      purpose: brandVoice?.purpose || "",
      audience: brandVoice?.audience || "",
      tone: brandVoice?.tone || [],
      emotion: brandVoice?.emotion || [],
      character: brandVoice?.character || [],
      language: brandVoice?.language || [],
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      if (setStep) {
        await createBrandVoice(data);
      } else {
        await editBrandVoice((brandVoice! as BrandVoice).id, data);
      }
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);

      // if editing, show success toast
      if (!setStep) {
        toast({
          title: "Edited successfully",
          variant: "success",
        });
      }
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  const handleEmojiSelect = (emoji: Skin) => {
    form.setValue("emoji", emoji.native);
    setShowPicker(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {status === STATUS.ERROR && (
          <Alert type="error" message={form.formState.errors.root!.message!} />
        )}
        <div className="space-y-2">
          <h4 className="font-bricolage-medium">Voice Name</h4>
          <div className="flex flex-row items-start gap-2">
            <FormField
              control={form.control}
              name="emoji"
              render={({ field }) => (
                <FormItem className="relative flex w-10 flex-shrink-0 items-center gap-4">
                  <FormControl>
                    <>
                      <Button
                        variant="outline"
                        type="button"
                        className="size-10 text-xl"
                        onClick={() => setShowPicker(!showPicker)}
                      >
                        {field.value || "🎙️"}
                      </Button>
                      {showPicker && (
                        <div
                          className="absolute left-0 top-full z-10"
                          onWheel={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Picker
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                            theme="light"
                            icons="outline"
                            previewPosition="none"
                            emojiVersion="15.0"
                            skinTonePosition="none"
                            maxFrequentRows={2}
                            emojiButtonColors={["#F3F4F6"]}
                            dynamicWidth={true}
                          />
                        </div>
                      )}
                    </>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex-grow">
                  <FormControl>
                    <Input {...field} placeholder="Enter voice name..." />
                  </FormControl>
                  <FormMessage {...field} />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="space-y-2">
          <h4 className="font-bricolage-medium">Voice Style</h4>
          <FormField
            control={form.control}
            name="purpose"
            render={({ field }) => (
              <FormItem className="flex flex-col items-start sm:flex-row sm:gap-4">
                <FormLabel className="min-w-16 pt-1.5 text-sm">
                  Purpose
                </FormLabel>
                <div className="flex w-full flex-grow flex-col gap-1">
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Enter the purpose of this voice..."
                      className="min-h-20 resize-none"
                    />
                  </FormControl>
                  <FormMessage {...field} />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="audience"
            render={({ field }) => (
              <FormItem className="flex flex-col items-start sm:flex-row sm:gap-4">
                <FormLabel className="min-w-16 pt-1.5 text-sm ">
                  Audience
                </FormLabel>
                <div className="flex w-full flex-grow flex-col gap-1">
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Enter the audience for this voice..."
                      className="min-h-20 resize-none"
                    />
                  </FormControl>
                  <FormMessage {...field} />
                </div>
              </FormItem>
            )}
          />

          {/* Tone */}
          <FormField
            control={form.control}
            name="tone"
            render={({ field }) => (
              <FormItem className="flex flex-col items-start sm:flex-row sm:gap-4">
                <FormLabel className="min-w-16 pt-1.5 text-sm">Tone</FormLabel>
                <div className="flex-grow">
                  <FormControl>
                    <TagInput<formSchema>
                      field={field}
                      maxTagLength={20}
                      maxTags={5}
                      placeholder="Formal, casual..."
                    />
                  </FormControl>
                  <FormMessage {...field} />
                </div>
              </FormItem>
            )}
          />

          {/* Emotion */}
          <FormField
            control={form.control}
            name="emotion"
            render={({ field }) => (
              <FormItem className="flex flex-col items-start sm:flex-row sm:gap-4">
                <FormLabel className="min-w-16 pt-1.5 text-sm">
                  Emotion
                </FormLabel>
                <div className="flex-grow">
                  <FormControl>
                    <TagInput<formSchema>
                      field={field}
                      maxTagLength={20}
                      maxTags={5}
                      placeholder="Happy, excited..."
                    />
                  </FormControl>
                  <FormMessage {...field} />
                </div>
              </FormItem>
            )}
          />

          {/* Character */}
          <FormField
            control={form.control}
            name="character"
            render={({ field }) => (
              <FormItem className="flex flex-col items-start sm:flex-row sm:gap-4">
                <FormLabel className="min-w-16 pt-1.5 text-sm">
                  Character
                </FormLabel>
                <div className="flex-grow">
                  <FormControl>
                    <TagInput<formSchema>
                      field={field}
                      maxTagLength={20}
                      maxTags={5}
                      placeholder="Heroic, villainous..."
                    />
                  </FormControl>
                  <FormMessage {...field} />
                </div>
              </FormItem>
            )}
          />

          {/* Language */}
          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem className="flex flex-col items-start sm:flex-row sm:gap-4">
                <FormLabel className="min-w-16 pt-1.5 text-sm">
                  Language
                </FormLabel>
                <div className="flex-grow">
                  <FormControl>
                    <TagInput<formSchema>
                      field={field}
                      maxTagLength={20}
                      maxTags={5}
                      placeholder="Simple, clear, and concise..."
                    />
                  </FormControl>
                  <FormMessage {...field} />
                </div>
              </FormItem>
            )}
          />
        </div>

        <DialogFooter className="gap-2">
          {setStep ? (
            <Button
              type="button"
              variant="outline"
              className="min-w-20"
              onClick={() => setStep("analyze")}
            >
              Back
            </Button>
          ) : (
            <DialogClose asChild>
              <Button type="button" variant="outline" className="min-w-20">
                Cancel
              </Button>
            </DialogClose>
          )}

          <Button
            type="submit"
            className="min-w-20"
            isLoading={status === STATUS.LOADING}
          >
            {setStep ? "Create Voice" : "Update Voice"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default EditVoiceForm;
