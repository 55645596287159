import { BarLoader } from "react-spinners";
import LogoUrl from "@/assets/images/logo-square.png";

const LoadingScreen = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6">
      <img src={LogoUrl} alt="Reinspire Logo" className="size-16" />
      <BarLoader color="#0098ea" height={5} width={120} className="rounded" />
    </div>
  );
};

export default LoadingScreen;
