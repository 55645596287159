import { Outlet } from "react-router-dom";
import TabButton from "@/components/Button/TabButton";

const AccountLayout = () => {
  return (
    <div className="space-y-3">
      <h1 className="font-bricolage-semibold text-2xl md:text-3xl">Settings</h1>
      <div className="overflow-x-scroll">
        <nav className="relative flex flex-row gap-8 border-b border-border">
          <TabButton text="Account & Security" to="/account/settings" />
          <TabButton text="Integrations" to="/account/integrations" />
          <TabButton text="Billing" to="/account/billing" />
        </nav>
      </div>
      <div className="py-4">
        <Outlet />
      </div>
    </div>
  );
};

export default AccountLayout;
