import * as React from "react";

import { cn } from "@/lib/utils";
import { Eye, EyeOff, LucideIcon, LucideProps } from "lucide-react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: LucideIcon;
  endIcon?: LucideIcon;
  iconProps?: LucideProps;
  showEye?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      startIcon,
      endIcon,
      iconProps = {},
      showEye = false,
      ...props
    },
    ref,
  ) => {
    const [show, setShow] = React.useState(false);
    const StartIcon = startIcon;
    const EndIcon = endIcon;
    const { className: iconClassName, ...iconRest } = iconProps;

    if (showEye && type === "password") {
      return (
        <div className="relative w-full">
          {StartIcon && (
            <div className="absolute left-3 top-1/2 -translate-y-1/2 transform">
              <StartIcon
                size={18}
                className={cn("text-black", iconClassName)}
                {...iconRest}
              />
            </div>
          )}
          <input
            autoComplete="off"
            type={show ? "text" : "password"}
            className={cn(
              "flex h-10 w-full rounded-md border border-input bg-background px-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
              startIcon ? "pl-9" : "",
              endIcon ? "pr-9" : "",
              className,
            )}
            ref={ref}
            {...props}
          />
          <button
            onClick={() => setShow((prev) => !prev)}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform"
            type="button"
          >
            {show ? (
              <Eye className="stroke-black" size={18} />
            ) : (
              <EyeOff className="stroke-black" size={18} />
            )}
          </button>
        </div>
      );
    }

    return (
      <div className="relative w-full">
        {StartIcon && (
          <div className="absolute left-3 top-1/2 -translate-y-1/2 transform">
            <StartIcon
              size={18}
              className={cn("text-black", iconClassName)}
              {...iconRest}
            />
          </div>
        )}
        <input
          type={type}
          className={cn(
            "flex h-10 w-full rounded-md border border-input bg-background px-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
            startIcon ? "pl-9" : "",
            endIcon ? "pr-9" : "",
            className,
          )}
          ref={ref}
          {...props}
        />
        {EndIcon && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
            <EndIcon
              className={cn("text-black", iconClassName)}
              {...iconRest}
              size={18}
            />
          </div>
        )}
      </div>
    );
  },
);

export { Input };
