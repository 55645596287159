import { CircleCheckBig } from "lucide-react";

const features = [
  "Volume based pricing",
  "API integration",
  "Priority support"
];

const EnterprisePlanCard = () => {
  return (
    <div className="flex flex-col justify-between gap-8 rounded-sm border border-border bg-card p-4 lg:flex-row lg:items-center">
      <div className="flex-shrink-0">
        <h2 className="font-bricolage-medium text-2xl">Enterprise Plan</h2>
        <p className="text-sm text-muted-foreground">
          For large organizations with custom needs
        </p>
      </div>
      <div className="flex flex-grow flex-col flex-wrap justify-center gap-x-6 gap-y-2 md:flex-row">
        {features.map((feature) => (
          <div key={feature} className="flex flex-row items-center gap-2">
            <CircleCheckBig
              size={16}
              strokeWidth={2.5}
              className="text-green-600"
            />
            <span>{feature}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnterprisePlanCard;
