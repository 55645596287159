import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import LoadingScreen from "@/views/Core/components/LoadingScreen";
import { getUser } from "@/api/services/user";

export enum AuthStatus {
  IDLE = "idle",
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
}

interface AuthState {
  status: AuthStatus;
  currentUser: User | null;
}

interface AuthContextType {
  authState: AuthState;
  setAuthState: (state: AuthState) => void;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

const MIN_LOADING_TIME = 800; // 800ms

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authState, setAuthState] = useState<AuthState>({
    status: AuthStatus.IDLE,
    currentUser: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const refreshUser = async () => {
    try {
      const userData = await getUser();
      setAuthState({
        status: AuthStatus.AUTHENTICATED,
        currentUser: userData,
      });
    } catch (error) {
      setAuthState({
        status: AuthStatus.UNAUTHENTICATED,
        currentUser: null,
      });
    }
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    const initializeAuth = async () => {
      const startTime = Date.now();
      await refreshUser();

      if (window.location.pathname !== "/oauth-callback") {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, MIN_LOADING_TIME - elapsedTime);
        timerId = setTimeout(() => setIsLoading(false), remainingTime);
      } else {
        setIsLoading(false);
      }
    };

    initializeAuth();

    // Cleanup function
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        refreshUser,
      }}
    >
      {isLoading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
