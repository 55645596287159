export function postProcessOutput(output: string): string {
  const timestampRangeRegex = /(\d{2}:\d{2}:\d{2}) --> (\d{2}:\d{2}:\d{2})/g;
  const singleTimestampRegex = /\b(\d{2}:\d{2}:\d{2})\b(?!<\/Timestamp>)/g;

  const createTimestampComponent = (timestamp: string): string => {
    return `<Timestamp>${timestamp}</Timestamp>`;
  };

  output = output.replace(timestampRangeRegex, (_, start, end) => {
    return `${createTimestampComponent(start)} – ${createTimestampComponent(
      end,
    )}`;
  });

  output = output.replace(singleTimestampRegex, (match) => {
    return createTimestampComponent(match);
  });

  return output;
}
