import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import Alert from "@/components/Alert/Alert";
import { useToast } from "@/components/ui/use-toast";

import { STATUS } from "@/api/utils";
import { changeSubscriptionPlan } from "@/api/services/subscription";

type ChangePlanDialogProps = {
  planName: string;
  priceId: string;
};

const ChangePlanDialog = ({ planName, priceId }: ChangePlanDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [actionError, setActionError] = useState<string | null>(null);

  const { toast } = useToast();

  const handlePlanChange = async () => {
    setStatus(STATUS.LOADING);

    try {
      await changeSubscriptionPlan(priceId);
      setStatus(STATUS.SUCCESS);

      toast({
        title: "Plan changed successfully",
        description: `You have successfully changed your plan to the ${planName} plan.`,
        variant: "success",
      });
      setIsOpen(false);
    } catch (error) {
      setActionError((error as Error).message);
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button className="!mt-10 w-full flex-shrink-0">Select Plan</Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="!rounded-sm">
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to change your plan?
          </AlertDialogTitle>
          <AlertDialogDescription className="space-y-2">
            {actionError && <Alert type="error" message={actionError} />}
            <p>
              This action will change your current plan to the{" "}
              <span className="font-bricolage-semibold">{planName}</span> plan.
              You will be charged the prorated amount for the remaining days of
              the current billing cycle.
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            onClick={handlePlanChange}
            isLoading={status === STATUS.LOADING}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChangePlanDialog;
