import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { CircleX } from "lucide-react";
import LogoUrl from "@/assets/images/logo-square.png";

import OnboardingForm from "@/views/Onboarding/components/Form/OnboardingForm.tsx";

import { useAuth, AuthStatus } from "@/views/Auth/AuthProvider";

const Onboarding = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.status === AuthStatus.IDLE) return;

    if (authState.status === AuthStatus.UNAUTHENTICATED) {
      // Redirect to login page
      navigate("/login", { replace: true });
    }

    if (
      authState.status === AuthStatus.AUTHENTICATED &&
      authState.currentUser?.is_onboarded
    ) {
      // Redirect to spaces page
      navigate("/spaces", { replace: true });
    }
  }, [authState]);

  if (
    authState.status !== AuthStatus.AUTHENTICATED ||
    authState.currentUser?.is_onboarded
  ) {
    return null;
  }

  return (
    <div className="relative h-screen">
      <div className="absolute -right-10 left-0 top-0 h-80 origin-top-right rotate-6 transform bg-primary"></div>
      <div className="relative flex justify-center px-2 py-20">
        {!authState.currentUser ? (
          <div className="flex flex-col items-center gap-1 py-2">
            <CircleX strokeWidth={2.5} size={36} className="text-destructive" />
            <p>Something went wrong</p>
            <small className="text-muted-foreground">
              Please refresh the page and try again
            </small>
          </div>
        ) : (
          <Card className="animate-appearance-in w-[600px]">
            <CardHeader className="flex items-center">
              <img src={LogoUrl} alt="logo" className="mb-2 size-14" />
              <CardTitle>Welcome to Reinspire 👋</CardTitle>
              <CardDescription>
                We just need a few details to get you set up.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <OnboardingForm user={authState.currentUser} />
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
