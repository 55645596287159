import axiosRetry from "axios-retry";
import { apiInstance } from "@/api/index";
import type { AxiosError, AxiosResponse } from "axios";

axiosRetry(apiInstance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition(err) {
    const error = err as AxiosError;

    if (!error.response) {
      return true;
    }

    const retryCodes = [429, 500];
    return retryCodes.includes(error.response.status as number);
  },
});

export class UserAlreadyVerified extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

const BadRequest: Record<string, string> = {
  VERIFY_USER_BAD_TOKEN: "Invalid verification token",
  VERIFY_USER_ALREADY_VERIFIED: "User already verified",
};

export const handleVerifyRequest = async (email: string) => {
  await apiInstance
    .post("/auth/request-verify-token", { email: email })
    .catch(() => {
      console.error("Verification request failed. Try again");
    });
};

export const handleEmailVerify = async (token: string) => {
  try {
    const response = await apiInstance.post("/auth/verify", { token: token });
    return response;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    }

    if (response.status === 400) {
      if (
        BadRequest[response.data.detail] ===
        BadRequest.VERIFY_USER_ALREADY_VERIFIED
      ) {
        throw new UserAlreadyVerified(BadRequest[response.data.detail]);
      }
      throw new Error(BadRequest[response.data.detail]);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    }

    throw new Error("Something went wrong. Try again.");
  }
};
