import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import {
  useMediaRemote,
  useMediaStore,
  MediaPlayerInstance,
} from "@vidstack/react";
import { Button } from "@/components/ui/button";
import { convertSecondsToTimestamp } from "@/lib/time";
import { MoveUp, ArrowDown } from "lucide-react";

import AssignSpeakerDialog from "@/views/Recordings/components/Dialog/AssignSpeakerDialog";

type TranscriptEditorProps = {
  recording: Recording;
  transcript: TranscriptChunk[];
  player: React.RefObject<MediaPlayerInstance>;
};

type TranscriptChunkProps = {
  recording: Recording;
  chunk: TranscriptChunk;
  chunkMeta: RecordingTranscriptChunk;
  currentTime: number;
  seekToTimestamp: (startTime: number) => void;
  isFirstUnassigned: boolean;
};

const TranscriptChunk = React.memo(
  ({
    recording,
    chunk,
    chunkMeta,
    currentTime,
    seekToTimestamp,
    isFirstUnassigned,
  }: TranscriptChunkProps) => {
    const textRef = useRef<HTMLParagraphElement>(null);

    const activeWordIndex = useMemo(() => {
      return chunk.words.findIndex(
        (word) => currentTime >= word.start && currentTime < word.end,
      );
    }, [chunk.words, currentTime]);

    const handleWordClick = useCallback(() => {
      const selection = window.getSelection();
      if (!selection || !textRef.current) return;

      const range = selection.getRangeAt(0);
      const node = range.startContainer;
      const fullText = textRef.current.textContent || "";

      let startOffset = fullText.indexOf(node.textContent || "", 0);
      startOffset += range.startOffset;

      // Find the index of the clicked word
      const wordIndex =
        fullText.substring(0, startOffset).split(/\s+/).length - 1;

      const clickedWordData = chunk.words[wordIndex];

      if (clickedWordData) {
        seekToTimestamp(clickedWordData.start);
      }
    }, [chunk.words, seekToTimestamp]);

    const renderText = useCallback(() => {
      if (activeWordIndex === -1) {
        return <span>{chunk.text}</span>;
      }

      const beforeText = chunk.words
        .slice(0, activeWordIndex)
        .map((w) => w.word)
        .join(" ");
      const activeWord = chunk.words[activeWordIndex].word;
      const afterText = chunk.words
        .slice(activeWordIndex + 1)
        .map((w) => w.word)
        .join(" ");

      return (
        <>
          <>{beforeText} </>
          <span className="mx-[-2.5px] rounded-[5px] bg-primary px-[2.5px] py-[1.5px] text-white">
            {activeWord}
          </span>
          <> {afterText}</>
        </>
      );
    }, [chunk.words, chunk.text, activeWordIndex]);

    return (
      <div
        id={chunk.id}
        className="w-full scroll-m-4 space-y-1 border-l-2 border-border pl-4"
      >
        <div className="relative flex items-center justify-between">
          <div className="flex items-center gap-2">
            <AssignSpeakerDialog
              recording={recording}
              transcriptChunk={chunk}
              transcriptChunkMeta={chunkMeta}
            />
            {isFirstUnassigned && (
              <div className="absolute left-24 flex animate-pointer-left items-center sm:static">
                <div className="h-0 w-0 border-b-[5px] border-r-[6px] border-t-[5px] border-b-transparent border-r-primary border-t-transparent"></div>
                <span className="flex items-center justify-center rounded-[4px] border border-primary bg-primary px-2 py-0.5 text-xs text-white">
                  Click to assign speaker
                </span>
              </div>
            )}
          </div>
          <button
            onClick={() => seekToTimestamp(chunk.start)}
            className="font-bricolage-regular text-sm text-muted-foreground underline-offset-2 hover:underline"
          >
            {convertSecondsToTimestamp(chunk.start)} -{" "}
            {convertSecondsToTimestamp(chunk.end)}
          </button>
        </div>
        <p ref={textRef} className="text-sm" onClick={handleWordClick}>
          {renderText()}
        </p>
      </div>
    );
  },
);

const TranscriptEditor = ({
  recording,
  transcript,
  player,
}: TranscriptEditorProps) => {
  const remote = useMediaRemote(player);
  const { currentTime } = useMediaStore(player);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [firstUnassignedId, setFirstUnassignedId] = useState<string | null>(
    null,
  );

  const seekToTimestamp = useCallback(
    (startTime: number) => {
      remote.seeking(startTime);
      remote.seek(startTime);
    },
    [remote],
  );

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const checkScroll = () => {
      setShowScrollButton(window.scrollY > 250);
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  const transcriptContent = useMemo(() => {
    let firstUnassignedFound = false;

    return (
      <div className="relative flex flex-col items-center">
        <div className="space-y-6">
          {transcript?.map((chunk, index) => {
            const chunkMeta = recording.transcript_chunks[index];
            const isFirstUnassigned =
              !firstUnassignedFound && !chunkMeta.space_speaker;

            if (isFirstUnassigned) {
              firstUnassignedFound = true;
              setFirstUnassignedId(chunk.id);
            }

            return (
              <TranscriptChunk
                key={chunk.id}
                recording={recording}
                chunk={chunk}
                chunkMeta={chunkMeta}
                currentTime={currentTime}
                seekToTimestamp={seekToTimestamp}
                isFirstUnassigned={isFirstUnassigned}
              />
            );
          })}
        </div>
      </div>
    );
  }, [currentTime, recording, transcript, seekToTimestamp]);

  return (
    <div className="space-y-6">
      {firstUnassignedId && (
        <div className="flex flex-col justify-between gap-4 rounded-sm border border-border bg-card p-3 lg:flex-row lg:items-center">
          <div>
            <h3 className="font-bricolage-medium text-base">
              Speakers not assigned
            </h3>
            <p className="text-sm text-muted-foreground">
              Please assign speakers to all transcript sections before
              generating AI content.
            </p>
          </div>
          <Button asChild size="sm">
            <a href={`#${firstUnassignedId}`}>
              <ArrowDown size={16} className="mr-1" />
              Scroll to unassigned speaker
            </a>
          </Button>
        </div>
      )}

      {transcriptContent}

      {showScrollButton && (
        <Button
          variant="outline"
          size="icon"
          onClick={handleScroll}
          className="fixed right-3 top-10 rounded-full bg-card shadow-md sm:top-0"
        >
          <MoveUp size={17} strokeWidth={2.5} />
        </Button>
      )}
    </div>
  );
};

export default React.memo(TranscriptEditor);
