import useSwr from "swr";
import { baseFetcher } from "@/api/index";

export const useTranscript = (url: string) => {
  const { data, error, isLoading } = useSwr<TranscriptChunk[], Error>(
    url,
    baseFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    transcript: data,
    transcriptError: error,
    transcriptLoading: isLoading,
  };
};
