export const leadSources = {
  google: "Google",
  twitter: "Twitter",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  youtube: "YouTube",
  tiktok: "TikTok",
  facebook: "Facebook",
  reddit: "Reddit",
  newsletter: "Newsletter",
  friend: "Friend",
  other: "Other",
};

export const leadRoles = {
  founder: "Founder / Owner",
  marketer: "Content Marketer / Copywriter",
  social: "Social Media Manager",
  content: "Content Creator",
  freelancer: "Freelancer",
  other: "Other",
};

export const supportedLanguages = [
  // Common
  "auto",
  "english",
  "spanish",
  "french",
  // Other
  "albanian",
  "arabic",
  "armenian",
  "azerbaijani",
  "belarusian",
  "bulgarian",
  "catalan",
  "chinese",
  "croatian",
  "czech",
  "danish",
  "dutch",
  "estonian",
  "finnish",
  "galician",
  "german",
  "greek",
  "haitian creole",
  "hausa",
  "hawaiian",
  "hebrew",
  "hindi",
  "hungarian",
  "icelandic",
  "italian",
  "japanese",
  "javanese",
  "korean",
  "lao",
  "latin",
  "latvian",
  "lingala",
  "lithuanian",
  "luxembourgish",
  "macedonian",
  "malay",
  "marathi",
  "mongolian",
  "myanmar",
  "nepali",
  "norwegian",
  "pashto",
  "persian",
  "polish",
  "portuguese",
  "romanian",
  "russian",
  "serbian",
  "slovak",
  "slovenian",
  "sundanese",
  "swahili",
  "swedish",
  "tajik",
  "tatar",
  "thai",
  "tibetan",
  "turkish",
  "turkmen",
  "ukrainian",
  "urdu",
  "uzbek",
  "vietnamese",
];

export const emojis = [
  "🚀",
  "🌟",
  "🔥",
  "🎉",
  "🎤",
  "🎸",
  "🎬",
  "🎮",
  "🎯",
  "🎲",
  "🎭",
  "🎤",
  "🎥",
  "😂",
  "👽",
  "🎯",
  "😎",
  "🐐",
  "🦸‍♂️",
  "💻",
  "🎙️",
  "🐬",
];
