import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  ChevronDown,
  ChevronUp,
  CornerLeftDown,
  WandSparkles,
} from "lucide-react";

import AIContentCard from "@/views/Recordings/components/Card/AIContentCard";

import { STATUS } from "@/api/utils";
import { useAIContent } from "@/api/hooks/useAIContent";

type AIContentTableProps = {
  spaceSlug: string;
  recording: Recording;
  onGenerateAIContent: () => void;
  status: string;
  seekToTimestamp: (startTime: number) => void;
};

const AIContentTable = ({
  spaceSlug,
  recording,
  onGenerateAIContent,
  status,
  seekToTimestamp,
}: AIContentTableProps) => {
  const [expandAll, setExpandAll] = useState<boolean>(true);

  const { content, contentLoading, contentError } = useAIContent(
    spaceSlug,
    recording.id,
  );

  const handleExpandCollapse = () => {
    setExpandAll(!expandAll);
  };

  const handleJump = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (contentLoading || contentError) {
    return (
      <div className="mt-6 space-y-6">
        <Skeleton className="h-8 rounded-sm" />
        {[...Array(5)].map((_, i) => (
          <div key={i} className="space-y-2">
            <Skeleton className="h-2.5 w-44 rounded-full" />
            <Skeleton className="h-36 rounded-sm" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {content!.length > 0 && (
        <div className="sticky top-0 z-10 border-b border-border bg-background py-3">
          <div className="flex flex-row items-center justify-end gap-2">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  size="sm"
                  variant="ghost"
                  className="flex h-8 items-center gap-1 bg-card"
                >
                  <CornerLeftDown size={16} />
                  Jump to
                </Button>
              </PopoverTrigger>
              <PopoverContent
                align="end"
                className="max-h-72 w-72 overflow-y-auto"
              >
                <div className="space-y-2">
                  {content?.map((contentBlock, index) => (
                    <Button
                      key={index}
                      size="sm"
                      variant="ghost"
                      className="line-clamp-1 w-full text-start"
                      onClick={() =>
                        handleJump(`prompt-${contentBlock.prompt.id}`)
                      }
                    >
                      {contentBlock.prompt.name}
                    </Button>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
            <Button
              size="sm"
              variant="ghost"
              onClick={handleExpandCollapse}
              className="flex h-8 items-center gap-1 bg-card"
            >
              {expandAll ? (
                <>
                  <ChevronUp size={16} />
                  Collapse All
                </>
              ) : (
                <>
                  <ChevronDown size={16} />
                  Expand All
                </>
              )}
            </Button>
          </div>
        </div>
      )}

      {content!.length === 0 ? (
        <div className="flex flex-row items-center justify-between rounded-sm border border-border bg-card p-3">
          <div>
            <h3 className="font-bricolage-medium text-base">
              No AI content found.
            </h3>
            <p className="text-sm text-muted-foreground">
              Generate AI content for this recording to get started. This
              usually takes a few minutes.
            </p>
          </div>
          <Button
            size="sm"
            className="flex flex-row items-center gap-2"
            onClick={onGenerateAIContent}
            isLoading={status === STATUS.LOADING}
          >
            {status !== STATUS.LOADING && (
              <WandSparkles size={16} strokeWidth={2} />
            )}
            <span>Generate</span>
          </Button>
        </div>
      ) : (
        <div className="space-y-6">
          {content?.map((contentBlock, index) => (
            <AIContentCard
              key={index}
              isExpanded={expandAll}
              aiContentBlock={contentBlock}
              seekToTimestamp={seekToTimestamp}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AIContentTable;
