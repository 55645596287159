import axios from "axios";
import Cookies from "js-cookie";

const apiInstance = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_API_URL}`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

apiInstance.interceptors.request.use((config) => {
  const csrfToken = Cookies.get(import.meta.env.VITE_CSRF_COOKIE_NAME);
  if (csrfToken) {
    config.headers["x-csrftoken"] = csrfToken;
  }
  return config;
});

interface Dictionary {
  [key: string]: string | number | boolean;
}

const baseFetcher = (url: string, params?: Dictionary) => {
  if (!params) {
    return axios.get(url).then((res) => res.data);
  }

  return axios.get(url, { params: params }).then((res) => res.data);
};

const fetcher = (url: string, params?: Dictionary) => {
  if (!params) {
    return apiInstance.get(url).then((res) => res.data);
  }

  return apiInstance.get(url, { params: params }).then((res) => res.data);
};

export { apiInstance, fetcher, baseFetcher };
