import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import Alert from "@/components/Alert/Alert";
import { LockIcon } from "lucide-react";

import { handleResetPassword } from "@/api/services/password";
import { STATUS } from "@/api/utils";

const formSchema = z.object({
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .regex(/[0-9]/, "Password must contain at least 1 number"),
  confirmPassword: z.string(),
});

type formSchema = z.infer<typeof formSchema>;

type ResetPasswordFormProps = {
  token: string;
};

const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.IDLE);

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    if (data.password !== data.confirmPassword) {
      form.setError("confirmPassword", {
        message: "Passwords do not match",
      });
      return;
    }

    setStatus(STATUS.LOADING);

    try {
      await handleResetPassword(token, data.password);
      setStatus(STATUS.SUCCESS);
      navigate("/login", {
        state: { message: "Password reset successfully." },
      });
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        {form.formState.errors.root && (
          <Alert type="error" message={form.formState.errors.root.message!} />
        )}
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your new password"
                  startIcon={LockIcon}
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Password must be at least 8 characters and contain at least 1
                uppercase letter and at least 1 number
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Repeat your password"
                  startIcon={LockIcon}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          isLoading={status === STATUS.LOADING}
          className="mt-4 w-full"
        >
          Reset Password
        </Button>
      </form>
    </Form>
  );
};

export default ResetPasswordForm;
