import moment from "moment";

export function convertSecondsToTimestamp(seconds: number) {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
}

export function timestampToSeconds(timestamp: string) {
  const [hours, minutes, seconds] = timestamp.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
