import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Menu, HelpCircle, PlayCircle, Settings, Text } from "lucide-react";

import MenuTileButton from "@/components/Button/MenuTileButton";
import UserDropdown from "@/components/Dropdown/UserDropdown";
import { sidebarElements } from "@/components/Sidebar/SidebarElements";

import { useUser } from "@/api/hooks/useUser";

type SidebarProps = {
  setShowPaywall: (show: boolean) => void;
};

const MobileSidebar = ({ setShowPaywall }: SidebarProps) => {
  const { spaceSlug } = useParams();

  const [isSpaceContext, setIsSpaceContext] = useState<boolean>(false);
  const [sheetOpen, setSheetOpen] = useState<boolean>(false);

  const { user, userLoading } = useUser();

  useEffect(() => {
    if (spaceSlug) {
      setIsSpaceContext(true);
    } else {
      setIsSpaceContext(false);
    }
  }, [spaceSlug]);

  return (
    <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
      <SheetTrigger className="flex flex-row items-center gap-2">
        <Menu size={26} />
      </SheetTrigger>
      <SheetContent
        className="flex w-full flex-col overflow-y-scroll"
        aria-describedby={undefined}
      >
        <SheetHeader>
          <SheetTitle className="text-start">Menu</SheetTitle>
          <SheetClose />
        </SheetHeader>

        <div className="flex w-full flex-grow flex-col gap-2">
          <UserDropdown
            isMobile={true}
            isCollapsed={false}
            onDropdownItemClick={() => setSheetOpen(false)}
          />
          <Separator className="my-1.5" />
          <nav className="flex h-full flex-col items-center gap-4">
            <div className="flex w-full flex-shrink-0 flex-col gap-2 rounded-sm">
              <h3 className="text-xs text-muted-foreground">Main Menu</h3>
              <div className="grid grid-cols-3 gap-3">
                {sidebarElements.map((element, index) => (
                  <MenuTileButton
                    key={index}
                    to={element.linkTo}
                    text={element.title}
                    icon={element.icon}
                    onAfterClick={() => setSheetOpen(false)}
                  />
                ))}
              </div>
            </div>
            {isSpaceContext ? (
              <div className="flex w-full flex-shrink-0 flex-col gap-2 rounded-sm">
                <h3 className="text-xs text-muted-foreground">Space Menu</h3>
                <div className="grid grid-cols-3 gap-3">
                  <MenuTileButton
                    to={`/spaces/${spaceSlug}/recordings`}
                    text="Recordings"
                    icon={<PlayCircle size={18} strokeWidth={2.5} />}
                    onAfterClick={() => setSheetOpen(false)}
                  />
                  <MenuTileButton
                    to={`/spaces/${spaceSlug}/settings`}
                    text="Settings"
                    icon={<Settings size={18} strokeWidth={2.5} />}
                    onAfterClick={() => setSheetOpen(false)}
                  />
                  <MenuTileButton
                    to={`/spaces/${spaceSlug}/prompts`}
                    text="Prompts"
                    icon={<Text size={18} strokeWidth={2.5} />}
                    onAfterClick={() => setSheetOpen(false)}
                  />
                </div>
              </div>
            ) : null}
            <div className="flex-grow"></div>
            <div className="flex w-full flex-shrink-0 flex-col gap-2">
              {!user?.subscription && !userLoading && (
                <div className="mb-2 flex flex-col gap-1.5 rounded-sm border border-border p-3">
                  <h6 className="font-bricolage-medium">Start Your Journey</h6>
                  <p className="text-sm text-muted-foreground">
                    Start your subscription now and cancel anytime — no
                    questions asked.
                  </p>
                  <Button
                    size="sm"
                    className="mt-2"
                    onClick={() => {
                      setSheetOpen(false);
                      setShowPaywall(true);
                    }}
                  >
                    Start Subscription
                  </Button>
                </div>
              )}
              <a
                target="_blank"
                href="https://nikonaskida.notion.site/Reinspire-io-Help-Quickstart-Guide-0d49fef9d21544c1971f32b76fe14f73?pvs=4"
                className="flex h-9 w-full flex-row items-center gap-2.5 rounded-sm px-3 hover:bg-secondary"
              >
                <HelpCircle
                  size={18}
                  strokeWidth={2.5}
                  className="flex-shrink-0"
                />

                <span className="overflow-hidden text-nowrap">
                  Help & Quickstart
                </span>
              </a>
            </div>
          </nav>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default MobileSidebar;
