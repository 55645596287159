import { useState } from "react";
import { cn } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { Mail } from "lucide-react";

// API
import { handleForgotPasswordRequest } from "@/api/services/password";

type ForgotPasswordCardProps = {
  email: string;
};

const ForgotPasswordCard = ({ email }: ForgotPasswordCardProps) => {
  const [isResent, setIsResent] = useState<boolean>(false);
  const { toast } = useToast();

  const resendEmail = async (email: string) => {
    try {
      await handleForgotPasswordRequest(email);
      setIsResent(true);
      toast({
        title: "Email resent successfully",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Failed to resend email",
        variant: "error",
      });
    }
  };

  return (
    <Card>
      <CardContent className="flex flex-col items-center justify-center space-y-3 p-6">
        <Mail size={96} />
        <p className="font-bricolage-medium text-2xl">
          We have sent you an email
        </p>
        <p className="text-center text-sm">
          We have just sent you an email to{" "}
          <span className="font-rubik-medium">{email}</span>. Please check your
          inbox and follow the instructions to reset your password.{" "}
          <span className="font-rubik-medium">
            Make sure to check your spam folder as well.
          </span>
        </p>
        <p className="text-xs">
          Didn't receive an email?
          <button
            disabled={isResent}
            className={cn(
              "ml-1 cursor-pointer text-xs text-primary hover:underline",
              isResent && "cursor-not-allowed text-muted-foreground",
            )}
            onClick={() => resendEmail(email)}
          >
            {isResent ? "Email resent" : "Resend email"}
          </button>
        </p>
      </CardContent>
    </Card>
  );
};

export default ForgotPasswordCard;
