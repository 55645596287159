import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import { AxiosError, AxiosResponse } from "axios";
import { mutate } from "swr";
import { mediaUrl } from "@/api/hooks/useMedia";

type RecordingPayload = {
  num_speakers: number | undefined;
  language: string;
  vocabulary?: string | undefined;
  copy_transcript?: boolean | undefined;
};

type RecordingResponse = {
  recording_id: string;
  transcript_status: TranscriptStatus;
};

export const createRecording = async (
  spaceSlug: string,
  mediaId: string,
  payload: RecordingPayload,
): Promise<RecordingResponse> => {
  try {
    const response = await apiInstance.post(
      `/spaces/${spaceSlug}/recordings?media_id=${mediaId}`,
      payload,
    );
    mutate(mediaUrl);
    mutate(`/spaces/${spaceSlug}/recordings`);
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const transcribeRecording = async (
  spaceSlug: string,
  recordingId: string,
): Promise<RecordingResponse> => {
  try {
    const response = await apiInstance.post(
      `/spaces/${spaceSlug}/recordings/${recordingId}/transcribe`,
    );
    mutate(`/spaces/${spaceSlug}/recordings/${recordingId}`);
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const deleteRecording = async (
  spaceSlug: string,
  recordingId: string,
): Promise<void> => {
  try {
    await apiInstance.delete(`/spaces/${spaceSlug}/recordings/${recordingId}`);
    mutate(
      `/spaces/${spaceSlug}/recordings`,
      (data) =>
        data.filter((recording: Recording) => recording.id !== recordingId),
      { revalidate: false },
    );
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};
