import React, { useState, KeyboardEvent, useRef, useEffect } from "react";
import { Badge } from "@/components/ui/badge";
import { X } from "lucide-react";
import { ControllerRenderProps } from "react-hook-form";

interface TagInputProps<T extends Record<string, any>> {
  field: ControllerRenderProps<T, any>;
  maxTagLength?: number;
  maxTags?: number;
  placeholder?: string;
}

const TagInput = <T extends Record<string, any>>({
  field,
  maxTagLength = 20,
  maxTags = 10,
  placeholder = "Type and press Enter...",
}: TagInputProps<T>) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [field.value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setError(null);
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      addTag(inputValue.trim());
    } else if (
      e.key === "Backspace" &&
      inputValue === "" &&
      field.value.length > 0
    ) {
      removeTag(field.value.length - 1);
    }
  };

  const addTag = (tag: string) => {
    if (tag.length > maxTagLength) {
      setError(`Item must be ${maxTagLength} characters or less`);
      return;
    }
    if (field.value.includes(tag)) {
      setError("This item already exists");
      return;
    }
    const updatedTags = [...field.value, tag];
    if (updatedTags.length <= maxTags) {
      field.onChange(updatedTags);
      setInputValue("");
      setError(null);
    } else {
      setError(`Maximum ${maxTags} items allowed`);
    }
  };

  const removeTag = (index: number) => {
    const updatedTags = field.value.filter(
      (_: string, i: number) => i !== index,
    );
    field.onChange(updatedTags);
    setError(null);
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <div className="space-y-2">
      <div
        ref={containerRef}
        className="flex flex-wrap gap-1 rounded-sm border p-1"
        onClick={focusInput}
      >
        {field.value.map((tag: string, index: number) => (
          <Badge
            key={index}
            variant="secondary"
            className="flex-shrink-0 space-x-0.5 whitespace-nowrap text-sm"
          >
            {tag}
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                removeTag(index);
              }}
              className="text-muted-foreground hover:text-black"
            >
              <X size={14} />
            </button>
          </Badge>
        ))}

        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={field.value.length === 0 ? placeholder : ""}
          className="h-7 w-20 flex-grow rounded-sm !border-0 bg-transparent px-2 text-sm outline-none !ring-0"
        />
      </div>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  );
};

export default TagInput;
