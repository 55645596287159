import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { initializePaddle, Paddle } from "@paddle/paddle-js";

interface AppContextType {
  paddle: Paddle | undefined;
}

const AppContext = createContext<AppContextType | null>(null);

type AppContextProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppContextProviderProps) => {
  const [paddle, setPaddle] = useState<Paddle | undefined>();

  const isProduction =
    import.meta.env.VITE_DEBUG_MODE === "false" &&
    import.meta.env.VITE_ENVIRONMENT === "production";
  const paddleEnvironment = isProduction ? "production" : "sandbox";
  const paddleToken = import.meta.env.VITE_PADDLE_CLIENT_TOKEN;

  useEffect(() => {
    initializePaddle({
      environment: paddleEnvironment,
      token: paddleToken,
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  return (
    <AppContext.Provider value={{ paddle: paddle }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within an AuthProvider");
  }
  return context;
};
