import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import { AxiosError, AxiosResponse } from "axios";
import { mutate, MutatorCallback } from "swr";
import { spacesUrl } from "@/api/hooks/useSpaces";

type SpacePayload = {
  emoji: string;
  name: string;
  language: string;
  context_prompt: string;
  brand_voice_id: number | null;
  preset_id: number | null;
};

export const createSpace = async (
  payload: SpacePayload,
): Promise<SpacePreview> => {
  try {
    const response = await apiInstance.post("/spaces", payload);
    mutate(spacesUrl, async (data) => [response.data, ...data], {
      revalidate: false,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const editSpace = async (
  spaceSlug: string,
  payload: SpacePayload,
): Promise<Space> => {
  try {
    const response = await apiInstance.put(`/spaces/${spaceSlug}`, payload);
    mutate(`${spacesUrl}/${spaceSlug}`, response.data, {
      revalidate: false,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const deleteSpace = async (spaceSlug: string): Promise<void> => {
  try {
    await apiInstance.delete(`/spaces/${spaceSlug}`);
    mutate(
      spacesUrl,
      async (data) => {
        data.filter((space: Space) => space.slug !== spaceSlug);
      },
      {
        revalidate: false,
      },
    );
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

// Space prompts
type SpacePromptPayload = {
  name: string;
  prompt: string;
};

export const createSpacePrompt = async (
  spaceSlug: string,
  payload: SpacePromptPayload,
): Promise<SpacePrompt> => {
  try {
    const response = await apiInstance.post(
      `/spaces/${spaceSlug}/prompts`,
      payload,
    );
    mutate(
      `${spacesUrl}/${spaceSlug}/prompts`,
      async (data) => {
        return [...data, response.data];
      },
      {
        revalidate: false,
      },
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const editSpacePrompt = async (
  spaceSlug: string,
  promptId: number,
  payload: SpacePromptPayload,
): Promise<SpacePrompt> => {
  try {
    const response = await apiInstance.put(
      `/spaces/${spaceSlug}/prompts/${promptId}`,
      payload,
    );
    const mutationRule: MutatorCallback = (
      data: SpacePrompt[],
    ): SpacePrompt[] => {
      return data.map((prompt) => {
        if (prompt.id === promptId) {
          return response.data;
        }
        return prompt;
      });
    };
    mutate(`${spacesUrl}/${spaceSlug}/prompts`, mutationRule, {
      revalidate: false,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

export const deleteSpacePrompt = async (
  spaceSlug: string,
  promptId: number,
): Promise<void> => {
  try {
    await apiInstance.delete(`/spaces/${spaceSlug}/prompts/${promptId}`);
    mutate(
      `${spacesUrl}/${spaceSlug}/prompts`,
      (data) => data.filter((prompt: SpacePrompt) => prompt.id !== promptId),
      {
        revalidate: false,
      },
    );
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

// Space speakers
type CreateSpaceSpeakerPayload = {
  assigned_label: string;
  recording_id: string;
  transcript_chunk_id: string;
  assign_to_all: boolean;
};

export const createSpaceSpeaker = async (
  spaceSlug: string,
  payload: CreateSpaceSpeakerPayload,
): Promise<SpaceSpeaker> => {
  try {
    const response = await apiInstance.post(
      `${spacesUrl}/${spaceSlug}/speakers`,
      payload,
    );

    // Mutate the speakers list
    const mutationRule: MutatorCallback = async (
      data: SpaceSpeaker[],
    ): Promise<SpaceSpeaker[]> => {
      return [...data, response.data];
    };
    mutate(`${spacesUrl}/${spaceSlug}/speakers`, mutationRule, {
      revalidate: false,
    });
    // Mutate the recording transcript chunk
    mutate(`${spacesUrl}/${spaceSlug}/recordings/${payload.recording_id}`);

    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};

type AssignSpaceSpeakerPayload = {
  recording_id: string;
  transcript_chunk_id: string;
  assign_to_all: boolean;
};

export const assignSpaceSpeaker = async (
  spaceSlug: string,
  speakerId: number,
  payload: AssignSpaceSpeakerPayload,
): Promise<SpaceSpeaker> => {
  try {
    const response = await apiInstance.post(
      `${spacesUrl}/${spaceSlug}/speakers/${speakerId}/assign`,
      payload,
    );

    // Mutate the recording transcript chunk
    mutate(`${spacesUrl}/${spaceSlug}/recordings/${payload.recording_id}`);

    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};
