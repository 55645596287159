import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { deleteSpace } from "@/api/services/space";

type DeleteSpaceFormProps = {
  space: Space;
};

const DeleteSpaceForm = ({ space }: DeleteSpaceFormProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [spaceNameInput, setSpaceNameInput] = useState<string>("");
  const [spaceName] = useState<string>(space.name);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Reset the values when the dialog is closed
    setStatus(STATUS.IDLE);
    setSpaceNameInput("");
    setError(null);
  }, [dialogOpen]);

  const handleDeleteSpace = async () => {
    setStatus(STATUS.LOADING);

    if (spaceNameInput !== spaceName) {
      setError("Space name doesn't match");
      setStatus(STATUS.ERROR);
      return;
    }

    try {
      await deleteSpace(space.slug);
      setDialogOpen(false);
      setStatus(STATUS.SUCCESS);
      navigate("/spaces", { replace: true });
      toast({
        title: "Space deleted successfully",
        variant: "success",
      });
    } catch (error) {
      setError((error as Error).message);
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <div className="rounded-sm border-2 border-destructive p-4">
      <div className="flex w-full flex-col justify-between gap-4 md:flex-row md:items-center">
        <div className="flex flex-col">
          <h3 className="font-bricolage-medium">Delete Space</h3>
          <p className="text-sm text-muted-foreground">
            Deleting your space will permanently remove all data and content
            associated with this space.
          </p>
        </div>
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogTrigger asChild>
            <Button variant="destructive">Delete Space</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Are you sure you want to delete this space?
              </DialogTitle>
              <DialogDescription>
                This action is irreversible and cannot be undone later.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col space-y-1">
              {status === STATUS.ERROR && (
                <Alert type="error" message={error!} className="mb-2" />
              )}
              <small>
                Type "<b className="font-rubik-medium">{spaceName}</b>" below to
                confirm
              </small>
              <Input
                value={spaceNameInput}
                onChange={(e) => setSpaceNameInput(e.target.value)}
                placeholder="Enter space name"
              />
            </div>
            <DialogFooter className="gap-2">
              <Button
                variant="outline"
                onClick={() => setDialogOpen(false)}
                disabled={status === STATUS.LOADING}
                className="min-w-20"
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={handleDeleteSpace}
                isLoading={status === STATUS.LOADING}
                disabled={spaceNameInput !== spaceName}
                className="min-w-20"
              >
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default DeleteSpaceForm;
