import useSwr from "swr";
import { fetcher } from "@/api/index";

import { spacesUrl } from "@/api/hooks/useSpaces";

export const usePrompts = (spaceSlug: string) => {
  const { data, error, isLoading } = useSwr<SpacePrompt[], Error>(
    `${spacesUrl}/${spaceSlug}/prompts`,
    fetcher,
  );

  return {
    prompts: data,
    promptsError: error,
    promptsLoading: isLoading,
  };
};
