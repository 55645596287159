import NewRecordingDialog from "@/views/Recordings/components/Dialog/NewRecordingDialog";
import RecordingDataTable from "@/views/Recordings/components/Table/RecordingDataTable";

const Recordings = () => {
  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h1 className="font-bricolage-semibold text-2xl md:text-3xl">
          Recordings
        </h1>
        <NewRecordingDialog />
      </div>
      <div className="space-y-16 py-4">
        <RecordingDataTable />
      </div>
    </div>
  );
};

export default Recordings;
