import { Progress } from "@/components/ui/progress";
import { Loader2, Check } from "lucide-react";
import { formatFileSize } from "@/lib/file";

interface FileItemProps {
  file: {
    name: string;
    size: number;
    type: string;
    progress: number;
    error?: string;
  };
}

const MediaDropzoneItem = (props: FileItemProps) => {
  const { file } = props;

  return (
    <li className="rounded-sm border border-border p-3">
      <div className="flex items-center justify-between gap-4">
        <div className="flex-grow">
          <p className="line-clamp-1 text-sm font-medium">{file.name}</p>
          <div className="flex flex-row items-center gap-4 text-xs">
            <p className="flex-shrink-0 text-muted-foreground">
              {formatFileSize(file.size)}
            </p>
            {file.progress < 0 ? (
              <span className="text-xs text-destructive">
                {file.error || "upload failed"}
              </span>
            ) : file.progress === 0 ? (
              <span className="flex flex-row items-center gap-2 text-muted-foreground">
                waiting for upload to start{" "}
                <Loader2
                  size={12}
                  strokeWidth={2.5}
                  className="animate-spin text-primary"
                />
              </span>
            ) : file.progress === 100 ? (
              <span className="flex flex-row items-center gap-2 text-green-500">
                <Check size={14} strokeWidth={2.5} />
              </span>
            ) : (
              <Progress
                value={file.progress}
                className="h-1.5 rounded-full bg-border"
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default MediaDropzoneItem;
