import { useState, useEffect, useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { DialogClose, DialogFooter } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { motion } from "framer-motion";
import { BarLoader } from "react-spinners";
import {
  TextCursorInput,
  Link,
  Newspaper,
  Info,
  AppWindowMac,
} from "lucide-react";

import LogoUrl from "@/assets/images/logo-square.png";
import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import {
  analyzeBrandVoice,
  AnalyzedBrandVoiceResponse,
} from "@/api/services/brandVoice";

const formSchema = z
  .object({
    website_url: z.string().url().optional(),
    text_content: z
      .string()
      .min(1, {
        message: "Text content is required",
      })
      .max(10000, {
        message: "Text content must be at most 10,000 characters",
      })
      .optional(),
  })
  .refine((data) => !!data.website_url || !!data.text_content);

type formSchema = z.infer<typeof formSchema>;

type AnalyzeForVoiceFormProps = {
  setBrandVoice: (brandVoice: AnalyzedBrandVoiceResponse) => void;
  setStep: (step: "analyze" | "edit") => void;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AnalyzeForVoiceForm = (props: AnalyzeForVoiceFormProps) => {
  const { setBrandVoice, setStep, setDialogOpen } = props;
  const [activeTab, setActiveTab] = useState<"url" | "text">("url");
  const [status, setStatus] = useState(STATUS.IDLE);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const form = useForm({
    shouldUnregister: true,
    resolver: zodResolver(formSchema),
    defaultValues: {
      website_url: "",
      text_content: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = useCallback(
    async (data) => {
      setStatus(STATUS.LOADING);
      const controller = new AbortController();
      setAbortController(controller);

      try {
        const result = await analyzeBrandVoice(data, controller);
        if (!controller.signal.aborted) {
          setBrandVoice(result);
          setStatus(STATUS.SUCCESS);
          setStep("edit");
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          setStatus(STATUS.ERROR);
          form.setError("root", { message: (error as Error).message });
        }
      } finally {
        setAbortController(null);
      }
    },
    [setBrandVoice, setStep],
  );

  useEffect(() => {
    return () => abortController?.abort();
  }, [abortController]);

  const onCancel = useCallback(() => {
    abortController?.abort();
    setDialogOpen(false);
    setStatus(STATUS.IDLE);
    form.setError("root", { message: "" });
  }, [setDialogOpen, abortController]);

  if (status === STATUS.LOADING) {
    return (
      <div className="flex h-64 flex-col items-center justify-center">
        <motion.div
          animate={{
            rotate: 360,
            y: [0, -15, 0],
          }}
          transition={{
            rotate: { duration: 2, repeat: Infinity, ease: "linear" },
            y: {
              duration: 0.6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
            },
          }}
        >
          <img src={LogoUrl} alt="Reinspire" className="size-14" />
        </motion.div>
        <p className="mt-8 text-center text-sm text-muted-foreground">
          <BarLoader
            color="#0098ea"
            height={5}
            width={160}
            className="rounded"
          />
        </p>
        <Button
          size="sm"
          variant="outline"
          className="mt-8 px-4"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <div className="relative flex border-b border-border text-sm">
          <button
            className={`flex flex-1 flex-row items-center justify-center gap-2 p-2 ${
              activeTab === "url" ? "text-primary" : "text-muted-foreground"
            }`}
            onClick={() => setActiveTab("url")}
          >
            <Link size={16} strokeWidth={2} />
            Link Website
          </button>
          <button
            className={`flex flex-1 flex-row items-center justify-center gap-2 p-2 ${
              activeTab === "text" ? "text-primary" : "text-muted-foreground"
            }`}
            onClick={() => setActiveTab("text")}
          >
            <TextCursorInput size={16} strokeWidth={2} />
            Content Sample
          </button>
          <motion.div
            className="absolute bottom-0 h-0.5 bg-primary"
            initial={false}
            animate={{
              left: activeTab === "url" ? "0%" : "50%",
              width: "50%",
            }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          />
        </div>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          {activeTab === "url" && (
            <div>
              {status === STATUS.ERROR && (
                <Alert
                  type="error"
                  message={form.formState.errors.root!.message!}
                  className="mb-2"
                />
              )}
              <FormField
                control={form.control}
                name="website_url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Website URL to pull content from to analyze
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="https://reinpire.io" {...field} />
                    </FormControl>
                    {form.formState.errors.website_url ? (
                      <FormMessage />
                    ) : (
                      <FormDescription>
                        About page, blog post, or any other page with text
                        content to analyze
                      </FormDescription>
                    )}
                  </FormItem>
                )}
              />
              <div className="mt-8 space-y-4">
                <p className="text-sm">
                  Good candidates for the website to create a brand voice from:
                </p>
                <div className="grid grid-cols-3 gap-2 sm:gap-4 text-sm">
                  <div className="flex h-24 flex-col items-center justify-center gap-2 rounded-sm border border-border font-bricolage-regular">
                    <AppWindowMac
                      size={30}
                      strokeWidth={2.5}
                      className="text-primary"
                    />
                    <span>Landing page</span>
                  </div>
                  <div className="flex h-24 flex-col items-center justify-center gap-2 rounded-sm border border-border font-bricolage-regular">
                    <Info
                      size={30}
                      strokeWidth={2.5}
                      className="text-primary"
                    />
                    <span>About page</span>
                  </div>
                  <div className="flex h-24 flex-col items-center justify-center gap-2 rounded-sm border border-border font-bricolage-regular">
                    <Newspaper
                      size={30}
                      strokeWidth={2.5}
                      className="text-primary"
                    />
                    <span>Blog post</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "text" && (
            <div>
              {status === STATUS.ERROR && (
                <Alert
                  type="error"
                  message={form.formState.errors.root!.message!}
                  className="mb-2"
                />
              )}
              <FormField
                control={form.control}
                name="text_content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Content sample to analyze</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        maxLength={10000}
                        rows={9}
                        className="resize-none"
                        placeholder="Enter text content..."
                      />
                    </FormControl>
                    {form.formState.errors.text_content ? (
                      <FormMessage {...field} />
                    ) : (
                      <FormDescription {...field}>
                        Maximum 10,000 characters
                      </FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
          )}
          <DialogFooter className="gap-2">
            <DialogClose asChild>
              <Button variant="outline" className="min-w-20">
                Close
              </Button>
            </DialogClose>
            <Button type="submit" className="min-w-20">
              Analyze Voice
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </div>
  );
};

export default AnalyzeForVoiceForm;
