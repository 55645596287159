import {
  MediaPlayer,
  MediaProvider,
  PlayButton,
  SeekButton,
  TimeSlider,
  Time,
  VolumeSlider,
  Title,
  MediaPlayerInstance,
} from "@vidstack/react";
import { useMediaStore } from "@vidstack/react";
import {
  Play,
  Pause,
  RotateCcw,
  RotateCw,
  VolumeX,
  Volume1,
  Volume2,
} from "lucide-react";

type RecordingAudioPlayerProps = {
  recording: Recording;
  player: React.RefObject<MediaPlayerInstance>;
};

const RecordingAudioPlayer = ({
  recording,
  player,
}: RecordingAudioPlayerProps) => {
  const { volume } = useMediaStore(player);

  return (
    <div className="fixed bottom-0 left-0 right-0 h-[72px] border-t border-border bg-card lg:left-72">
      <MediaPlayer
        ref={player}
        viewType="audio"
        title={recording!.media.title}
        src={{
          src: recording!.media.file.src_url,
          type: "audio/mpeg",
        }}
        className="flex h-full items-center justify-center"
      >
        <MediaProvider />

        <div className="flex w-full flex-col items-center justify-center gap-0.5 px-4 md:w-3/4 md:px-0">
          <div className="grid w-full grid-cols-3 gap-8">
            <div className="hidden items-center justify-start md:flex">
              <Title className="line-clamp-1 text-sm" />
            </div>
            <div className="col-span-3 flex items-center justify-center gap-2.5 md:col-span-1">
              <SeekButton
                seconds={-10}
                className="group relative inline-flex size-8 cursor-pointer items-center justify-center rounded-md outline-none ring-inset ring-primary transition-transform hover:scale-105 active:scale-[0.98]"
              >
                <RotateCcw size={20} />
                <span className="absolute font-bricolage-medium text-[7px] text-muted-foreground">
                  10
                </span>
              </SeekButton>

              <PlayButton className="group relative inline-flex size-8 cursor-pointer items-center justify-center rounded-md outline-none ring-inset ring-primary transition-transform hover:scale-105 active:scale-[0.98] data-[focus]:ring-4">
                <span className="flex size-7 items-center justify-center rounded-full bg-primary">
                  <Play
                    size={16}
                    fill="#ffffff"
                    className="hidden text-white group-data-[paused]:block"
                  />
                  <Pause
                    size={16}
                    fill="#ffffff"
                    className="text-white group-data-[paused]:hidden"
                  />
                </span>
              </PlayButton>

              <SeekButton
                seconds={10}
                className="group relative inline-flex size-8 cursor-pointer items-center justify-center rounded-md outline-none ring-inset ring-primary transition-transform hover:scale-105 active:scale-[0.98]"
              >
                <RotateCw size={20} />
                <span className="absolute font-bricolage-medium text-[7px] text-muted-foreground">
                  10
                </span>
              </SeekButton>
            </div>
            <div className="hidden items-center justify-end md:flex">
              <div className="flex w-full max-w-32 items-center gap-2">
                {volume === 0 ? (
                  <VolumeX size={20} />
                ) : volume < 0.5 ? (
                  <Volume1 size={20} />
                ) : (
                  <Volume2 size={20} />
                )}
                <VolumeSlider.Root className="group relative inline-flex w-full cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden">
                  <VolumeSlider.Track className="relative z-0 h-[5px] w-full rounded-full bg-secondary ring-primary group-data-[focus]:ring-[3px]">
                    <VolumeSlider.TrackFill className="absolute h-full w-[var(--slider-fill)] rounded-full bg-primary will-change-[width]" />
                  </VolumeSlider.Track>
                  <VolumeSlider.Thumb className="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4" />
                </VolumeSlider.Root>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-row gap-2">
            <Time
              className="time flex w-14 flex-shrink-0 items-center justify-start font-bricolage-medium text-xs text-muted-foreground"
              type="current"
              showHours={true}
              padHours={true}
              padMinutes={true}
            />
            <TimeSlider.Root
              step={0.01}
              className="group relative inline-flex flex-grow cursor-pointer touch-none select-none items-center rounded-full outline-none aria-hidden:hidden"
            >
              <TimeSlider.Track className="relative z-0 h-[5px] w-full rounded-full bg-secondary ring-primary group-data-[focus]:ring-[3px]">
                <TimeSlider.TrackFill className="absolute z-10 h-full w-[var(--slider-fill)] rounded-full bg-primary will-change-[width]" />
                <TimeSlider.Progress className="absolute h-full w-[var(--slider-progress)] rounded-full bg-muted will-change-[width]" />
              </TimeSlider.Track>
              <TimeSlider.Thumb className="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-border bg-white opacity-0 ring-white/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4" />
            </TimeSlider.Root>
            <Time
              className="time flex w-14 flex-shrink-0 items-center justify-end font-bricolage-medium text-xs text-muted-foreground"
              type="duration"
              showHours={true}
              padHours={true}
              padMinutes={true}
            />
          </div>
        </div>
      </MediaPlayer>
    </div>
  );
};

export default RecordingAudioPlayer;
