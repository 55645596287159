import useSwr from "swr";
import { fetcher } from "@/api/index";

export const brandVoicesUrl = "/brand-voices";

export const useBrandVoices = () => {
  const { data, error, isLoading } = useSwr<BrandVoice[], Error>(
    brandVoicesUrl,
    fetcher,
  );

  return {
    brandVoices: data,
    brandVoicesError: error,
    brandVoicesLoading: isLoading,
  };
};
