import React, { useState, useEffect } from "react";
import { useParams, ScrollRestoration } from "react-router-dom";
import { Crisp } from "crisp-sdk-web";
import { Toaster } from "@/components/ui/toaster";
import { useAppContext } from "@/app/AppContextProvider";

import Sidebar from "@/components/Sidebar/Sidebar";
import MobileHeader from "@/components/Header/MobileHeader";

import { useUser } from "@/api/hooks/useUser";
import Paywall from "./components/Paywall";

type CoreLayoutProps = {
  children: React.ReactNode;
};

const CoreLayout = ({ children }: CoreLayoutProps) => {
  Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID);
  const { user } = useUser(); // Preload user data
  const { paddle } = useAppContext();

  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
  const [showPaywall, setShowPaywall] = useState<boolean>(false);

  const { spaceSlug } = useParams();
  const spaceSlugExists = spaceSlug !== undefined;

  useEffect(() => {
    if (!user) return;

    // Set Crisp user data
    Crisp.setTokenId(user.id);
    Crisp.user.setEmail(user.email);
    Crisp.user.setNickname(user.full_name);
    Crisp.chat.show();

    // Check if paywall has been shown this session
    const paywallShown = sessionStorage.getItem("paywallShown");

    // Show Paywall if user is not subscribed and paywall hasn't been shown
    if (!user.subscription && !paywallShown) {
      setShowPaywall(true);
      sessionStorage.setItem("paywallShown", "true");
    }
  }, [user]);

  useEffect(() => {
    if (!user || !paddle) return;

    // Initialize Paddle Retain
    const paddleToken = import.meta.env.VITE_PADDLE_CLIENT_TOKEN;
    paddle.Initialize({
      token: paddleToken,
      pwCustomer: { email: user.email },
    });
  }, [user, paddle]);

  useEffect(() => {
    setSidebarCollapsed(spaceSlugExists);
  }, [spaceSlugExists]);

  return (
    <>
      <ScrollRestoration />
      <MobileHeader setShowPaywall={setShowPaywall} />
      <div className="relative mx-auto mt-14 flex min-h-screen w-[100vw] flex-row lg:mt-0">
        <Sidebar
          isCollapsed={sidebarCollapsed}
          setShowPaywall={setShowPaywall}
        />
        {children}
      </div>
      <Paywall showPaywall={showPaywall} setShowPaywall={setShowPaywall} />
      <Toaster />
    </>
  );
};

export default CoreLayout;
