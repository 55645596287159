import { useEffect } from "react";
import { mutate } from "swr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { CheckCircle2, Loader2 } from "lucide-react";
import { useAuth } from "@/views/Auth/AuthProvider";
import { isWithinXHours } from "@/lib/date";

import FetchError from "@/components/Error/FetchError";

import { usersMeUrl } from "@/api/hooks/useUser";
import {
  useSubscription,
  userSubscriptionUrl,
} from "@/api/hooks/useSubscription";

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { refreshUser } = useAuth();
  const { subscription, subscriptionLoading, subscriptionError } =
    useSubscription();

  const isSuccessful = searchParams.get("success") === "true";

  const mutateUserData = () => {
    mutate(userSubscriptionUrl);
    mutate(usersMeUrl);
    refreshUser();
  };

  const navigateBilling = () => {
    navigate("/account/billing", { replace: true });
  };

  useEffect(() => {
    if (!subscription) return;

    // Navigate to billing page if no success param
    if (!isSuccessful) navigateBilling();

    // Early return if subscription is active and is a new subscription
    console.log(isWithinXHours(subscription?.created_at, 1));
    if (subscription?.status === "active") return;

    // Mutate subscription every 5 sec until we get active subscription
    const timer = setTimeout(() => {
      mutate(userSubscriptionUrl);
    }, 5000);

    // mutate user data on page leave
    return () => {
      clearTimeout(timer);
      mutateUserData();
    };
  }, [subscription, isSuccessful]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 py-16">
      {subscriptionLoading ? (
        <Loader2
          size={40}
          strokeWidth={2}
          className="animate-spin text-primary"
        />
      ) : subscriptionError ? (
        <FetchError />
      ) : (
        <>
          <CheckCircle2
            size={84}
            strokeWidth={2}
            className="text-background"
            fill="#16a34a"
          />
          <h1 className="font-bricolage-medium text-xl">
            Subscription successful!
          </h1>
          <p className="w-full text-center text-sm text-muted-foreground sm:w-3/5">
            Thank you for subscribing! You can now start using the app. You will
            also receive an email confirmation with details shortly.
          </p>
          <Button className="mt-4 w-44" onClick={navigateBilling}>
            Go to Billing
          </Button>
        </>
      )}
    </div>
  );
};

export default CheckoutSuccess;
