import { FileVideo, FileVolume, FileText, File } from "lucide-react";
import { SiYoutube } from "@icons-pack/react-simple-icons";

const SourceIcon = {
  youtube: <SiYoutube size={16} color="default" />,
};

const TypeIcon = {
  video: <FileVideo size={16} strokeWidth={2.5} />,
  audio: <FileVolume size={16} strokeWidth={2.5} />,
  text: <FileText size={16} strokeWidth={2.5} />,
  other: <File size={16} strokeWidth={2.5} />,
};

type MediaIconProps = {
  source: string;
};

const MediaIcon = ({ source }: MediaIconProps) => {
  return (
    <span>
      {SourceIcon[source as keyof typeof SourceIcon] ||
        TypeIcon[source as keyof typeof TypeIcon]}
    </span>
  );
};

export default MediaIcon;
