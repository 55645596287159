import { apiInstance } from "@/api/index";
import { AxiosError, AxiosResponse } from "axios";
import { removeSessionCookie } from "@/lib/auth/cookie";

// Login
const LoginBadRequest: Record<string, string> = {
  LOGIN_BAD_CREDENTIALS: "Invalid email or password",
  LOGIN_USER_NOT_VERIFIED: "Email not verified. Verify your email to proceed",
};

type LoginData = {
  email: string;
  password: string;
};

export const handleEmailLogin = async (data: LoginData) => {
  const formData = new FormData();

  formData.set("username", data.email);
  formData.set("password", data.password);

  await apiInstance
    .post("/auth/login", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((err) => {
      const error = err as AxiosError;
      const response = error.response as AxiosResponse;

      if (!response && error.request) {
        throw new Error("Network error");
      }

      if (response.status === 400) {
        throw new Error(LoginBadRequest[response.data.detail]);
      } else if (response.status === 429) {
        throw new Error("Too many requests. Try again later.");
      }

      throw new Error("Something went wrong. Try again.");
    });
};

// Registration
const RegisterBadRequest: Record<string, string> = {
  REGISTER_USER_ALREADY_EXISTS: "User already exists",
  REGISTER_INVALID_PASSWORD: "Invalid password",
  REGISTER_DISPOSABLE_EMAIL: "Disposable email not allowed",
};

type RegisterData = {
  full_name: string;
  email: string;
  password: string;
};

export const handleEmailRegister = async (data: RegisterData) => {
  try {
    const response = await apiInstance.post("/auth/register", {
      full_name: data.full_name,
      email: data.email,
      password: data.password,
    });

    return response;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    }

    if (response.status === 400) {
      if (typeof response.data.detail === "string") {
        throw new Error(RegisterBadRequest[response.data.detail]);
      }

      throw new Error(RegisterBadRequest[response.data.detail.code]);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    }

    throw new Error("Something went wrong. Try again.");
  }
};

export const handleLogout = async () => {
  await apiInstance.post("/auth/logout").catch(() => {
    throw new Error("Logout failed");
  });
  removeSessionCookie();
};

export default handleLogout;
