import { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

import ResetPasswordForm from "@/views/Auth/components/Form/ResetPasswordForm";

import { STATUS } from "@/api/utils";

import LogoUrl from "@/assets/images/logo-square.png";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const [status, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    if (!token) {
      setStatus(STATUS.ERROR);
    }
  }, [token]);

  if (status === STATUS.ERROR) {
    return (
      <div className="space-y-4 text-start">
        <h1 className="font-bricolage-semibold text-2xl">Invalid Link</h1>
        <p className="text-muted-foreground">
          The link you followed is invalid or has expired.
        </p>
        <Button>
          <Link to="/forgot-password">Request a new password reset</Link>
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-4 text-start">
      <div className="space-y-2">
        <img src={LogoUrl} alt="Reinspire logo" className="size-14" />
        <h1 className="font-bricolage-semibold text-2xl">Reset Password</h1>
        <p className="text-muted-foreground">Enter your new password below</p>
      </div>
      <ResetPasswordForm token={token!} />
    </div>
  );
};

export default ResetPassword;
