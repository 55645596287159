import { AxiosError } from "axios";
import { useParams, Outlet, Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

import Sidebar from "@/views/Spaces/components/Sidebar/Sidebar";

import { useSpace } from "@/api/hooks/useSpaces";

const SpaceLayout = () => {
  const { spaceSlug } = useParams();
  if (!spaceSlug) throw new Error("Space slug is required");

  const { space, spaceLoading, spaceError } = useSpace(spaceSlug);

  // If the space does not exist, show a 404 page
  if (spaceError) {
    const error = spaceError as AxiosError;
    if ([404, 422].includes(error.response?.status as number)) {
      return (
        <div className="flex w-full flex-col items-center justify-center px-8 py-8 md:ml-16 md:px-16">
          <h2 className="text-2xl font-bold">Space not found</h2>
          <p className="mt-4 text-center text-sm text-muted-foreground">
            The space you are looking for does not exist.
          </p>
          <Button size="sm" asChild className="mt-4">
            <Link to="/spaces">Go back to spaces</Link>
          </Button>
        </div>
      );
    }
  }

  return (
    <>
      <Sidebar
        space={space}
        spaceLoading={spaceLoading}
        spaceError={spaceError as AxiosError}
      />
      <div className="relative w-[100vw] px-4 py-8 lg:ml-72 lg:px-16">
        <Outlet />
      </div>
    </>
  );
};

export default SpaceLayout;
