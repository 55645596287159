import { z } from "zod";

const LAST_LOGIN_KEY = "last_login_with";

const LoginMethodSchema = z.union([z.literal("google"), z.string().email()]);

export const getLastLogin = () => {
  return localStorage.getItem(LAST_LOGIN_KEY);
};

export const setLastLogin = (method: string) => {
  try {
    const validatedMethod = LoginMethodSchema.parse(method);
    localStorage.setItem(LAST_LOGIN_KEY, validatedMethod);
  } catch (error) {
    if (error instanceof z.ZodError) {
      throw new Error(`Invalid login method: ${method}`);
    }
    throw error;
  }
};
