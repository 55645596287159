import { MoonLoader } from "react-spinners";

type LoaderProps = {
  size?: number;
};

const Loader = (props: LoaderProps) => {
  const { size } = props;

  return (
    <div className="flex w-full items-center justify-center py-4">
      <MoonLoader color="#0098ea" size={size || 30} />
    </div>
  );
};

export default Loader;
