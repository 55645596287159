import { apiInstance } from "@/api/index";
import { AxiosError, AxiosResponse } from "axios";

const BadRequest: Record<string, string> = {
  RESET_PASSWORD_BAD_TOKEN: "Invalid password reset token",
  RESET_PASSWORD_INVALID_PASSWORD: "Invalid password",
};

export const handleForgotPasswordRequest = async (email: string) => {
  await apiInstance
    .post("/auth/forgot-password", { email: email })
    .catch(() => {
      throw new Error("Something went wrong. Try again.");
    });
};

export const handleResetPassword = async (token: string, password: string) => {
  try {
    const response = await apiInstance.post("/auth/reset-password", {
      token: token,
      password: password,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    }

    if (response.status === 400) {
      if (typeof response.data.detail === "string") {
        throw new Error(BadRequest[response.data.detail]);
      }

      throw new Error(BadRequest[response.data.detail.code]);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    }

    throw new Error("Something went wrong. Try again.");
  }
};
