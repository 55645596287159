import axiosRetry from "axios-retry";
import { apiInstance } from "@/api/index";
import { mutate } from "swr";
import { AxiosError, AxiosResponse } from "axios";

import { usersMeUrl } from "@/api/hooks/useUser";

export const getUser = async () => {
  axiosRetry(apiInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
  });

  const response = await apiInstance.get(usersMeUrl);
  return response.data;
};

const BadRequest: Record<string, string> = {
  UPDATE_USER_EMAIL_ALREADY_EXISTS: "User with this email already exists",
  UPDATE_USER_INVALID_PASSWORD: "Invalid password",
  UPDATE_USER_INCORRECT_PASSWORD: "Current password is incorrect",
  UPDATE_USER_PASSWORDS_MATCH:
    "New password cannot be the same as the current password",
};

type updateUserProps = {
  full_name?: string;
  current_password?: string;
  password?: string;
};

export const updateUser = async (data: updateUserProps) => {
  const actionUrl = "/users/me";

  if (!data.full_name && !data.current_password && !data.password) {
    throw new Error("No data provided");
  }

  try {
    const response = await apiInstance.patch(actionUrl, data);
    mutate(actionUrl);
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    }

    if (response.status === 400) {
      throw new Error(BadRequest[response.data.detail]);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    }

    throw new Error("Something went wrong. Try again.");
  }
};
