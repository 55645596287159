import useSwr from "swr";
import { fetcher } from "@/api/index";

export const usageUrl = "/usage";

export const useUsage = () => {
  const { data, error, isLoading } = useSwr<UserUsage, Error>(
    usageUrl,
    fetcher,
  );

  return {
    usage: data,
    usageError: error,
    usageLoading: isLoading,
  };
};
