import { useAppContext } from "@/app/AppContextProvider";

import UserSubscription from "@/views/Account/components/Section/UserSubscription";
import BillingPlans from "@/views/Account/components/Section/BillingPlans";

import { useSubscription } from "@/api/hooks/useSubscription";

const Billing = () => {
  const { paddle } = useAppContext();

  const { subscription, subscriptionLoading } = useSubscription();

  return (
    <div className="max-w-full space-y-16">
      <UserSubscription />
      <BillingPlans
        subscription={subscription}
        subscriptionLoading={subscriptionLoading}
        paddle={paddle}
      />
    </div>
  );
};

export default Billing;
