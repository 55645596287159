import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { HelpCircle } from "lucide-react";
import { cn } from "@/lib/utils";
import LogoUrl from "@/assets/images/logo-square.png";

import MenuButton from "@/components/Button/MenuButton";
import UserDropdown from "@/components/Dropdown/UserDropdown";
import SidebarAlert from "@/components/Alert/SidebarAlert";
import { sidebarElements } from "@/components/Sidebar/SidebarElements";

type SidebarProps = {
  isCollapsed: boolean;
  setShowPaywall: (show: boolean) => void;
};

const Sidebar = ({ isCollapsed, setShowPaywall }: SidebarProps) => {
  const sidebarVariants = {
    expanded: { width: "16rem" },
    collapsed: { width: "4rem" },
  };

  return (
    <motion.aside
      className="fixed bottom-0 left-0 top-0 z-10 hidden flex-shrink-0 flex-col overflow-hidden border-r border-border bg-card lg:flex"
      initial="expanded"
      animate={isCollapsed ? "collapsed" : "expanded"}
      variants={sidebarVariants}
      transition={{ duration: 0.1, ease: "easeInOut" }}
    >
      <div className="flex h-16 min-h-16 min-w-16 items-center p-3.5">
        <Link to="/" className="flex flex-row items-center gap-2 text-black">
          <img src={LogoUrl} alt="Reinspire" className="size-9" />
          <h1
            className={cn(
              "font-bricolage-semibold text-2xl",
              isCollapsed ? "hidden" : "block",
            )}
          >
            Reinspire
          </h1>
        </Link>
      </div>

      <Separator />

      <nav
        className={cn(
          "flex h-full flex-col items-center gap-1.5 py-4",
          isCollapsed ? "px-3" : "px-4",
        )}
      >
        <div className="flex w-full flex-grow flex-col gap-1.5">
          {sidebarElements.map((element, index) => (
            <MenuButton
              key={index}
              to={element.linkTo}
              text={element.title}
              startContent={element.icon}
              isCollapsed={isCollapsed}
            />
          ))}
        </div>

        <div className="flex w-full flex-shrink-0 flex-col gap-1.5">
          <SidebarAlert
            setShowPaywall={setShowPaywall}
            isCollapsed={isCollapsed}
          />
          <a
            target="_blank"
            href="https://nikonaskida.notion.site/Reinspire-io-Help-Quickstart-Guide-0d49fef9d21544c1971f32b76fe14f73?pvs=4"
            className={cn(
              "flex h-9 flex-row items-center gap-2.5 rounded-sm px-3 hover:bg-secondary",
              isCollapsed ? "size-9 justify-center p-0" : "w-full",
            )}
          >
            <HelpCircle size={18} strokeWidth={2.5} className="flex-shrink-0" />
            {!isCollapsed && (
              <span className="overflow-hidden text-nowrap">
                Help & Quickstart
              </span>
            )}
          </a>
          <Separator />
          <UserDropdown isCollapsed={isCollapsed} />
        </div>
      </nav>
    </motion.aside>
  );
};

export default Sidebar;
