import { apiInstance } from "@/api/index";
import { HTTP_ERROR_CODES } from "@/api/utils";
import { AxiosError, AxiosResponse } from "axios";
import { mutate } from "swr";

type generateAIContentResponse = {
  content_status: ContentStatus;
  content_task_id: string;
};

export const generateAIContent = async (
  spaceSlug: string,
  recordingId: string,
): Promise<generateAIContentResponse> => {
  try {
    const response = await apiInstance.post(
      `/spaces/${spaceSlug}/recordings/${recordingId}/content`,
    );
    mutate(
      `/spaces/${spaceSlug}/recordings/${recordingId}`,
      (data) => {
        return {
          ...data,
          content_status: response.data.content_status,
          content_task_id: response.data.content_task_id,
        };
      },
      { revalidate: false },
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    const response = error.response as AxiosResponse;

    if (!response && error.request) {
      throw new Error("Network error");
    } else if (HTTP_ERROR_CODES.includes(response.status)) {
      throw new Error(response.data.detail);
    } else if (response.status === 429) {
      throw new Error("Too many requests. Try again later.");
    } else {
      throw new Error("Something went wrong. Try again.");
    }
  }
};
