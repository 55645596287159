import { Link } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { formatDate } from "@/lib/date";
import { cn } from "@/lib/utils";

import UpdatePaymentDialog from "@/views/Account/components/Dialog/UpdatePaymentDialog";
import UserUsage from "@/views/Account/components/Section/UserUsage";

import { useSubscription } from "@/api/hooks/useSubscription";

const UserSubscription = () => {
  const { subscription, subscriptionLoading, subscriptionError } =
    useSubscription();

  if (subscriptionLoading || subscriptionError) return null;

  return (
    <section
      id="subscription"
      className="space-y-6 rounded-sm border border-border bg-card p-4"
    >
      <div className="flex flex-row flex-wrap items-center justify-between gap-4 lg:grid lg:grid-cols-3">
        <div className="space-y-2">
          <div className="font-rubik-regular text-sm text-muted-foreground">
            Plan
          </div>
          <div className="flex h-9 flex-row items-center gap-3 font-bricolage-medium text-2xl">
            {subscription!.plan}
            <Badge
              variant="outline"
              className={cn(
                "font-bricolage-medium capitalize",
                subscription!.status === "active"
                  ? "border-green-600 bg-green-600/20 text-green-600"
                  : subscription!.status === "past_due"
                  ? "border-yellow-500 bg-yellow-500/20 text-yellow-500"
                  : subscription!.status === "canceled" &&
                    "border-red-500 bg-red-500/20 text-red-500",
              )}
            >
              {subscription!.status}
            </Badge>
          </div>
        </div>

        <div className="space-y-2">
          <div className="font-rubik-regular text-sm text-muted-foreground">
            {subscription!.next_billed_at
              ? "Renews at"
              : subscription!.scheduled_change
              ? "Scheduled Change"
              : "Canceled at"}
          </div>
          <div className="h-9">
            {subscription!.next_billed_at ? (
              <div className="font-bricolage-medium text-2xl">
                {formatDate(subscription!.next_billed_at, "MMM DD, YYYY")}
              </div>
            ) : subscription!.scheduled_change ? (
              <div className="flex flex-col text-sm capitalize">
                <span>Action: {subscription!.scheduled_change.action}</span>
                <span>
                  Effective at:{" "}
                  {formatDate(
                    subscription!.scheduled_change.effective_at,
                    "MMM DD, YYYY",
                  )}
                </span>
              </div>
            ) : (
              <div className="font-bricolage-medium text-2xl">
                {subscription!.canceled_at
                  ? formatDate(subscription!.canceled_at, "MMM DD, YYYY")
                  : "N/A"}
              </div>
            )}
          </div>
        </div>

        {subscription!.status !== "canceled" && (
          <div className="space-y-2">
            <div className="font-rubik-regular text-sm text-muted-foreground">
              Manage
            </div>
            <div className="h-9 space-x-4">
              <Link
                to="/account/billing#plans"
                className="text-sm underline transition-colors hover:text-primary"
              >
                Upgrade
              </Link>
              <UpdatePaymentDialog subscription={subscription!} />
            </div>
          </div>
        )}
      </div>
      {subscription!.status !== "canceled" && (
        <UserUsage subscription={subscription!} />
      )}
    </section>
  );
};

export default UserSubscription;
