import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { MoveLeft } from "lucide-react";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-[calc(100vh-80px)] w-full flex-col items-center justify-center font-medium">
      <span className="font-bricolage-semibold text-8xl">Oops ...</span>
      <span className="mt-6 pb-6">
        Sorry, the page you were looking for was not found
      </span>
      <Button color="primary" className="px-10" onClick={() => navigate(-1)}>
        <MoveLeft className="mr-2" size={18} />
        Go back
      </Button>
    </div>
  );
};

export default NotFound;
