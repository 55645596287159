import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Row } from "@tanstack/react-table";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import { DialogFooter, DialogClose } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandList,
  CommandInput,
  CommandEmpty,
  CommandGroup,
  CommandSeparator,
  CommandItem,
} from "@/components/ui/command";
import { Plus, ChevronDown, Check, Loader2 } from "lucide-react";
import { supportedLanguages } from "@/lib/constants";
import { cn } from "@/lib/utils";

import Alert from "@/components/Alert/Alert";

import { STATUS } from "@/api/utils";
import { useSpacePreviews } from "@/api/hooks/useSpaces";
import { createRecording } from "@/api/services/recording";

const formSchema = z.object({
  space_slug: z.string().min(1, "Please select a space"),
  num_speakers: z.number().min(1).max(50).optional(),
  language: z.string(),
  vocabulary: z
    .string()
    .max(120, { message: "Vocabulary must be less than 120 characters" })
    .optional(),
  copy_transcript: z.boolean().optional(),
});

type FormSchema = z.infer<typeof formSchema>;

type AssignRecordingFormProps = {
  row: Row<Media>;
  setDialogOpen: (value: boolean) => void;
  mediaRecordings: MediaRecording[];
};

const AssignRecordingForm = (props: AssignRecordingFormProps) => {
  const { row, setDialogOpen, mediaRecordings } = props;
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.IDLE);
  const [comboboxOpen, setComboboxOpen] = useState<boolean>(false);

  const { toast } = useToast();
  const { spaces, spacesLoading, spacesError } = useSpacePreviews();

  const existingRecordingSpaceSlugs = useMemo(
    () => mediaRecordings.map((recording) => recording.space.slug),
    [mediaRecordings],
  );

  const existingRecordingsWithTranscript = useMemo(
    () =>
      row.original.recordings.filter(
        (recording) => recording.transcript_status === "completed",
      ),
    [row.original.recordings],
  );

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      space_slug: undefined,
      num_speakers: undefined,
      language: "auto",
      vocabulary: undefined,
      copy_transcript:
        existingRecordingsWithTranscript.length > 0 ? true : false,
    },
  });

  const copyTranscript = form.watch("copy_transcript");

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      const spaceSlug = data.space_slug;
      const mediaId = row.original.id;

      // Remove space_slug from data
      const modifiedData = {
        num_speakers: data.num_speakers,
        language: data.language,
        vocabulary: data.vocabulary,
        copy_transcript: data.copy_transcript,
      };

      // Create recording
      const { recording_id, transcript_status } = await createRecording(
        spaceSlug,
        mediaId,
        modifiedData,
      );
      setStatus(STATUS.SUCCESS);
      setDialogOpen(false);

      // Show toast based on transcript status
      if (transcript_status === "not_started") {
        toast({
          title: "Transcription didn't start.",
          description: "Not enough minutes available to transcribe.",
          variant: "info",
        });
      } else if (transcript_status === "processing") {
        toast({
          title: "Recording created successfully",
          description:
            "Transcript is being processed. It usually takes a few minutes.",
          variant: "success",
        });
      } else if (transcript_status === "completed") {
        toast({
          title: "Transcription copied successfully",
          variant: "success",
        });
      }

      // Navigate to created recording
      navigate(`/spaces/${spaceSlug}/recordings/${recording_id}`);
    } catch (error) {
      setStatus(STATUS.ERROR);
      form.setError("root", { message: (error as Error).message });
    }
  };

  return (
    <div className="space-y-4">
      {status === STATUS.ERROR && (
        <Alert type="error" message={form.formState.errors.root!.message!} />
      )}
      {existingRecordingsWithTranscript.length > 0 && (
        <Alert
          type="info"
          message="This media already has recording in other space(s)"
        />
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="space_slug"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Space</FormLabel>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    if (value === "new") {
                      navigate("/spaces", { state: { newSpace: true } });
                    }
                    field.onChange(value);
                  }}
                  disabled={spacesLoading || spacesError ? true : false}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={
                          <span className="flex flex-row items-center gap-2">
                            {(spacesLoading || spacesError) && (
                              <>
                                <Loader2
                                  size={16}
                                  strokeWidth={2.5}
                                  className="animate-spin"
                                />
                              </>
                            )}
                            Select a space
                          </span>
                        }
                      >
                        {
                          spaces?.find((space) => space.slug === field.value)
                            ?.emoji
                        }{" "}
                        {
                          spaces?.find((space) => space.slug === field.value)
                            ?.name
                        }
                      </SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {spaces
                      ?.filter(
                        (space) =>
                          !existingRecordingSpaceSlugs.includes(space.slug),
                      )
                      .map((space) => (
                        <SelectItem key={space.slug} value={space.slug}>
                          {space.emoji} {space.name}
                        </SelectItem>
                      ))}
                    <SelectItem value="new">
                      <span className="flex flex-row items-center gap-1">
                        <Plus
                          size={16}
                          strokeWidth={2.5}
                          className="text-primary"
                        />
                        Create a new space
                      </span>
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          {!copyTranscript && (
            <>
              <FormField
                control={form.control}
                name="num_speakers"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Number of Speakers</FormLabel>
                    <div className="flex items-center space-x-4">
                      <div>
                        <FormControl>
                          <Input
                            {...field}
                            type="text"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                value === "" ||
                                value.toLowerCase() === "auto"
                              ) {
                                field.onChange(undefined);
                              } else {
                                const numValue = parseInt(value, 10);
                                if (
                                  !isNaN(numValue) &&
                                  numValue >= 0 &&
                                  numValue <= 50
                                ) {
                                  field.onChange(numValue);
                                }
                              }
                            }}
                            value={
                              field.value === undefined ? "Auto" : field.value
                            }
                            min={1}
                            max={50}
                            onFocus={(e) => {
                              if (field.value === undefined) {
                                e.target.select();
                              }
                            }}
                            className="w-20"
                          />
                        </FormControl>
                      </div>
                      <Slider
                        min={0}
                        max={50}
                        step={1}
                        value={[field.value === undefined ? 0 : field.value]}
                        onValueChange={(value) =>
                          field.onChange(value[0] || undefined)
                        }
                        className="flex-grow"
                      />
                    </div>

                    <FormMessage />

                    <FormDescription>
                      The number of speakers in the recording. Default is auto
                      detect.{" "}
                      <span className="text-primary">
                        Recommended to set for higher quality results.
                      </span>
                    </FormDescription>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="language"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel className="mb-1 w-fit">Language</FormLabel>
                    <Popover open={comboboxOpen} onOpenChange={setComboboxOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between capitalize active:scale-[1]",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value || "Select language"}
                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-[--radix-popover-trigger-width] p-0 shadow-sm">
                        <Command>
                          <CommandInput
                            placeholder="Search language..."
                            className="h-9"
                          />

                          <CommandList>
                            <CommandEmpty>No language found.</CommandEmpty>
                            <CommandGroup heading="Common">
                              {supportedLanguages
                                .slice(0, 4)
                                .map((language) => (
                                  <CommandItem
                                    key={language}
                                    value={language}
                                    onSelect={() => {
                                      form.setValue("language", language);
                                      setComboboxOpen(false);
                                    }}
                                    className="cursor-pointer capitalize"
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        language === field.value
                                          ? "opacity-100"
                                          : "opacity-0",
                                      )}
                                    />
                                    {language}
                                  </CommandItem>
                                ))}
                            </CommandGroup>
                            <CommandSeparator />
                            <CommandGroup heading="Other">
                              {supportedLanguages.slice(4).map((language) => (
                                <CommandItem
                                  key={language}
                                  value={language}
                                  onSelect={() => {
                                    form.setValue("language", language);
                                    setComboboxOpen(false);
                                  }}
                                  className="cursor-pointer capitalize"
                                >
                                  <Check
                                    className={cn(
                                      "mr-2 h-4 w-4",
                                      language === field.value
                                        ? "opacity-100"
                                        : "opacity-0",
                                    )}
                                  />
                                  {language}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormDescription>
                      The language in the recording. Default is auto detect.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="vocabulary"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Vocabulary</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Vocabulary (optional)" />
                    </FormControl>
                    {form.formState.errors.num_speakers ? (
                      <FormMessage />
                    ) : (
                      <FormDescription>
                        The vocabulary used in the recording. Use commas to
                        separate words. 120 characters max.
                      </FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </>
          )}

          {existingRecordingsWithTranscript.length > 0 && (
            <FormField
              control={form.control}
              name="copy_transcript"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      className="rounded-[4px]"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Use existing transcript</FormLabel>
                    <FormDescription>
                      Copy the transcript from the existing recording(s). Your
                      minutes usage won't be affected in this case.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
          )}

          <DialogFooter className="gap-2">
            <DialogClose asChild>
              <Button variant="outline" className="min-w-20">
                Cancel
              </Button>
            </DialogClose>
            <Button
              type="submit"
              className="min-w-20"
              isLoading={status === STATUS.LOADING}
            >
              Save recording
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </div>
  );
};

export default AssignRecordingForm;
