import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "@/components/ui/button";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Alert from "@/components/Alert/Alert";
import { AtSign } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";

import { STATUS } from "@/api/utils";
import { handleForgotPasswordRequest } from "@/api/services/password";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});

type formSchema = z.infer<typeof formSchema>;

type ForgotPasswordFormProps = {
  status: string;
  setStatus: (status: string) => void;
  setUserEmail: (email: string) => void;
};

const ForgotPasswordForm = ({
  status,
  setStatus,
  setUserEmail,
}: ForgotPasswordFormProps) => {
  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await handleForgotPasswordRequest(data.email);
      setUserEmail(data.email);
      setStatus(STATUS.SUCCESS);
    } catch (error) {
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        {form.formState.errors.root && (
          <Alert type="error" message={form.formState.errors.root.message!} />
        )}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  startIcon={AtSign}
                  placeholder="Enter your email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          isLoading={status === STATUS.LOADING}
          className="mt-4 w-full"
        >
          Reset Password
        </Button>
      </form>
    </Form>
  );
};

export default ForgotPasswordForm;
